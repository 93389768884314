import { AttackAction } from '../AttackAction'
import { Activation } from './Activation'
import { Dice } from '../Dice'
import { Range } from './Range'
import { Json, Dictionary } from '../../Common/Types'

export enum CreatureType {
  WildShape = 1,
  Familiar = 2,
  BeastCompanion = 3,
  Mount = 4,
  Pet = 5,
  Summon = 6,
  Misc = 7,
  Unknown1 = 8,
  Unknown2 = 9,
  SteelDefender = 10,
  Sidekick = 11,
  Infusion = 12
}

export class CreatureDefinition {
  id: number
  entityTypeId: number
  name: string
  isHomebrew: boolean
  actionsDescription: string

  constructor(json: Json) {
    this.id = json.id
    this.entityTypeId = json.entityTypeId
    this.name = json.name
    this.isHomebrew = json.isHomebrew
    this.actionsDescription = json.actionsDescription
  }
}

export class Creature {
  id: number // id
  entityTypeId: number
  name: string // name
  private race: string // definition.name
  private groupId: CreatureType
  private definition: CreatureDefinition

  attacks: AttackAction[] = []

  constructor(data: Json) {
    const { definition } = data
    this.id = data.id
    this.entityTypeId = data.entityTypeId
    this.name = data.name
    this.groupId = data.groupId
    this.race = definition.name
    if (!this.name) {
      this.name = this.race
    }

    this.definition = new CreatureDefinition(data.definition)

    const actionsDescription = definition.actionsDescription

    const attackChunks: string[] = actionsDescription.split('<p>')
    for (const chunk of attackChunks) {
      const creatureAttack = this.lookForMatches(chunk)

      if (creatureAttack) {
        const { name, toHit, range, longRange, diceCount, diceValue, fixedValue } = creatureAttack
        const dice = Dice.Create(diceCount, diceValue, fixedValue)
        const attributes: Dictionary = {
          range: Range.makeWeaponRange(range, longRange),
          type: 'Companion',
          creatureType: this.groupId
        }
        const activation = this.groupId === CreatureType.WildShape ? Activation.Action() : Activation.BonusAction()

        const attackAction = new AttackAction(
          this.id + data.definition.id + this.attacks.length,
          `${this.name}: ${name}`,
          toHit,
          dice,
          attributes,
          activation,
          creatureAttack.description
        )

        this.attacks.push(attackAction)
      }
    }
  }

  replaceHtmlEntities(chunk: string): string {
    return chunk
      .replace(/<[^>]+>/g, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&')
      .replace(/&ndash;/g, '-')
      .trim()
  }

  lookForMatches(chunk: string): CreatureAttack | null {
    const text = this.replaceHtmlEntities(chunk)

    if (text.length === 0) {
      return null
    }

    const patterns = [
      {
        regex: /(\b.+?)\.[^+]+(\+\d+)( to hit)?, \w+ (\d+\/\d+|\d+) ft.+?(?:(\d+)?d?(\d+)?(?: \+ (\d+))?)?\) (\w+) damage/,
        indices: [1, 2, 4, 5, 6, 7]
        // name, toHit, range, diceCount, diceValue, fixedValue
      },
      {
        // One target is from some legacy creatures (tressym), one creature is homunculus
        regex: /(\b.+?)\.[^+]+(\+\d+)( to hit)?, \w+ (\d+\/\d+|\d+) ft.(, one (target|creature).)? Hit: (\d+) (\w+)/,
        indices: [1, 2, 4, -1, -1, 7]
      }
    ]

    for (const pattern of patterns) {
      const matches = text.match(pattern.regex)
      if (matches) {
        return this.smartMatch(text, matches, pattern.indices)
      }
    }

    // const nameMatch = text.match(/^([^.]+)\.(.+)$/)
    const nameMatch = text.match(/^(.+?)\.(.+)$/)

    if (nameMatch) {
      const name = nameMatch[1].trim()
      // if (name.includes('&')) {
      //     name =
      // }

      if (name === 'Multiattack') return null
      const description = nameMatch[2].trim()
      return {
        name,
        toHit: 0,
        range: 0,
        longRange: 0,
        diceCount: 0,
        diceValue: 0,
        fixedValue: 0,
        description
      }
    }

    if (!text.includes('Multiattack')) {
      console.warn(`Could not match ${this.name}'s attack [${text}]`)
    }

    return null
  }

  smartMatch(text: string, matches: RegExpMatchArray, indices: number[]): CreatureAttack {
    const rawRange = matches[indices[2]]
    let range: number = Number(rawRange)
    let longRange = 0

    const matchedRange: string[] = String(rawRange).split('/')
    if (matchedRange.length === 2) {
      range = Number(matchedRange[0])
      longRange = Number(matchedRange[1])
    }

    // Don't show description if it's a match for now
    // const nameMatch = text.match(/^([^.]+)\.(.+)$/)
    // const description = nameMatch ? nameMatch[2].trim() : ''

    return {
      name: matches[indices[0]],
      toHit: Number(matches[indices[1]]),
      range: Number(range),
      longRange: Number(longRange),
      diceCount: indices[3] === -1 ? 0 : Number(matches[indices[3]]) || 0,
      diceValue: indices[4] === -1 ? 0 : Number(matches[indices[4]]) || 0,
      fixedValue: Number(matches[indices[5]]) || 0
    }
  }
}

interface CreatureAttack {
  name: string
  toHit: number
  range: number
  longRange?: number
  diceCount: number
  diceValue: number
  fixedValue: number
  description?: string
}
