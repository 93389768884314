import { ActionParser } from './ActionParser'
import { AttackAction } from '../AttackAction'
import { Dice } from '../Dice'
import { Action } from '../CharacterJSON/Actions'

export class ActionParserSorcerer {
  static parse(parser: ActionParser, action: Action, attackActions: AttackAction[]) {
    const name = action.name

    if (parser.character.classLevel('Sorcerer') === 0) return false

    // Abberant Mind Sorcerer
    if (name === 'Warping Implosion') {
      const spellSaveDC = parser.character.saveDcForAbility('charisma')
      const attack = parser.createAoeSpellAction(name, action, parser.action, spellSaveDC)
      attackActions.push(attack)
      return true
    }

    // Shadow Sorcerer
    if (name === 'Hound of Ill Omen') {
      const dice = Dice.Create(2, 6, 3)
      const attack = new AttackAction(action.id, `${name}: Bite`, 5, dice, parser.companionAttrs, parser.bonusAction)
      attackActions.push(attack)
      return true
    }

    return false
  }
}
