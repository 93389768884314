import { Box, Table, Tbody, Tr, Td, Flex } from '@chakra-ui/react'
import React, { JSX } from 'react'
import { Spell } from '../DDB/Spell'
import { HTMLTableParser } from '../Loader/HTMLParser'

export function SpellDescriptionBox({ spell }: { spell?: Spell }): JSX.Element[] {
  let description = spell ? spell.sanitizeDescription() : ''

  const combinedRegex = /<table[^>]*>[\s\S]*?<\/table>/g
  const allTables = description.match(combinedRegex) || []

  // XXX
  const parsedTables = allTables
    .map((tableHtml) => {
      return HTMLTableParser.parseTableString(tableHtml)
    })
    .reverse()

  description = description.replace(combinedRegex, '<tablea>')
  description = description.replace(/<caption>.*?<\/caption>/g, '')

  // First split into paragraphs by \r
  const paragraphs = description.split('\r').filter((para) => para.trim().length > 0)

  // Check if there are exactly two consecutive <table> elements in the paragraphs (stat blocks)
  if (parsedTables.length === 2 && paragraphs.some((p, i) => p === '\n<tablea>' && paragraphs[i + 1] === '\n<tablea>')) {
    // Keep only the first <table> and remove the second
    const tableIndex = paragraphs.indexOf('\n<tablea>')
    const nextTableIndex = paragraphs.indexOf('\n<tablea>', tableIndex + 1)
    if (nextTableIndex !== -1) {
      paragraphs.splice(nextTableIndex, 1)
    }

    const firstTable = parsedTables[0]
    const secondTable = parsedTables[1]

    // Concatenate the rows from secondTable into firstTable
    secondTable.rows.forEach((row, i) => {
      row.cells.push(...firstTable.rows[i].cells)
    })
  }

  const length = description.length
  return paragraphs.map((paragraph, paraIndex) => {
    // Within each paragraph, split by style tags
    const parts = paragraph.split(/(<\/?(?:strong|em|h4|b)>)/).filter((part) => part.trim().length > 0)
    let isStrong = false
    let isEm = false
    let isLarge = false

    return (
      <Box fontSize={length < 1000 ? 'md' : 'sm'} className={length < 1000 ? 'font-md' : 'font-sm'} key={`para-${paraIndex}`} mb={2}>
        {parts.map((part, partIndex) => {
          if (part === '<strong>' || part === '<b>') {
            isStrong = true
            return null
          }
          if (part === '</strong>' || part === '</b>') {
            isStrong = false
            return null
          }
          if (part === '<em>') {
            isEm = true
            return null
          }
          if (part === '</em>') {
            isEm = false
            return null
          }
          if (part === '<h4>') {
            isLarge = true
            return null
          }
          if (part === '</h4>') {
            isLarge = false
            return null
          }

          // Split the content by newlines within each styled part
          const lines = part.split('\n').filter((line) => line.trim().length > 0)

          return lines.map((line, lineIndex) =>
            line === '<tablea>' ? (
              <Table
                key={`table-${paraIndex}-${partIndex}`}
                bgColor={'white'}
                color="black"
                borderWidth="1px"
                size="sm"
                className="font-sm"
                width="auto"
                sx={{ tableLayout: 'auto', width: 'fit-content' }}
              >
                <Tbody>
                  {parsedTables?.pop()?.rows.map((row, rowIndex) => (
                    <Tr key={`row-${rowIndex}`}>
                      {row.cells.map((cell, cellIndex) => (
                        <Td
                          py={1}
                          fontSize={'md'}
                          className="font-md"
                          key={`cell-${rowIndex}-${cellIndex}`}
                          whiteSpace={cellIndex === 0 ? 'nowrap' : 'normal'}
                          // wordBreak="break-word"
                          px={2}
                        >
                          {cell}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <Flex
                as="span"
                key={`${paraIndex}-${partIndex}-${lineIndex}`}
                fontWeight={isStrong ? 'bold' : 'normal'}
                fontStyle={isEm ? 'italic' : 'normal'}
                fontSize={isLarge ? 'lg' : 'md'}
                className={isLarge ? 'font-lg' : 'font-md'}
                display={lines.length > 1 ? 'block' : 'inline'}
              >
                {line}
              </Flex>
            )
          )
        })}
      </Box>
    )
  })
}
