import { Dictionary, ABILITY } from '../Common/Types'
import { Feat as FeatJSON } from './CharacterJSON/Feat'
import { Modifier } from './CharacterJSON/Modifiers'
import { Utility } from '../Common/Utility'

// TODO - dedupe this and CharacterJSON/Feat - but be wary of the options and modifiers, keep those separate.
export class CharacterFeat {
  name: string
  type: string
  subtype: string
  id: number
  attrIndex: number | null = null
  attrValue: number = 0
  optionDefinition: Dictionary = {}
  isFightingStyle: boolean = false
  friendlySubtypeName: string | undefined = undefined

  constructor(featData: FeatJSON, modifiersData: Modifier[], featOptionsData: Dictionary[]) {
    const featDefinitionData = featData.definition
    this.name = featDefinitionData.name
    this.id = featDefinitionData.id
    this.type = featDefinitionData.type
    this.subtype = featDefinitionData.subType
    this.friendlySubtypeName = featData.friendlySubtypeName

    // 2024 has new feat option choices, like Elemental Adept
    const matchingFeatOption = featOptionsData.find((option) => option.componentId === this.id)
    if (matchingFeatOption) {
      this.optionDefinition = matchingFeatOption.definition
    }

    // Find if this feat granted attribute points
    for (const modifierData of modifiersData) {
      const componentId = modifierData.componentId
      const type = modifierData.type

      if (componentId === this.id) {
        if (type === 'bonus') {
          const subtype = modifierData.subType
          const attrName = subtype.slice(0, -'-score'.length)
          this.attrIndex = Utility.indexOfAbility(attrName as ABILITY)
          this.attrValue = modifierData.value
        }

        if (type === 'weapon-mastery') {
          this.friendlySubtypeName = modifierData.friendlySubtypeName
        }
      }
    }

    this.isFightingStyle = featDefinitionData.categories.some((category: Dictionary) => category.tagName === 'Fighting Style')
  }
}
