import { Dice } from '../Dice'

import { FeatureParserBase } from './FeatureParserBase'

export class FeatureParserArtificer {
  static parse(parser: FeatureParserBase) {
    const thisClass = 'Artificer'
    if (!parser.validate(thisClass)) return false
    const { name, only, feature, effects, character, isSubclassFeature } = parser
    const classLevel = parser.classLevel(thisClass)

    if (name === 'Battle Ready') {
      only.magicWeapon = true
      effects.useAbilityModForAttacks = character.modifierForAbility('intelligence')
      feature.defaultEnabled = true
      feature.notes = 'Use spellcasting mod for attacks and damage with magic weapons'
      return true
    }

    if (name === 'Experimental Elixir') {
      effects.additionalToHitDice = Dice.Create(1, 4)
      feature.name = 'Experimental Elixir (Boldness)'
      return true
    }

    if (name === 'Arcane Jolt') {
      if (isSubclassFeature) return feature.skip()
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(classLevel >= 15 ? 4 : 2, 6)
      return true
    }

    if (name === 'Alchemical Savant') {
      if (isSubclassFeature) return feature.skip()
      // TODO: only acid, fire, necrotic, or poison
      const intMod = character.modifierForAbility('intelligence')
      only.spell = true
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(intMod)
      feature.notes = `+${intMod} to acid, fire, necrotic, or poison spell damage`
      return true
    }

    if (name === 'Arcane Firearm') {
      if (isSubclassFeature) return feature.skip()
      only.spell = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(1, 8)
      feature.notes = '+1d8 dmg to Artificer spells once per turn'
      return true
    }

    const nonBuffActions = [
      'Magical Tinkering',
      'Infuse Item',
      'The Right Tool for the Job',
      'Tool Expertise',
      'Flash of Genius',
      'Spell-Storing Item',
      'Soul of Artifice',
      'Create Eldritch Cannon',
      'Eldritch Cannon: Flamethrower',
      'Eldritch Cannon: Force Ballista',
      'Eldritch Cannon: Protector',
      'Detonate Eldritch Cannon',
      'Hit Points',
      'Proficiencies',
      'Spellcasting',
      'Artificer Infusions',
      'Artificer Specialist',
      'Ability Score Improvement',
      'Magic Item Adept',
      'Magic Item Savant',
      'Magic Item Master',
      'Equipment',
      'Tool Proficiency',
      'Artillerist Spells',
      'Eldritch Cannon',
      'Explosive Cannon',
      'Fortified Position',
      'Arcane Armor - Create Armor',
      'Guardian Armor: Thunder Gauntlets',
      'Guardian Armor: Defensive Field',
      'Guardian Armor: Thunder Gauntlets (STR)',
      'Tools of the Trade',
      'Armorer Spells',
      'Arcane Armor',
      'Armor Model',
      'Armor Modifications',
      'Perfected Armor',
      'Extra Attack'
    ]

    if (nonBuffActions.includes(name)) return feature.skip()

    return false
  }
}
