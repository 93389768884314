import { ActionParser } from './ActionParser'
import { AttackAction } from '../AttackAction'
import { Weapon } from '../Weapon'

export class ActionParserWeapon {
  static createActions(parser: ActionParser, weapons: Weapon[], fakeIDBaseList: number[], attackActions: AttackAction[]) {
    const nonRangedWeapons = weapons.filter((weapon) => !weapon.attributes.isRanged).filter((weapon) => !weapon.attributes.isOffHand)
    nonRangedWeapons.forEach((weapon) => {
      const attack = AttackAction.CreateFromWeapon(weapon, parser.character)
      const fakeId = fakeIDBaseList[0] + fakeIDBaseList[1]++

      attack.name = weapon.name
      // Many weapons are just 1 id away from the others, so multiply by 1000 to avoid conflicts
      const id = 1000 * fakeId + attack.id
      this.configureOpportunityAttack(attack, id)
      attackActions.push(attack)
    })
  }

  static configureOpportunityAttack(attack: AttackAction, id: number) {
    attack.configureAsOpportunityAttack(id)
  }

  static parse(parser: ActionParser, weapon: Weapon, attackActions: AttackAction[]) {
    const character = parser.character
    if (!weapon.attributes.isOffHand) {
      return
    }
    const dualWielder = character.hasFeatNamed('Dual Wielder')
    if (weapon.hasProperty('Light') || (dualWielder && !weapon.hasProperty('Two-Handed'))) {
      const offhand = true
      const attackAction = AttackAction.CreateFromWeapon(weapon, character, offhand)
      attackActions.push(attackAction)
    }
  }
}
