import { Json } from '../../Common/Types'
import { JSONUtility, JsonValue } from '../../Common/JSONUtility'
import { ShareDataInterface } from '../../Common/Interfaces'
import { Creature } from './Creature'
import { Inventory } from './Inventory'
import { Campaign } from './Campaign'
import { Race } from './Race'
import { Spell, SpellGroup, SpellList } from './Spell'
import { Stat } from './Stat'
import { Actions } from './Actions'
import { Options } from './Options'
import { Modifiers } from './Modifiers'
import { Background } from './Background'
import { DDBClass } from './DDBClass'
import { Feat } from './Feat'
import { Decorations } from './Decorations'
import { CharacterValues } from './CharacterValues'

// This could have been just an interface, but i want to be able to prune data out as well
// This also helps prevent me from cheating and just reaching into the raw data

export class CharacterJSON {
  id: number
  name: string
  creatures: Creature[]
  decorations: Decorations
  inventory: Inventory[]
  background: Background
  classes: DDBClass[]
  options: Options
  modifiers: Modifiers
  feats: Feat[]
  characterValues: CharacterValues[]
  campaign?: Campaign
  actions: Actions
  race: Race
  spells: SpellGroup
  classSpells: SpellList[]
  subclassSpells?: Spell[][]
  knownSpells?: Spell[][] // Loaded from always-known-spells…
  stats: Stat[]
  overrideStats: Stat[]
  bonusStats: Stat[]
  shareData?: ShareDataInterface
  loadedFromCache: boolean // Only set by the backend. This is for 'hard coded' characters that can't be force updated.

  constructor(rootData: Json, loadDescriptions: boolean = false) {
    if (!rootData.race || rootData.race === null) {
      // Workaround if character isn't ready, avoid a crash.
      // TODO: Later filter this out
      rootData.race = { racialTraits: [] }
    }

    const logSize = false

    this.id = rootData.id
    this.name = rootData.name
    this.creatures = rootData.creatures.map((creature: Json) => new Creature(creature))
    this.decorations = new Decorations(rootData.decorations)
    this.inventory = rootData.inventory.map((inv: Json) => new Inventory(inv))
    this.background = new Background(rootData.background)
    this.classes = rootData.classes.map((c: Json) => new DDBClass(c))
    this.options = new Options(rootData.options)
    this.modifiers = new Modifiers(rootData.modifiers)
    this.feats = rootData.feats.map((feat: Json) => new Feat(feat))
    this.characterValues = rootData.characterValues.map((value: Json) => new CharacterValues(value))
    this.campaign = rootData.campaign ? new Campaign(rootData.campaign) : undefined
    this.actions = new Actions(rootData.actions)
    this.race = new Race(rootData.race)
    const baseSpellcastingId = this.classes[0].spellcastingAbilityId()
    this.spells = new SpellGroup(rootData.spells, baseSpellcastingId, loadDescriptions)

    this.classSpells = rootData.classSpells.map((list: Json, index: number) => {
      if (index >= this.classes.length) {
        console.error(`Class index ${index} out of bounds for classSpells`)
        return new SpellList(list, undefined, loadDescriptions)
      }

      return new SpellList(list, this.classes[index].spellcastingAbilityId(), loadDescriptions)
    })

    this.subclassSpells = rootData.subclassSpells
      ? rootData.subclassSpells.map((spellList: Json[], index: number) => {
          if (index >= this.classes.length) {
            console.error(`Class index ${index} out of bounds for classSpells`)
            return spellList.map((spell: Json) => new Spell(spell, undefined, loadDescriptions))
          }

          const spellcastingId = this.classes[index].spellcastingAbilityId()
          return spellList.map((spell: Json) => new Spell(spell, spellcastingId, loadDescriptions))
        })
      : undefined

    this.knownSpells = rootData.knownSpells
      ? rootData.knownSpells.map((spellList: Json[], index: number) => {
          if (index >= this.classes.length) {
            console.error(`Class index ${index} out of bounds for classSpells`)
            return spellList.map((spell: Json) => new Spell(spell, undefined, loadDescriptions))
          }
          const spellcastingId = this.classes[index].spellcastingAbilityId()
          return spellList.map((spell: Json) => new Spell(spell, spellcastingId, loadDescriptions))
        })
      : undefined
    this.stats = rootData.stats.map((stat: Json) => new Stat(stat))
    this.overrideStats = rootData.overrideStats.map((stat: Json) => new Stat(stat))
    this.bonusStats = rootData.bonusStats.map((stat: Json) => new Stat(stat))

    this.loadedFromCache = rootData.loadedFromCache || false

    if (logSize) {
      const sizeInBytes = JSON.stringify(rootData).length
      const prunedSizeInBytes = JSON.stringify(this.prunedData()).length

      console.log(
        `Character data ${this.name} reduced from ${sizeInBytes} to ${prunedSizeInBytes}, ${Number((1 - prunedSizeInBytes / sizeInBytes) * 100).toFixed(2)}% reduction`
      )
    }
  }

  prunedData(): Json {
    // Remove any undefined or null values
    return JSON.parse(JSONUtility.stringifyWithoutUndefined(this as unknown as JsonValue))
  }
}
