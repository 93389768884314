export type JsonValue = string | number | boolean | null | { [key: string]: JsonValue } | JsonValue[]

export class JSONUtility {
  /**
   * Recursively strips undefined values from an object or array.
   * @param obj The object, array, or primitive to process.
   * @returns A new object, array, or primitive with undefined values removed.
   */
  static stripUndefined<T extends JsonValue>(obj: T): T {
    if (Array.isArray(obj)) {
      // Filter undefined and null values in arrays
      return obj.map((item) => this.stripUndefined(item)).filter((item): item is NonNullable<JsonValue> => item != null) as T
    } else if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
      // Filter undefined and null values in objects
      const filteredObject = Object.entries(obj)
        .filter(([, value]) => value != null)
        .reduce<{ [key: string]: JsonValue }>((acc, [key, value]) => {
          acc[key] = this.stripUndefined(value as JsonValue)
          return acc
        }, {})
      return filteredObject as T
    }
    return obj
  }

  /**
   * Safely stringify an object to JSON, removing undefined values.
   * @param obj The object to stringify.
   * @returns A JSON string representation of the object without undefined values.
   */
  static stringifyWithoutUndefined<T extends JsonValue>(obj: T): string {
    return JSON.stringify(this.stripUndefined(obj))
  }
}
