export function DPRAppThemeColors() {
  return {
    // Dark Mode
    common: '#fff',
    uncommon: '#5cbe3e',
    rare: '#5991dc',
    veryrare: '#b55dff',
    legendary: '#f2a546',
    artifact: '#b78b76'
    // Light mode
    // common: '#242528',
    // uncommon: '#1fc219',
    // rare: '#4990e2',
    // veryrare: '#9810e0',
    // legendary: '#fea227',
    // artifact: '#be8972'
  }
}

export function DPRAppThemeComponents() {
  const Menu = MenuThemeCommponent()
  const Table = TableThemeComponent()
  const Text = TextThemeComponent()

  return {
    Text,
    Menu,
    Table
  }
}

function TextThemeComponent() {
  return {
    variants: {
      common: {
        color: 'common'
      },
      uncommon: {
        color: 'uncommon'
      },
      rare: {
        color: 'rare'
      },
      veryrare: {
        color: 'veryrare'
      },
      legendary: {
        color: 'legendary'
      },
      artifact: {
        color: 'artifact'
      }
    }
  }
}

function TableThemeComponent() {
  return {
    parts: ['th', 'tfoot', 'td'],
    baseStyle: {
      th: {
        textTransform: 'uppercase',
        fontWeight: 'bold'
      },
      tfoot: {
        td: {
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: 'xs'
        },
        bgColor: 'gray.800'
      }
    }
  }
}

function MenuThemeCommponent() {
  return {
    baseStyle: {
      variants: {
        hoverButton: {
          bgColor: 'bg.surface',
          color: 'white'
        }
      }
    }
  }
}
