import React, { useState, useRef } from 'react'
import * as CSS from 'csstype'
import { Box, Flex, Spacer } from '@chakra-ui/react'
import { Table, Tr, Td, Tbody, Thead } from '@chakra-ui/react'
import { extractCharacterID } from '../Common/Handlers'
import { useToast } from '@chakra-ui/react'
import { CharacterLoader } from '../Loader/CharacterLoader'
import { Character } from '../DDB/Character'
import { Spell } from '../DDB/Spell'
import { useReactToPrint } from 'react-to-print'
import { Global, css } from '@emotion/react'
import { DprTab, MainNavBar } from './MainNavBar'
import {
  SpellEffectCell,
  SpellDescriptionCell,
  InstructionsHeader,
  URLInputControlStrip,
  SpellInfoCell,
  OctoBoxValue,
  OctoBox
} from './SpellPageComponents'

import { errorToast } from '../Common/UIComponents'
import { useLocalStorage } from '../Common/useLocalStorage'
import { RECENT_CHARACTERS_KEY } from '../Common/Constants'
import { ShareDataInterface } from '../Common/Interfaces'

const tableStyles = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'gray.200'
}

const DDB_URL_PREFIX = 'https://www.dndbeyond.com/characters/'

export function SpellListPage() {
  const toast = useToast()
  const componentRef = useRef(null)
  const { items, addItem } = useLocalStorage<ShareDataInterface>(RECENT_CHARACTERS_KEY)

  const [urlFieldValue, setUrlFieldValue] = useState(items.length > 0 ? `${DDB_URL_PREFIX}${items[0].cid}` : '')
  const [character, setCharacter] = useState<Character | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  // Auto-load the last valid character on component mount
  React.useEffect(() => {
    if (urlFieldValue) {
      loadCharacter(false)
    }
  }, [])

  const loadCharacter = async (forceRefresh = false) => {
    setIsLoading(true)

    try {
      // If we have a character ID and we're not forcing a refresh, use it
      const cid = extractCharacterID(urlFieldValue)
      if (!cid) {
        errorToast(toast, 'Invalid URL', 'Please enter a valid DDB character URL and try again.')
        return
      }

      // Pass forceRefresh to control caching behavior
      const loadedCharacter = await CharacterLoader.load(String(cid), forceRefresh, true, true)
      if (!loadedCharacter) {
        errorToast(toast, 'Could not load character', 'Please enter a valid DDB character URL and try again.')
        return
      }

      setCharacter(loadedCharacter)
      const shareData = loadedCharacter?.baseShareDateForRecents()
      if (shareData) addItem(shareData)
    } finally {
      setIsLoading(false)
    }
  }

  function handleXClick() {
    setUrlFieldValue('')
    setCharacter(undefined)
  }

  function handleInitialLoad() {
    loadCharacter(false)
  }

  function handleReload() {
    if (character) {
      loadCharacter(true)
    } else {
      errorToast(toast, 'No character loaded', 'Please load a character first before reloading.')
    }
  }

  const handlePrint = useReactToPrint({
    documentTitle: 'Spell Cards',
    contentRef: componentRef,
    pageStyle: printPageStyles()
  })

  const spellsToShow = isLoading ? Array(10).fill(undefined) : character?.spellsSortedByLevelAndName(true) || []

  return (
    <MainNavBar tab={DprTab.SPELLS}>
      <Box p={8}>
        <PrintStyles />
        <InstructionsHeader />
        <URLInputControlStrip
          urlFieldData={[urlFieldValue, setUrlFieldValue]}
          character={character}
          isLoading={isLoading}
          handleLoadClick={handleInitialLoad}
          handleReloadClick={handleReload}
          handleXClick={handleXClick}
          handlePrint={handlePrint}
        />
        <Box ref={componentRef}>
          <Table bgColor={'white'} color={'black'} sx={tableStyles}>
            <SpellTableHeader character={character} isLoading={isLoading} />
            <Tbody>
              {spellsToShow.map((spell: Spell | undefined, index: number) =>
                spell ? (
                  <Tr key={spell.id}>
                    <SpellInfoCell edgePad={4} innerPad={2} sx={tableStyles} spell={spell} />
                    <SpellEffectCell spell={spell} character={character} innerPad={2} sx={tableStyles} />
                    <SpellDescriptionCell spell={spell} innerPad={2} sx={tableStyles} />
                  </Tr>
                ) : (
                  <Tr key={`loading-${index}`}>
                    <SpellInfoCell edgePad={4} innerPad={2} sx={tableStyles} spell={undefined} />
                    <SpellDescriptionCell spell={undefined} innerPad={2} sx={tableStyles} />
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </MainNavBar>
  )
}

function RepeatedBox({ count, direction = 'row', children }: { count: number; direction?: CSS.Property.FlexDirection; children: React.ReactNode }) {
  const elements = Array.from({ length: count }, (_, i) => (
    <Box key={`repeated-${i}`} mx={0.5} fontSize="16px" lineHeight="1" flexDirection={'column'} display="flex">
      {children}
    </Box>
  ))

  return (
    <Flex flexWrap="wrap" justifyContent="center" flexDirection={direction}>
      {elements}
    </Flex>
  )
}
const SpellTableHeader = ({ character, isLoading }: { character: Character | undefined; isLoading: boolean }) => {
  //   const spellcastingClasses = character?.spellcastingClasses()

  const characterName = character?.name() || undefined
  const spellcastingClasses = character?.classesAndSpellcastingAbilities()
  const classString = character?.classNamesForDisplay()

  // later return a fancy fake empty header
  if (!character || isLoading) return undefined

  const abilityStrings = spellcastingClasses?.map((scClass) => scClass.ability).filter((value) => value !== undefined)
  const dedupedAbilityStrings = [...new Set(abilityStrings || [])]
  const spellcastingAbilityString = dedupedAbilityStrings?.length === 1 ? spellcastingClasses![0].fullAbility : dedupedAbilityStrings?.join(' / ')

  const saveDcs = spellcastingClasses?.map((scClass) => scClass.spellSaveDC).filter((value) => value !== undefined)

  const spellAttackStrings = spellcastingClasses
    ?.map((scClass) => (scClass.spellAttack ? `+${scClass.spellAttack}` : undefined))
    .filter((value) => value !== undefined)
  const spellSaveDCString = [...new Set(saveDcs || [])]?.join(' / ')
  const spellAttackBonusString = [...new Set(spellAttackStrings || [])]?.join(' / ')

  return (
    <Thead key={`spell-table-header-${character.id()}`}>
      <Tr>
        <Td colSpan={3} px={4} py={1}>
          <Flex direction="row" alignItems="center" width="100%" justifyContent="space-between">
            {characterName && (
              <Flex direction="column" gap={-2}>
                <Flex fontSize={'2xl'} className="font-2xl" fontWeight={'bold'} mb={4} my={1} whiteSpace="normal" wordBreak="break-word">
                  {characterName}
                </Flex>

                <Flex fontSize="md" className="font-md" fontStyle={'italic'} whiteSpace="normal" wordBreak="break-word">
                  {classString}
                </Flex>
              </Flex>
            )}

            <Spacer />
            <OctoBoxValue title="Spellcasting Ability" value={spellcastingAbilityString || '–'} />
            <OctoBoxValue title="Spell Save DC" value={spellSaveDCString || '–'} />
            <OctoBoxValue title="Spell Attack Bonus" value={spellAttackBonusString || '–'} />
          </Flex>

          <Box alignContent={'center'} textAlign="right">
            {character && <SpellSlotBox title={'Spell Slots'} spellSlots={character.spellSlots()} />}
            {character && <SpellSlotBox title={'Pact Magic'} spellSlots={character.warlockSpellSlots()} />}
          </Box>
        </Td>
      </Tr>
    </Thead>
  )
}

function printPageStyles() {
  return `
        @page { 
            size: letter; 
        }
        tr { 
            page-break-inside: avoid; 
            break-inside: avoid;
        }
        td {
            page-break-inside: avoid;
            break-inside: avoid;
        }
    `
}

const sizeMap = { '2xs': 0.5, xs: 0.75, sm: 0.875, md: 1, lg: 1.125, xl: 1.25, '2xl': 1.375 }
//const sizeMap = { xs: 0.5, sm: 0.75, md: 0.875, lg: 1, xl: 1.125, '2xl': 1.25 }
//const sizeMap = { xs: 0.35, sm: 0.5, md: 0.75, lg: 0.875, xl: 1, '2xl': 1.125 }

function SpellSlotBox({ title, spellSlots }: { title: string; spellSlots: number[] }) {
  // Return null if spellSlots is empty or all values are 0
  if (!spellSlots || spellSlots.length === 0 || spellSlots.every((slot) => slot === 0)) {
    return null
  }
  return (
    <OctoBox maxW="100%" className="first-page-header-only">
      <Box fontWeight="bold" textAlign="center" mb={1} textTransform={'uppercase'} fontSize="sm">
        {title}
      </Box>
      <Box borderBottom="1px solid" borderColor="gray.200" />
      <Flex justifyContent="space-around" flexWrap="wrap" mt={1}>
        {spellSlots.map((slots, index) => {
          if (slots <= 0) return null // Skip levels with 0 slots
          const level = index + 1
          const ordinal = level === 1 ? '1st' : level === 2 ? '2nd' : level === 3 ? '3rd' : `${level}th`

          return (
            <Box key={`spell-slot-${level}`} textAlign="center" px={1} mb={2}>
              <Box fontWeight="semibold" fontSize="sm" mb={1}>
                {ordinal}
              </Box>

              <RepeatedBox count={slots}>◇</RepeatedBox>
            </Box>
          )
        })}
      </Flex>
    </OctoBox>
  )
}

const PrintStyles = () => {
  // Generate CSS classes for each font size
  const fontSizeClasses = Object.entries(sizeMap)
    .map(
      ([key, value]) =>
        `.font-${key} {
            font-size: ${value}rem !important;
            line-height: ${1.35 * value}rem !important;
        }`
    )
    .join('')

  return (
    <Global
      styles={css`
        @page {
          size: letter;
          margin: 0.25in;
        }
        @media print {
          html {
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
          }

          /* Font size classes */
          ${fontSizeClasses}

          /* Force all borders to be solid black in print */
         table,
         th,
         td {
            border-width: 1px !important;
            border-style: solid !important;
            border-collapse: collapse !important;
            border-color: inherit !important;
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
          }

          .no-print {
            display: none !important;
          }

          /* Hide the first-page header on all pages except first */
          .first-page-header-only {
            display: block;
            page-break-after: always;
          }

          /* Hide it when it appears again on subsequent pages */
          .first-page-header-only:not(:first-of-type) {
            display: none;
          }
        }
      `}
    />
  )
}
