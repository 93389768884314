class HTMLRow {
  cells: string[] = []
}

class HTMLTable {
  rows: HTMLRow[] = []
}

export class HTMLTableParser {
  static parseTableString(htmlString: string): HTMLTable {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const tableElement = doc.querySelector('table')
    const htmlTable = new HTMLTable()

    if (tableElement) {
      const rows = tableElement.querySelectorAll('tr')
      rows.forEach((rowElement) => {
        const htmlRow = new HTMLRow()
        const cells = rowElement.querySelectorAll('th, td')
        cells.forEach((cell) => {
          htmlRow.cells.push(cell.textContent || '')
        })
        htmlTable.rows.push(htmlRow)
      })
    }

    return htmlTable
  }
}
