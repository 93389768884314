import { Json } from '../Common/Types'
import { NumberMap } from '../Common/Interfaces'
import { DiceUtility } from '../Common/Utility'

export class Dice {
  diceCount: number
  diceValue: number
  diceMultiplier?: number
  originalDiceString: string
  fixedValue: number = 0

  constructor(diceBlob?: Json) {
    if (diceBlob === undefined || diceBlob === null) {
      this.diceCount = 0
      this.diceValue = 0
      this.originalDiceString = '0'
      return
    }

    this.diceCount = diceBlob.diceCount ?? 0
    this.diceValue = diceBlob.diceValue ?? 0
    this.originalDiceString = diceBlob.diceString ?? ''
    this.diceMultiplier = diceBlob.diceMultiplier
    this.fixedValue = diceBlob.fixedValue ?? 0
    if (!this.fixedValue || this.fixedValue === null) {
      this.fixedValue = 0
    }
  }

  diceString() {
    if (this.diceCount === 0 || this.diceValue === 0) {
      return this.fixedValue.toString()
    }

    const diceString = this.diceCount.toString() + 'd' + this.diceValue.toString()
    if (this.fixedValue) {
      if (this.fixedValue > 0) {
        return `${diceString} + ${this.fixedValue}`
      } else {
        return `${diceString}${this.fixedValue}`
      }
    }

    return diceString
  }

  copy(): Dice {
    return new Dice({
      diceCount: this.diceCount,
      diceValue: this.diceValue,
      fixedValue: this.fixedValue,
      diceString: this.diceString()
    })
  }

  double() {
    this.diceCount *= 2
    return this
  }

  max() {
    return this.diceCount * this.diceValue + this.fixedValue
  }

  min() {
    return this.diceCount
  }

  median() {
    return Math.floor(this.max() / 2)
  }

  avg() {
    return (this.diceCount * (this.diceValue + 1)) / 2 + this.fixedValue
  }

  isGreaterThan(otherDice: Dice) {
    return this.max() > otherDice.max()
  }

  static flatAmountDie(damage: number) {
    return new Dice({
      diceCount: 0,
      diceValue: 0,
      fixedValue: damage,
      diceString: damage.toString()
    })
  }

  static CreateFromString(diceString: string): Dice {
    if (!diceString || diceString.trim() === '') {
      return new Dice()
    }

    const trimmedString = diceString.trim()

    // Check if it's a flat amount (no 'd')
    if (!trimmedString.toLowerCase().includes('d')) {
      const value = parseInt(trimmedString)
      return isNaN(value) ? new Dice() : Dice.flatAmountDie(value)
    }

    // Parse dice notation
    // Match patterns like: "3d6", "3d6+5", "3d6-2"
    const dicePattern = /^(\d+)d(\d+)(?:([+-])(\d+))?$/i
    const match = trimmedString.match(dicePattern)

    if (!match) {
      return new Dice()
    }

    const diceCount = parseInt(match[1])
    const diceValue = parseInt(match[2])

    let fixedValue = 0
    if (match[3] && match[4]) {
      const modifierValue = parseInt(match[4])
      fixedValue = match[3] === '+' ? modifierValue : -modifierValue
    }

    return new Dice({
      diceCount,
      diceValue,
      fixedValue,
      diceString: trimmedString
    })
  }
  static Create(count: number, value: number, fixedValue: number = 0) {
    return new Dice({
      diceCount: count,
      diceValue: value,
      fixedValue: fixedValue
    })
  }
}

export class DiceCollection {
  dice: { [key: string]: number }
  modifier: number

  constructor() {
    this.dice = {}
    this.modifier = 0
  }

  copy(): DiceCollection {
    const newDiceCollection = new DiceCollection()
    newDiceCollection.dice = { ...this.dice }
    newDiceCollection.modifier = this.modifier
    return newDiceCollection
  }

  stripZeroes() {
    for (const die in this.dice) {
      if (this.dice[die] === 0) {
        delete this.dice[die]
      }
    }
  }

  // This returns undefined if there are multiple sets of dice
  singularDice(): Dice | undefined {
    if (!this.dice) return undefined

    const diceCount = Object.keys(this.dice).filter((d) => d !== '0').length
    if (diceCount !== 1) return undefined

    return this.firstDice()
  }

  firstDice(): Dice {
    if (!this.dice) return new Dice()

    for (const die in this.dice) {
      if (die === '0') continue
      return Dice.Create(this.dice[die], parseInt(die))
    }

    return new Dice()
  }

  addDice(dice: Dice) {
    if (this.dice[dice.diceValue]) {
      this.dice[dice.diceValue] += dice.diceCount
    } else {
      this.dice[dice.diceValue] = dice.diceCount
    }
    this.modifier += dice.fixedValue
    return this
  }

  subtractDice(dice: Dice) {
    if (this.dice[dice.diceValue] !== undefined) {
      this.dice[dice.diceValue] -= dice.diceCount

      // Remove the entry if count reaches 0 or less
      if (this.dice[dice.diceValue] <= 0) {
        delete this.dice[dice.diceValue]
      }
    }

    this.modifier -= dice.fixedValue
    return this
  }

  addDiceCollection(diceCollection: DiceCollection): DiceCollection {
    for (const die in diceCollection.dice) {
      if (this.dice[die]) {
        this.dice[die] += diceCollection.dice[die]
      } else {
        this.dice[die] = diceCollection.dice[die]
      }
    }

    this.modifier += diceCollection.modifier
    return this
  }

  addDiceList(diceList: Dice[]) {
    for (const die of diceList) {
      this.addDice(die)
    }
    return this
  }

  multiplyDice(diceMultiplier: number, modifierMultiplier: number = 1): DiceCollection {
    for (const die in this.dice) {
      this.dice[die] = this.dice[die] * diceMultiplier
    }

    this.modifier *= modifierMultiplier
    return this
  }

  maxDice(): DiceCollection {
    const { dice, modifier } = this
    const total = Object.entries(dice).reduce((acc, [die, count]) => acc + parseInt(die) * count, modifier)
    this.dice = {}
    this.modifier = total
    return this
  }

  modifierString(modifier: number): string {
    if (modifier >= 0) {
      return '+' + modifier.toString()
    }
    return modifier.toString()
  }

  displayString(rerollThreshold: number = 0, putConstantInFront: boolean = false, minRoll: number = 0, rerollDamageDice: number = 0): string {
    const consolidated = this.dice
    const modifierSum = this.modifier

    const noDice: boolean = Object.keys(this.dice).length === 0
    if (noDice) {
      return putConstantInFront ? this.modifierString(modifierSum) : String(modifierSum)
    }

    const sortedKeys = Object.keys(consolidated)
      .filter((key) => parseInt(key) > 0)
      .sort((a, b) => parseInt(b.slice(1)) - parseInt(a.slice(1)))
      .reverse()

    const minRollPrefix = minRoll > 0 ? `(${minRoll}>` : ''
    const minRollSuffix = minRoll > 0 ? ')' : ''

    let consolidatedString = sortedKeys.map((key) => `${consolidated[key]}${minRollPrefix}d${key}${minRollSuffix}`).join(' + ')

    if (this.dice.length === 0) {
      return String(this.modifier)
    }

    if (putConstantInFront) {
      const sumString = this.modifierString(modifierSum)
      // +3

      if (consolidatedString.length === 0) {
        return this.modifierString(modifierSum)
      }

      // +3 + 1d8 + 1d6
      if (modifierSum !== 0 && consolidatedString.length > 0) {
        return sumString + ' + ' + consolidatedString
      }
    }

    // TODO - this assumes sortedKeys.length === 1 (aka only one set of base damage dice)
    // We don't have a way of knowing WHICH dice to reroll

    const diceValue = sortedKeys[0]
    const diceCount = consolidated[diceValue]

    //rerollThreshold = 2 // TODO

    // Fake flames for now
    if (rerollDamageDice && !rerollThreshold) {
      const rerollCount = diceCount > rerollDamageDice ? rerollDamageDice : diceCount
      const remainingCount = diceCount > rerollDamageDice ? diceCount - rerollDamageDice : 0

      // NEED TO USE MIN ROLL PREFIX HERE
      consolidatedString = `${rerollCount}${minRollPrefix}(d${diceValue}!)${minRollSuffix}${remainingCount ? ` + ${remainingCount}${minRollPrefix}(d${diceValue})${minRollSuffix}` : ''}`
    } else if (rerollThreshold && !rerollDamageDice) {
      consolidatedString = `${diceCount}${minRollPrefix}(d${diceValue} reroll ${rerollThreshold})${minRollSuffix}`
    } else if (rerollThreshold && rerollDamageDice) {
      console.log('hi')
    }

    if (consolidatedString === '') {
      return String(modifierSum)
    }

    if (modifierSum > 0) {
      return `${consolidatedString} + ${modifierSum}`
    } else if (modifierSum < 0) {
      return `${consolidatedString} - ${Math.abs(modifierSum)}`
    }
    return consolidatedString
  }

  diceSumFrequency(): NumberMap {
    const diceList: number[][] = []
    diceList.push([this.modifier])
    for (const die in this.dice) {
      const diceCount = this.dice[die]
      const diceValue = parseInt(die)
      for (let i = 0; i < diceCount; i++) {
        diceList.push(DiceUtility.diceFaceList(diceValue))
      }
    }

    const combinations = this.diceSumMultiplier(diceList)
    const frequencyMap: NumberMap = new NumberMap()

    combinations.forEach((num) => {
      if (frequencyMap[num]) {
        frequencyMap[num]++
      } else {
        frequencyMap[num] = 1
      }
    })
    return frequencyMap
  }

  private diceSumMultiplier(diceList: number[][]): number[] {
    function _faceMultiply(a: number[], b: number[]): number[] {
      const combinations: number[] = []
      for (let i = 0; i < a.length; i++) {
        for (let j = 0; j < b.length; j++) {
          combinations.push(a[i] + b[j])
        }
      }
      return combinations
    }
    let combinations = [0]
    for (const dice of diceList) {
      combinations = _faceMultiply(combinations, dice)
    }
    return combinations
  }

  static CreateFromString(diceString: string): DiceCollection {
    if (!diceString || diceString.trim() === '') {
      return new DiceCollection()
    }

    // Split the string by + and - operators while preserving the operators (but later strip the +)
    const parts = diceString
      .trim()
      .replace(/\s+/g, '')
      .split(/(?=[+-])/g)

    // Create a base dice from the first part
    const resultDice = Dice.CreateFromString(parts[0])
    const diceCollection = new DiceCollection()
    diceCollection.addDice(resultDice)

    // Add any additional parts
    for (let i = 1; i < parts.length; i++) {
      const additionalDice = Dice.CreateFromString(parts[i].replace(/\+/g, ''))
      diceCollection.addDice(additionalDice)
    }

    return diceCollection
  }
}
