import React, { useState, useEffect } from 'react'
import { Box, Card, Text, Stack, Image, Icon, CardFooter } from '@chakra-ui/react'
import { IconUser } from '@tabler/icons-react'
import { SkeletonText } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { ShareDataInterface } from '../Common/Interfaces'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Flex } from '@chakra-ui/react'
import { BasicBadge } from '../Common/UIComponents'

function DPRBadge({ data }: { data: ShareDataInterface }) {
  if (!data.dpr) {
    return <Text>Error</Text>
  }
  const dpr = data.dpr.split('.')[0]
  const ac = `AC ${data.targetAC}`
  return (
    <Flex ps={1} pb={2}>
      <Text fontSize="md">
        <BasicBadge>{dpr}</BasicBadge> vs <BasicBadge>{ac}</BasicBadge>
      </Text>
    </Flex>
  )
}

export function CharacterStubCard({ stub, description, postName }: { stub: string; description?: string | 'empty'; postName?: string }) {
  const cachedData = localStorage.getItem(stub)
  const [data, setData] = useState<ShareDataInterface | undefined>(cachedData ? JSON.parse(cachedData) : undefined)
  const [loading, setLoading] = useState(cachedData === null)
  const [fetchComplete, setFetchComplete] = useState(cachedData !== null)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      if (cachedData) return

      try {
        const response = await fetch(`/api/s/${stub}?raw=true`, { cache: 'force-cache' })
        const result = await response.json()
        if (result.error) {
          console.error(`Error fetching stub ${stub}: ${result.error}`)
        } else {
          localStorage.setItem(stub, JSON.stringify(result))
        }

        setData(result)
      } catch (error) {
        console.error(`Error fetching stub ${stub}`, error)
      } finally {
        setLoading(false)
        setFetchComplete(true)
      }
    }

    fetchData()
  }, [stub, cachedData])

  const handleClick = (event: React.MouseEvent) => {
    if (postName) {
      navigate(`/post/${postName}`)
    } else {
      const url = `/c/${stub}`
      if (event.metaKey || event.ctrlKey) {
        window.open(url, '_blank')
      } else {
        navigate(url)
      }
    }
  }

  if (loading || !data) {
    return (
      <Card direction={'row'} overflow="hidden" variant="outline" maxW={{ base: '100%', sm: '100%' }} size="sm" bgColor={'bg.surface'}>
        <Box display="flex" justifyContent="center" alignItems="center" maxW={{ base: '100%', sm: '120px' }} maxH={{ base: '120px', sm: '150px' }}>
          <Icon as={IconUser} w={120} h={120} color="gray.500" />
        </Box>

        <Stack spacing={0} ps={2} alignContent={'flex-start'} flex="1">
          <SkeletonText mt={2} noOfLines={1} spacing={0} skeletonHeight={4} pb={4} pt={0} width={'40%'} />
          <SkeletonText mt={0} noOfLines={1} spacing={0} skeletonHeight={4} pb={4} pt={0} width={'60%'} />
          <SkeletonText mt={0} noOfLines={2} spacing={2} skeletonHeight={4} pb={2} pt={0} width={'90%'} />
        </Stack>
      </Card>
    )
  }

  if (fetchComplete && (!data || data.classNames === undefined)) {
    console.error('Error loading character', data)
    return <Text>Error loading character</Text>
  }

  const raceAndClass = `${data.race ? `${data.race} ` : ''}${data.classNames.split(' / ').join(', ')}`

  return (
    <Card direction={'row'} overflow="hidden" variant="outline" maxW={{ base: '100%', sm: '100%' }} size="sm" onClick={handleClick} cursor="pointer">
      {data.avatarUrl ? (
        <Image
          objectFit="cover"
          maxW={{ base: '100%', sm: '120px' }}
          maxH={{ base: '120px', sm: '150px' }}
          src={data.avatarUrl}
          alt={`Image of ${data.name}`}
        />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          maxW={{ base: '100%', sm: '120px' }}
          maxH={{ base: '120px', sm: '150px' }}
          bg="gray.200"
        >
          <Icon as={IconUser} w={120} h={120} color="gray.500" />
        </Box>
      )}

      <Stack spacing={0} ps={2} alignContent={'flex-start'} flex="1">
        <Flex flex="1" ps={1} pt={1} flexWrap="wrap">
          <Text fontSize="xl" fontWeight={'bolder'}>
            {data.name}
          </Text>
          <Box flex="1" />
          <DPRBadge data={data} />
        </Flex>
        <Text ps={1} fontSize="sm" lineHeight="tight" textAlign="left" width="100%" textColor={'gray.400'}>
          {raceAndClass}
        </Text>
        <Box flex="1" />
        <Text ps={1} textAlign={'left'} fontSize="sm" pb={1}>
          {description}
        </Text>
        <Box flex="1" />
      </Stack>

      <CardFooter p={1} display="flex" alignItems="center">
        <Icon as={ChevronRightIcon} w={6} h={6} />
      </CardFooter>
    </Card>
  )
}
