import { ActionParser } from './ActionParser'
import { Action } from '../CharacterJSON/Actions'
import { AttackAction } from '../AttackAction'
import { Dice } from '../Dice'
import { Activation } from '../CharacterJSON/Activation'
import { Dictionary } from '../../Common/Types'
import { Range } from '../CharacterJSON/Range'
import { Utility } from '../../Common/Utility'
import { ABILITY } from '../../Common/Types'

export class ActionParserRogue {
  static parse(parser: ActionParser, action: Action, attackActions: AttackAction[]): boolean {
    const name = action.name
    const character = parser.character

    if (character.classLevel('Rogue') === 0) return false

    if (name === 'Psychic Blades: Attack (DEX)' || name === 'Psychic Blades: Bonus Attack (DEX)') {
      const attack = this.createPsychicBlades(parser, action, 'dexterity')
      attackActions.push(attack)
      return true
    }

    if (name === 'Psychic Blades: Attack (STR)' || name === 'Psychic Blades: Bonus Attack (STR)') {
      const attack = this.createPsychicBlades(parser, action, 'strength')
      attackActions.push(attack)
      return true
    }

    if (name === 'Psychic Blades') {
      // 2024 Soulknife
      const activation = new Activation(action.activation)
      const die = new Dice(action.dice)
      const attack = this.createSoulknifePsychicBlades(parser, action.id, die, action, activation)
      attackActions.push(attack)
      return true
    }

    return false
  }

  static createSoulknifePsychicBlades(parser: ActionParser, id: number, dice: Dice, action: Dictionary, activation: Activation): AttackAction {
    const character = parser.character
    const name: string = action.name
    const abilityMod = character.finesseAbilityMod()
    const toHit = character.proficiencyBonus() + abilityMod
    const attackRange = Range.makeWeaponRange(60)
    const attributes = parser.thrownfinesseWeaponAttrs(attackRange)
    attributes.abilityName = 'Psychic Blades'
    // if (activation.usesAction()) { // TODO determine if this is RAW
    dice.fixedValue += abilityMod

    return new AttackAction(id, name, toHit, dice, attributes, activation)
  }

  static createPsychicBlades(parser: ActionParser, action: Dictionary, ability: ABILITY): AttackAction {
    const character = parser.character
    const id: number = action.id
    const name: string = action.name
    const abilityScore = character.abilityScore(ability)
    const abilityMod = Utility.modifierForScore(abilityScore)
    const toHit = character.proficiencyBonus() + abilityMod

    const attackRange = Range.makeWeaponRange(60)
    const attributes = parser.thrownfinesseWeaponAttrs(attackRange)
    const dice: Dice = new Dice(action.dice)
    dice.fixedValue += abilityMod
    const activation: Activation = new Activation(action.activation)
    return new AttackAction(id, name, toHit, dice, attributes, activation)
  }
}
