import React, { PropsWithChildren, ReactNode } from 'react'
import { Heading, Text, Link, Divider, List, ListItem, Image, Box, Flex } from '@chakra-ui/react'
import type { MDXComponents } from 'mdx/types'
import { CharacterStubCard } from './CharacterStubCard'
import { MiniCharacterSheet } from './CharacterSheet'
import { StatsRowForNumbers } from '../Common/UIComponents'
import { Table, Thead, Tfoot, Tbody, Tr, Th, Td } from '@chakra-ui/react'

const headerHeight = '2em'
const listIndent = 4

export const ChakraMDXComponents: MDXComponents = {
  // Standard MD
  h1: (props) => <Heading as="h1" fontSize="2em" lineHeight={headerHeight} {...props} />,
  h2: (props) => <Heading as="h2" fontSize="1.5em" lineHeight={headerHeight} {...props} />,
  h3: (props) => <Heading as="h3" fontSize="1.17em" lineHeight={headerHeight} {...props} />,
  h4: (props) => <Heading as="h4" fontSize="1em" lineHeight={headerHeight} {...props} />,
  h5: (props) => <Heading as="h5" fontSize="0.83em" lineHeight={headerHeight} {...props} />,
  h6: (props) => <Heading as="h6" fontSize="0.67em" lineHeight={headerHeight} {...props} />,
  p: (props) => <Text fontSize="1em" my={2} {...props} />,
  a: (props) => <Link color="blue.500" textDecoration="underline" {...props} />,
  blockquote: (props) => <Box borderLeft="4px solid" borderColor="gray.600" pl={listIndent} fontStyle="italic" {...props} />,
  ul: (props) => <List styleType="disc" fontSize={'lg'} pl={listIndent} spacing={0.5} mb={2} {...props} />,
  ol: (props) => <List as="ol" fontSize={'lg'} styleType="decimal" pl={listIndent} spacing={0.5} mb={2} {...props} />,
  li: (props) => <ListItem {...props} />,
  hr: (props) => <Divider borderWidth="2px" my={8} {...props} />,
  img: (props) => <Image borderRadius="md" {...props} />,
  code: (props) => (
    <Box as="span" display="inline-flex" bg="gray.700" px={1} borderRadius="md">
      <Box as="span" borderRadius="md" {...props} />
    </Box>
  ),

  // MD Tables
  table: (props) => <Table variant="simple" size="sm" width="auto" layout="fixed" my={2} {...props} />,
  thead: (props) => <Thead {...props} />,
  tbody: (props) => <Tbody {...props} />,
  tfoot: (props) => <Tfoot {...props} />,
  tr: (props) => <Tr {...props} />,
  th: (props) => <Th py={2} fontWeight="bold" textTransform="uppercase" fontSize="sm" textColor="white" {...props} />,
  td: (props) => <Td fontSize="md" {...props} />,

  // HTML Tables - the styling is a BIT different than the MD tables, we should fix that
  Table: (props) => <Table variant="simple" size="sm" width="auto" layout="fixed" mb={2} {...props} />,
  Thead: (props) => <Thead fontWeight="bold" textTransform="uppercase" fontSize="md" {...props} />,
  Tbody: (props) => <Tbody {...props} />,
  Tfoot: (props) => <Tfoot {...props} />,
  Tr: (props) => <Tr {...props} />,
  Th: (props) => <Th {...props} />,
  Td: (props) => <Td fontSize="md" {...props} />,

  // Custom Components
  Text: (props) => <Text fontSize="md" my={2} {...props} />,
  Stub: (props) => (
    <Box width={'100%'} minW="400" display="inline-flex" pb={4} mt={2}>
      <CharacterStubCard {...props} />
    </Box>
  ),
  CharacterBar: (props) => (
    <Box mb={2} my={3}>
      <MiniCharacterSheet {...props} />
    </Box>
  ),
  Stats: (props) => (
    <Box as="span" mb={2}>
      <StatsRowForNumbers {...props} bgColor={'gray.800'} px={2} py={1} />
    </Box>
  ),
  LevelBox: (props: PropsWithChildren) => <Flex padding={1}>{props.children}</Flex>,
  Level: (props: PropsWithChildren) => (
    <Box padding={2} borderWidth="1px" borderStyle="solid" borderColor="gray.700">
      {props.children}
    </Box>
  ),
  Box: (props) => <FlexTable {...props} />,
  Column: (props) => <FlexColumn {...props} />
}

// FlexTable: a container that mimics a table's outer boundary
// and lays out each column side by side (row) on desktop,
// stacking them on mobile.
const FlexTable = (props: PropsWithChildren) => (
  <Box
    border="1px"
    borderColor="gray.700"
    borderRadius="md"
    overflow="hidden"
    display="inline-flex"
    as={Flex}
    direction={{ base: 'column', md: 'row' }}
    wrap="wrap"
    mb={4}
    mt={2}
    {...props}
  />
)

type FlexColumnProps = PropsWithChildren<{
  // Header content for the column
  header?: ReactNode
  footer?: ReactNode
}>

// FlexColumn: a single “column” that displays a distinct header at the top,
// followed by normal cells (children).

const FlexColumn = ({ header, footer, children }: FlexColumnProps) => (
  <Box borderColor="gray.700" flex="1 0 auto" display="flex" flexDirection="column">
    {header && (
      <Box fontWeight="bold" textTransform="uppercase" fontSize="sm" borderBottom="1px" borderColor="gray.700" p={2} bgColor="gray.800">
        {header}
      </Box>
    )}

    {children && (
      <Box p={2} fontSize="md">
        {children}
      </Box>
    )}

    {footer && (
      <Box
        fontWeight="bold"
        textTransform="uppercase"
        borderTop="1px"
        borderBottom="1px"
        borderColor="gray.700"
        fontSize="sm"
        p={2}
        bgColor="gray.800"
        mt="auto"
        textColor={'gray.300'}
      >
        {footer}
      </Box>
    )}
  </Box>
)
