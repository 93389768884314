import { Activation } from './CharacterJSON/Activation'
import { Spell } from './Spell'
import { Utility } from '../Common/Utility'
import { Class, ABILITY } from '../Common/Types'
import { CLASS_SLOT_DIVISORS, FIGHTING_STYLES, MULTICLASS_SPELLCASTER_TABLE, SUBCLASS_SLOT_DIVISORS } from '../Common/Constants'
import { AttackAction } from './AttackAction'
import { Weapon } from './Weapon'
import { CharacterClass } from './CharacterClass'
import { CharacterFeat } from './Feat'
import { WeaponMastery } from './WeaponMastery'
import { Creature } from './CharacterJSON/Creature'
import { Dictionary } from '../Common/Types'
import { ActionParser } from './ActionParsers/ActionParser'
import { FightingStyle } from './FightingStyle'
import { Character } from './Character'
import { CustomizationValues } from './CustomizationValues'
import { Ammunition } from './Ammunition'
import { Feature } from './Feature'
import { AbilityScoreParser } from './AbilityScoreParser'
import { FeatureLoader } from './FeatureLoader'
import { ShareDataInterface, SpellSource } from '../Common/Interfaces'
import { Inventory, InventoryDefinition } from './CharacterJSON/Inventory'
import { Campaign } from './CharacterJSON/Campaign'
import { CharacterJSON } from './CharacterJSON/CharacterJSON'
import { Feat as FeatJSON } from './CharacterJSON/Feat'
import { DDBClass } from './CharacterJSON/DDBClass'
import { Modifier } from './CharacterJSON/Modifiers'
import { Spell as SpellJSON, SpellGroup, SpellList } from './CharacterJSON/Spell'
import { Feat } from './CharacterJSON/Feat'
import { Option } from './CharacterJSON/Options'

export type CustomizationValueMap = { [key: string]: CustomizationValues }

export class CharacterParser {
  name: string
  id: number
  classes: CharacterClass[]
  race: string
  hasShieldEquipped: boolean = false
  fightingStyles: FightingStyle[]
  abilityScores: number[]
  highestLevelSpellSlot: number
  proficiencyBonus: number
  shareData?: ShareDataInterface
  totalLevel: number
  loadedFromCache: boolean
  feats: CharacterFeat[]
  weaponMasteries: WeaponMastery[]
  weapons: Weapon[]
  allSpells: Spell[]
  hasOffHand: boolean = false
  preparedSpells: Spell[]
  attackActions: AttackAction[]
  features: Feature[]
  extraAttackActions: AttackAction[]
  avatarUrl: string
  attackCount: number = 1
  campaign?: Campaign

  // For development purposes only
  characterJsonForDevelopment?: CharacterJSON

  constructor(characterData: CharacterJSON) {
    // Load basics

    this.name = characterData.name
    this.id = characterData.id
    this.avatarUrl = characterData.decorations.avatarUrl
    this.race = characterData.race.fullName || characterData.race.baseRaceName
    this.loadedFromCache = characterData.loadedFromCache
    this.classes = this.loadRealClasses(characterData.classes, characterData.options.class)
    this.totalLevel = this.calculateTotalLevel(this.classes)
    this.highestLevelSpellSlot = this.calculateHighestLevelSpellSlot(this.classes)
    this.proficiencyBonus = this.calculateProficiencyBonus()
    this.feats = this.loadFeats(characterData.feats, characterData.modifiers.feat, characterData.options.feat)
    this.weaponMasteries = this.loadWeaponMasteries(characterData.modifiers.feat)
    this.abilityScores = AbilityScoreParser.loadAbilityScores(characterData)
    this.campaign = characterData.campaign

    // Load custom character values
    const customizations: CustomizationValueMap = this.loadCustomizations(characterData)

    this.hasOffHand = this.hasOffhandWeapon(characterData.inventory, customizations)
    this.fightingStyles = this.loadFightingStyles(characterData)

    // Equipment
    const character = new Character(this) // TODO - When it it safe to create this?
    this.hasShieldEquipped = character.isShieldEquipped(characterData.inventory)

    this.weapons = this.loadWeapons(character, characterData.inventory, customizations)

    const ammunition = this.loadAmmunition(characterData.inventory)

    // Spells
    const { spells, classSpells, subclassSpells, knownSpells } = characterData
    const spellSources: SpellSource[] = this.loadSpellSources(spells, classSpells, subclassSpells, knownSpells)
    this.allSpells = this.loadSpells(character, spellSources, customizations)
    this.preparedSpells = this.allSpells.filter((spell) => spell.isPreparedOrForceKnown).sort((a, b) => a.name.localeCompare(b.name))

    const creatures: Creature[] = characterData.creatures
    const spellIDsToPrune: number[] = []
    const actionParser = new ActionParser(character)
    this.extraAttackActions = actionParser.parse(characterData, this.weapons, creatures, this.preparedSpells, customizations, spellIDsToPrune)

    this.features = FeatureLoader.loadAllFeatures(
      characterData,
      this.weapons,
      this.weaponMasteries,
      this.feats,
      this.fightingStyles,
      this.extraAttackActions,
      ammunition,
      this.preparedSpells,
      spellIDsToPrune,
      character
    )

    // Now that we have all spellIDsToPrune, we can remove them from the spells list
    this.preparedSpells.forEach((spell) => {
      if (spellIDsToPrune.includes(spell.id)) spell.isEffect = true
    })

    this.attackActions = this.loadAttackActions(character, this.weapons, this.extraAttackActions)
    this.applyWeaponMasteriesToAttackActions(this.attackActions, this.weaponMasteries)
    this.applyWeaponMasteriesToAttackActions(this.extraAttackActions, this.weaponMasteries)

    this.attackCount = this.countAttacksPerTurn(characterData.modifiers.class)

    // Share Data for UI
    this.shareData = characterData.shareData

    if (Utility.isDevelopment) this.characterJsonForDevelopment = characterData
  }

  hasOffhandWeapon(inventoryData: Inventory[], customizations: CustomizationValueMap): boolean {
    return inventoryData.some((itemData: Inventory) => {
      const { definition, equipped, id } = itemData
      const { damage, canEquip } = definition
      return damage !== null && canEquip && equipped && id in customizations && customizations[id].isOffhand
    })
  }

  nonWarlockCasterLevel(classes: CharacterClass[]): number {
    let casterLevel: number = 0

    for (const characterClass of classes) {
      const { className, level, subclassName } = characterClass
      const spellSlotDivisor = CLASS_SLOT_DIVISORS[className as Class] || 0
      const subclassSlotDivisor = (subclassName && SUBCLASS_SLOT_DIVISORS[`${className}: ${subclassName}`]) || 0
      const divisor = Math.max(spellSlotDivisor, subclassSlotDivisor)
      if (divisor === 0) continue
      casterLevel += level / divisor
    }

    return Math.ceil(casterLevel)
  }

  spellSlots(): number[] {
    const casterLevel = this.nonWarlockCasterLevel(this.classes)
    return MULTICLASS_SPELLCASTER_TABLE[casterLevel]
  }

  calculateHighestLevelSpellSlot(classes: CharacterClass[]): number {
    const casterLevel = this.nonWarlockCasterLevel(classes)

    // console.log(this.name, this.highestLevelSpellSlotFromTable(Math.ceil(casterLevel), MULTICLASS_SPELLCASTER_TABLE))
    // This is the 5.5 logic. Later We can check each class to see if it's 2024 or not (Paladin and Artificer) to change rounding logic
    return this.highestLevelSpellSlotFromTable(casterLevel, MULTICLASS_SPELLCASTER_TABLE)
  }

  highestLevelSpellSlotFromTable(level: number, slotTable: number[][]): number {
    const table = slotTable[level]
    for (let i = table.length - 1; i >= 0; i--) {
      if (table[i] !== 0) {
        return i + 1
      }
    }
    return 0
  }

  countAttacksPerTurn(data: Modifier[]) {
    let extraAttackCount: number = 0
    for (const classFeature of data) {
      if (classFeature.subType === 'extra-attacks' && classFeature.isGranted) {
        extraAttackCount = Math.max(extraAttackCount, classFeature.value)
      }
    }

    return 1 + extraAttackCount
  }

  loadSpellSources(spells: SpellGroup, classSpells: SpellList[], subclassSpells?: SpellJSON[][], knownSpells?: SpellJSON[][]) {
    const spellSources: SpellSource[] = []

    for (const charClass of classSpells) {
      spellSources.push({ spells: charClass.spells, forceKnown: false })
    }

    if (subclassSpells) for (const scSpellList of subclassSpells) spellSources.push({ spells: scSpellList, forceKnown: true })

    if (knownSpells) for (const scSpellList of knownSpells) spellSources.push({ spells: scSpellList, forceKnown: true })

    spellSources.push({ spells: spells.class, forceKnown: false })
    spellSources.push({ spells: spells.race, forceKnown: true })
    spellSources.push({ spells: spells.feat, forceKnown: true })
    spellSources.push({ spells: spells.item, forceKnown: true })
    spellSources.push({ spells: spells.background, forceKnown: true })

    return spellSources
  }

  modifierForAbilityIndex(attrIndex: number) {
    const score = this.abilityScores![attrIndex]
    return Utility.modifierForScore(score)
  }

  scoreForAbility(ability: ABILITY) {
    const attrIndex = Utility.indexOfAbility(ability)
    return this.abilityScores![attrIndex]
  }

  modifierForAbility(ability: ABILITY) {
    const score = this.scoreForAbility(ability)
    return Utility.modifierForScore(score)
  }

  calculateProficiencyBonus(): number {
    return 1 + Math.ceil(this.totalLevel / 4)
  }

  is2024Class(name: Class): boolean {
    return this.classForClassName(name)?.is2024Class() || false
  }

  private loadSpells(character: Character, spellSources: SpellSource[], customizations: CustomizationValueMap): Spell[] {
    const spells: Spell[] = []

    for (const spellSource of spellSources) {
      const spellList = spellSource.spells
      if (spellList) {
        for (const spellData of spellList) {
          const spell = new Spell(character, spellData, customizations)

          // Skil weird empty spells
          if (spell.name === '' || spell.id === undefined) continue

          // Skip duplicate spells
          //   const existingSpells = spells.find((s) => s.id === spell.id)

          if (spells.some((s) => s.name === spell.name)) continue

          if (spell.name === 'Crown of Stars') {
            spell.activation = Activation.BonusAction()
          }

          if (spell.id in customizations) {
            const spellCustomization: CustomizationValues = customizations[spell.id]
            if (spellCustomization.name) spell.displayName = spellCustomization.name
          }

          spell.isPreparedOrForceKnown = spell.isPrepared || spellSource.forceKnown
          spells.push(spell)
        }
      }
    }

    return spells
  }

  classForClassName(name: Class): CharacterClass | undefined {
    return this.classes!.find((characterClass) => String(characterClass.className) === String(name))
  }

  classLevel(name: Class): number {
    return this.classForClassName(name)?.level || 0
  }

  loadCustomizations(characterData: CharacterJSON): CustomizationValueMap {
    const map: CustomizationValueMap = {}

    for (const valueData of characterData.characterValues) {
      let customValues: CustomizationValues = map[valueData.valueId]
      if (customValues === undefined) {
        customValues = new CustomizationValues(characterData.name, valueData.valueId)
        map[valueData.valueId] = customValues
      }

      customValues.addValue(valueData.valueId, valueData.typeId, valueData.value)
    }
    return map
  }

  loadAmmunition(inventoryData: Inventory[]): Ammunition[] {
    const ammo: Ammunition[] = []
    for (const inventoryItemData of inventoryData) {
      const definitionData: InventoryDefinition = inventoryItemData.definition
      const isEquipped = definitionData.canEquip === true && inventoryItemData.equipped === true
      const isAmmo = definitionData.type === 'Ammunition'
      if (isEquipped && isAmmo) {
        ammo.push(new Ammunition(definitionData))
      }
    }
    return ammo
  }

  loadWeapons(character: Character, inventoryData: Inventory[], customizations: CustomizationValueMap): Weapon[] {
    const weapons: Weapon[] = []
    for (const inventoryItemData of inventoryData) {
      const definitionData = inventoryItemData.definition
      const weaponBehaviors: Dictionary[] = definitionData.weaponBehaviors
      const hasDamage = definitionData.damage || weaponBehaviors.some((behavior) => 'damage' in behavior)
      const isEquipped = definitionData.canEquip === true && inventoryItemData.equipped === true
      const isWeapon = (definitionData.filterType === 'Weapon' || hasDamage) && definitionData.type !== 'Ammunition'

      if (isWeapon && isEquipped) {
        weapons.push(new Weapon(character, inventoryItemData, customizations))
      }
    }
    return weapons
  }

  loadFightingStyles(characterData: CharacterJSON): FightingStyle[] {
    const fightingStyles: FightingStyle[] = []

    for (const classOptionData of [characterData.feats, characterData.options.class, characterData.options.feat].flat()) {
      const definitionData = classOptionData.definition
      const optionName = definitionData.name
      if (FIGHTING_STYLES.includes(optionName)) {
        const fightingStyle = new FightingStyle(definitionData)
        fightingStyles.push(fightingStyle)
      }
    }

    return fightingStyles
  }

  calculateTotalLevel(classes: CharacterClass[]): number {
    return classes.reduce((totalLevel, characterClass) => totalLevel + characterClass.level, 0)
  }

  loadRealClasses(classes: DDBClass[], options: Option[]): CharacterClass[] {
    return classes.map((classData: DDBClass) => new CharacterClass(classData, options))
  }

  loadFeats(feats: Feat[], modifiers: Modifier[], options: Option[]): CharacterFeat[] {
    return feats.map((feat: FeatJSON) => new CharacterFeat(feat, modifiers, options))
  }

  loadWeaponMasteries(modifiers: Modifier[]): WeaponMastery[] {
    return modifiers.filter((modifier) => modifier.type === 'weapon-mastery').map((modifier) => new WeaponMastery(modifier))
  }

  abilityScoreForIndex(index: number): number {
    return this.abilityScores[index]
  }

  classAnalyticsNames(): Dictionary[] {
    return this.classes.map((characterClass) => {
      return {
        className: characterClass.className,
        subclassName: characterClass.subclassName ?? '',
        level: characterClass.level
      }
    })
  }

  applyWeaponMasteriesToAttackActions(attackActions: AttackAction[], masteries: WeaponMastery[]) {
    attackActions.forEach((attack) => {
      const mastery = masteries.find((mastery) => attack.attributes.weaponType === mastery.weaponType)
      attack.attributes.weaponMastery = mastery ? mastery.name : undefined
    })
  }

  loadAttackActions(character: Character, weapons: Weapon[], extraAttackActions: AttackAction[]): AttackAction[] {
    const allActions: AttackAction[] = weapons.map((weapon) => AttackAction.CreateFromWeapon(weapon, character))
    const attackActions: AttackAction[] = allActions.filter((action) => !action.attributes.isOffHand)
    attackActions.push(...extraAttackActions.filter((action) => action.activation.usesAction()))
    return attackActions
  }
}
