import { Feature } from './Feature'
import { TurnAction } from './TurnAction'

import { Character } from './Character'

export class TurnActionEngine {
  modifyFeaturesForTurnActions(features: Feature[], turnActions: TurnAction[]): void {
    // Handle the things that affect multiple turns up-front
    const elvenAccuracy = features.some((thisFeature) => thisFeature.effects.rerollOnAdvantage)
    const advantageValue = elvenAccuracy ? 2 : 1
    // Modify sneak attack feaure based on other active features
    this.modifyPactOfTheBlade(features)
    this.modifySneakAttack(features, turnActions, advantageValue)
    this.modifyDivineSmite(features)
    this.modifyHuntersMark(features)
  }

  applyFeaturesToTurnActions(features: Feature[], turnActions: TurnAction[], character: Character): void {
    if (turnActions.length === 0) {
      return
    }

    // Handle the things that affect multiple turns up-front
    const elvenAccuracy = features.some((thisFeature) => thisFeature.effects.rerollOnAdvantage)
    const advantageValue = elvenAccuracy ? 2 : 1

    this.applySneakAttackAdvantage(features, turnActions, advantageValue)
    this.applyAdvantageToNextAttacks(features, turnActions, advantageValue)
    this.applyPsyshicBladesVex(features, turnActions, advantageValue) // TODO - initial implementation of VEX… but just for psychic blades
    this.applyPsychicBladeRendMind(features, turnActions, advantageValue)

    // Set up conditional features
    this.configureBoomingBlade(features)

    for (const feature of features) {
      const { only, effects } = feature
      const oncePerTurn = only.oncePerTurn

      const addFeature = (featureCode: (turn: TurnAction) => void) => {
        // TODO - factor out only -> only and feature.effects -> effects
        for (const turn of turnActions) {
          if (!only.featureAppliesToTurn(feature, turn, features, turnActions)) {
            continue
          }

          const peekNextTurn = (currentTurn: TurnAction): TurnAction | undefined => {
            const currentIndex = turnActions.indexOf(currentTurn)
            if (currentIndex >= 0 && currentIndex < turnActions.length - 1) {
              return turnActions[currentIndex + 1]
            }
            return undefined
          }

          if (effects.applyToNextAttack) {
            // Workaround… also tag this weapon mastery as such, even if the effect doesn't trigger
            turn.specificweaponMasteryType = only.specificWeaponMasteryType

            // Fast forward this to the next turn
            const nextTurn = peekNextTurn(turn)
            if (nextTurn) featureCode(nextTurn)
          } else {
            featureCode(turn)
          }

          if (oncePerTurn) break
        }
      }

      effects.applyEffectsToTurn(addFeature, turnActions, elvenAccuracy, character, features, only)
    }
  }

  applyAdvantageToNextAttacks(features: Feature[], turnActions: TurnAction[], advantage: number): void {
    // This is a tricky case - if we have grappler AND the first attack is unarmed, then we apply advantage on subsequent melee attacks
    const nextAttacksHaveAdvantage: Feature | undefined = features.find((feature) => feature.effects.nextAttacksHaveAdvantage)
    if (nextAttacksHaveAdvantage) {
      if ((nextAttacksHaveAdvantage.only.abilityNamed === 'Psychic Blades') === false) {
        const grappler = features.find((feature) => feature.id === 1789147)
        if (grappler) {
          let foundUnarmed = false

          for (let i = 0; i < turnActions.length; i++) {
            if (!foundUnarmed && turnActions[i].isUnarmed()) {
              foundUnarmed = true
              continue
            }

            if (foundUnarmed) {
              const turn = turnActions[i]
              if (turn.isMeleeOrUnarmed()) {
                turn.advantage = advantage
              }
            }
          }
        }
      } else {
        // for (let i = 0; i < turnActions.length - 1; i++) {
        //   if (turnActions[i].isPsychicBlades()) {
        //     const nextTurn = turnActions[i + 1]
        //     nextTurn.advantage = elvenAccuracy ? 2 : 1
        //   }
        // }
      }
    }
  }

  applyPsyshicBladesVex(features: Feature[], turnActions: TurnAction[], advantage: number): void {
    if (features.some((feature) => feature.effects.nextPsychicBladeAttackHasAdvantage)) {
      for (let i = 0; i < turnActions.length - 1; i++) {
        if (turnActions[i].isAbilityNamed('Psychic Blades')) {
          const nextTurn = turnActions[i + 1]
          if (!nextTurn.damageRider) nextTurn.advantage = advantage
        }
      }
    }
  }

  applyPsychicBladeRendMind(features: Feature[], turnActions: TurnAction[], advantage: number): void {
    if (features.some((feature) => feature.effects.nextPsychicBladeAttacksHaveAdvantage)) {
      let foundPsychicBlades = false
      for (const turn of turnActions) {
        if (turn.isAbilityNamed('Psychic Blades')) {
          foundPsychicBlades = true
          continue
        }

        if (foundPsychicBlades) {
          turn.advantage = advantage
        }
      }
    }
  }

  applySneakAttackAdvantage(features: Feature[], turnActions: TurnAction[], advantageValue: number): void {
    // Assassinate is a sneak attack only but also provides advantage on all attacks
    const sneakAttackForcedAdvantage = features
      .filter((feature) => feature.modifiedFeature === 'Sneak Attack')
      .some((saFeature) => saFeature.effects.advantage)

    if (sneakAttackForcedAdvantage) {
      for (const turn of turnActions) {
        if (turn.isAttackRoll() && !turn.damageRider) {
          turn.advantage = turn.isCompanion() ? 1 : advantageValue
        }
      }
    }
  }

  modifyPactOfTheBlade(features: Feature[]): void {
    const potb = features.find((feature) => feature.name === `Pact of the Blade`)
    if (potb) {
      if (potb.only.featureNameEnabled === 'Hex Warrior') {
        // if it's the 2014 version {
        potb.only.meleeWeapon = true // reset it back to be melee weapon only
        potb.only.weapon = false
      }

      // Look for Improved Pact Weapon or similar…
      if (features.find((feature) => feature.only.pactWeapon && feature.effects.canAlsoUseBowsAndCrossbows)) {
        potb.only.meleeWeapon = false
        potb.only.weapon = true
      }
    }
  }

  modifyHuntersMark(features: Feature[]): void {
    const huntersMark = features.find((feature) => feature.name === `Hunter's Mark`)
    if (!huntersMark) return

    // Reset to initial state
    huntersMark.effects.advantage = false
    huntersMark.effects.additionalDamageOnHitDice = huntersMark.effects.huntersMarkDice?.copy()

    // Apply modifiers from features that affect hunter's mark
    const huntersMarkModifiers = features.filter((feature) => feature.modifiedFeature === 'Hunter’s Mark')
    for (const modifier of huntersMarkModifiers) {
      if (modifier.effects.replacementDamageDice) {
        huntersMark.effects.additionalDamageOnHitDice = modifier.effects.replacementDamageDice.copy()
      }

      if (modifier.effects.additionalDamageOnHitDice) {
        this.applyDamageModifier(huntersMark, modifier)
      }

      if (modifier.effects.advantage) {
        // TODO check if we need to filter this out as a damage rider
        huntersMark.effects.advantage = modifier.effects.advantage
      }
    }
  }

  private applyDamageModifier(feature: Feature, modifier: Feature): void {
    if (!feature.effects.additionalDamageOnHitDice) {
      feature.effects.additionalDamageOnHitDice = modifier.effects.additionalDamageOnHitDice?.copy()
    } else {
      feature.effects.additionalDamageOnHitDice.diceCount += modifier.effects.additionalDamageOnHitDice!.diceCount
      feature.effects.additionalDamageOnHitDice.fixedValue += modifier.effects.additionalDamageOnHitDice!.fixedValue
    }
  }

  modifyDivineSmite(features: Feature[]): void {
    const divineSmite = features.find((feature) => feature.name === 'Divine Smite')
    if (!divineSmite) return

    // Reset to original smite dice
    divineSmite.effects.additionalDamageOnHitDice = divineSmite.effects.smiteDice?.copy()
    divineSmite.effects.additionalDamageOnCritDice = undefined
    // divineSmite.only.oncePerTurn = true // part of onCritMod

    // Apply modifiers from other features that affect divine smite
    const smiteModifiers = features.filter((feature) => feature.modifiedFeature === 'Divine Smite')

    for (const modifier of smiteModifiers) {
      if (modifier.effects.additionalDamageOnHitDice) {
        this.applyDamageModifier(divineSmite, modifier)
      }
    }

    // TODO: Turning this off for now, it freaks out with the new level steppers
    // const onCritMod = smiteModifiers.find((feature) => feature.only.onCrit)
    // if (onCritMod) {
    //   divineSmite.effects.additionalDamageOnCritDice = divineSmite.effects.additionalDamageOnHitDice?.copy().double()
    //   divineSmite.effects.additionalDamageOnHitDice = undefined
    //   divineSmite.only.oncePerTurn = onCritMod.only.oncePerTurn
    // }
  }

  modifySneakAttack(features: Feature[], turnActions: TurnAction[], advantage: number): void {
    // TODO - change these to be constant numbers
    const saMod = features.find((feature) => feature.name === 'Sneak Attack')
    const cunningStrikePoison = features.find((feature) => feature.name === 'Cunning Strike: Poison (Cost: 1d6)')

    if (cunningStrikePoison) {
      // Reset to original dice
      cunningStrikePoison.effects.additionalDamageOnHitDice = cunningStrikePoison.effects.cunningStrikeDice?.copy()

      // Apply modifiers from features that affect cunning strike poison
      const poisonModifiers = features.filter((f) => f.modifiedFeature === 'Cunning Strike: Poison (Cost: 1d6)')
      for (const modifier of poisonModifiers) {
        const additionalDice = modifier.effects.additionalDamageOnHitDice
        if (!additionalDice) continue

        this.applyDamageModifier(cunningStrikePoison, modifier)
      }
    }

    if (!saMod) return

    this.resetSneakAttack(saMod)
    this.applySneakAttackModifiers(saMod, features)
    this.handlePostSneakAttackEffects(saMod, turnActions, advantage)
  }

  private resetSneakAttack(saMod: Feature): void {
    saMod.effects.damageMultiplier = 1
    saMod.effects.additionalDamageOnHitDice = saMod.effects.sneakAttackDice?.copy()
  }

  private applySneakAttackModifiers(saMod: Feature, features: Feature[]): void {
    const saModFeatures = features.filter((feature) => feature.modifiedFeature === 'Sneak Attack')

    saMod.effects.nextAttacksHaveAdvantage = saModFeatures.some((f) => f.effects.nextAttacksHaveAdvantage)
    saMod.effects.attackAfterSneakAttackAutoCrits = saModFeatures.some((f) => f.effects.attackAfterSneakAttackAutoCrits)
    saMod.effects.attackAfterSneakAttackHasAdvantage = saModFeatures.some((f) => f.effects.attackAfterSneakAttackHasAdvantage)

    for (const samFeature of saModFeatures) {
      if (samFeature.effects.additionalDamageOnHitDice && saMod.effects.additionalDamageOnHitDice) {
        saMod.effects.additionalDamageOnHitDice.diceCount += samFeature.effects.additionalDamageOnHitDice.diceCount
        saMod.effects.additionalDamageOnHitDice.fixedValue += samFeature.effects.additionalDamageOnHitDice.fixedValue

        if (saMod.effects.additionalDamageOnHitDice.diceCount <= 0) {
          saMod.effects.additionalDamageOnHitDice = undefined
        }
      }

      if (samFeature.effects.damageMultiplier !== 1) {
        saMod.effects.damageMultiplier = samFeature.effects.damageMultiplier
      } else {
        saMod.effects.damageMultiplier = 1
      }
    }
  }

  private handlePostSneakAttackEffects(saMod: Feature, turnActions: TurnAction[], advantage: number): void {
    if (!saMod.effects.attackAfterSneakAttackAutoCrits) return

    const sneakAttackIndex = turnActions.findIndex(
      (turn) => turn.isWeapon() && (turn.hasWeaponProperty('Finesse') || turn.hasWeaponProperty('Range'))
    )

    const nextAttack = turnActions.slice(sneakAttackIndex + 1).find((turn) => turn.isAttackRoll())
    if (nextAttack) {
      nextAttack.autoCrit = true
      if (saMod.effects.attackAfterSneakAttackHasAdvantage && !nextAttack.damageRider) {
        nextAttack.advantage = advantage
      }
    }
  }

  configureBoomingBlade(features: Feature[]): void {
    const bb = features.find((feature) => feature.effects.isBoomingBlade)

    if (bb) {
      bb.effects.targetMoved = features.some((feature) => feature.effects.isBoomingBladeTargetMoved)
    }
  }
}
