import { Dictionary } from './Types'
import { DiceEvaluation } from '../Dice/DiceEvaluation'

export class DictionaryCache {
  private cache: { [key: string]: Dictionary }

  constructor() {
    this.cache = {}
  }

  set(key: string, value: Dictionary): void {
    this.cache[key] = value
  }

  get(key: string): Dictionary | undefined {
    return this.cache[key]
  }

  has(key: string): boolean {
    return key in this.cache
  }
}

export class NumberCache {
  private cache: { [key: string]: number }

  constructor() {
    this.cache = {}
  }

  set(key: string, value: number): void {
    this.cache[key] = value
  }

  get(key: string): number | undefined {
    return this.cache[key]
  }

  has(key: string): boolean {
    return key in this.cache
  }
}

export class DiceEvaluationCache {
  private cache: { [key: string]: DiceEvaluation }

  constructor() {
    this.cache = {}
  }

  set(key: string, value: DiceEvaluation): void {
    this.cache[key] = value
  }

  get(key: string): DiceEvaluation | undefined {
    return this.cache[key]
  }

  has(key: string): boolean {
    return key in this.cache
  }
}
