import React from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { Dispatcher } from '../Common/Types'
import { Line } from 'react-chartjs-2'
import { useEffect, useState } from 'react'

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend)

export enum ChartType {
  Bar = 'bar',
  Line = 'line'
}

export interface ChartData {
  labels: string[]
  datasets: ChartDataset[]
}

export interface ChartDataset {
  label: string
  data: number[]
}

export function LineChart({ data, acState, requiresSave }: { data: ChartData; acState: [number, Dispatcher<number>]; requiresSave: boolean }) {
  const [, setChartAC] = acState
  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          boxWidth: 12, // make legend boxes smaller
          boxHeight: 12,
          padding: 10 // reduce padding between items
        }
      },

      tooltip: {
        titleFont: {
          size: 16
        },
        bodyFont: {
          size: 14
        },
        mode: 'index' as 'index',
        intersect: false,
        callbacks: {
          title: function (context: any) {
            const ac = parseInt(context[0].label)

            if (requiresSave) {
              setChartAC(ac + 10)
              return `DPR vs ${ac >= 0 ? `+` : ``}${ac} Save`
            } else {
              setChartAC(ac)
            }
            return `DPR vs AC ${ac}`
          },

          label: function (context: any) {
            return ` ${Number(context.raw).toFixed(2)} average`
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0 // Set the minimum value of the Y-axis to 0
      }
    },
    animation: {
      duration: 400
    }
  }
  return <Line options={lineOptions} data={data} />
}

export function BarChart({ data, chartAC }: { data: ChartData; chartAC: number }) {
  const [previousAC, setPreviousAC] = useState(-1)

  const animate = previousAC === chartAC

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: true
      },
      tooltip: {
        titleFont: {
          size: 16
        },
        bodyFont: {
          size: 14
        },
        mode: 'index' as 'index',
        intersect: false,
        callbacks: {
          title: function (context: any) {
            return ` ${context[0].label} damage`
          },

          label: function (context: any) {
            return ` ${(100 * context.raw).toFixed(2)}% chance`
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0, // Set the minimum value of the Y-axis to 0
        ticks: {
          callback: function (value: any) {
            return Math.floor(100 * value).toString() + '%'
          }
        }
      }
    },
    animation: {
      duration: animate ? 400 : 0 // general animation time
    }
  }

  useEffect(() => {
    setPreviousAC(chartAC)
  }, [chartAC])
  return <Line options={barOptions} data={data} />
}
