import React, { ElementType, ReactNode } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Heading, Icon, Image, SimpleGrid, Square } from '@chakra-ui/react'
import { Stack, Text, Link, Box } from '@chakra-ui/react'
import { DprTab, MainNavBar } from './MainNavBar'
import { handleCharacterButtonClick } from '../Common/Handlers'
import { FaDice, FaChartBar, FaMagic, FaFileAlt } from 'react-icons/fa'
import { SettingsIcon } from '@chakra-ui/icons'
import { FaPeopleCarry } from 'react-icons/fa'
import { TrackPageView, PAGE_WELCOME } from '../Common/AppAnalytics'
import { URLEntryBar } from '../Common/UIComponents'
import { CharacterStubCard } from './CharacterStubCard'
import { DISCORD_URL } from '../Common/Constants'

const accentColor = 'blue.200'

export const features = [
  {
    name: 'Smart Rules Engine',
    description: "Uses a custom rules engine to apply multiple D&D 5e rules to your turn actions, so you don't have to memorize them all",
    icon: SettingsIcon
  },
  {
    name: 'Dice Calculation',
    description: 'Does all of the dice math for you, so you can focus on building fun characters',
    icon: FaDice
  },
  {
    name: 'Charts',
    description: "Powerful interactive charts to help you visualize your character's damage output",
    icon: FaChartBar
  },
  {
    name: 'External Effect Simulation',
    description: 'Try out what your character can do if they have advantage, bless, haste, and more',
    icon: FaMagic
  },
  {
    name: 'Comprehensive',
    description:
      'Supports both 2014 and 2024 PHB and understands your class features, feats, racial traits, spells, weapon masteries, fighting styles, and more',
    icon: FaFileAlt
  },
  {
    name: 'Community',
    tsElement: (
      <Text>
        Join our{' '}
        <Link color={accentColor} target="_blank" href={DISCORD_URL}>
          Discord
        </Link>{' '}
        to share and discuss builds, report bugs, and suggest new features
      </Text>
    ),
    icon: FaPeopleCarry
  }
]

function SlantedBox({ children }: { children: ReactNode }) {
  return (
    <Box
      mx="auto"
      maxW={{ base: '90%', md: '100%' }}
      transition="transform 0.5s ease"
      _hover={{ transform: 'perspective(1000px) rotateX(5deg) rotateY(-5deg)' }}
      borderRadius="lg"
      overflow="hidden"
      boxShadow="xl"
    >
      {children}
    </Box>
  )
}

export function WelcomePage() {
  const [urlFieldValue, setUrlFieldValue] = useState('')
  const navigate = useNavigate()
  function handleButtonClick() {
    handleCharacterButtonClick(navigate, urlFieldValue)
  }
  const title = 'DPRcalc'

  TrackPageView(PAGE_WELCOME)

  const element = (
    <Container py={{ base: '4', md: '8' }}>
      <Stack spacing={{ base: '8', md: '12' }} justifyContent="center" textAlign={'center'}>
        <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 4, md: 8 }} align="center">
          <Stack spacing="3" flex="6">
            <Text fontSize={{ base: 'md', md: 'lg' }} fontWeight="semibold" color={accentColor}>
              Introducing
            </Text>
            <Heading size={{ base: 'sm', md: 'md' }}>{title}</Heading>
            <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
              Theory craft and explore the damage of your D&D 5e characters!
            </Text>
            <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
              Enter your character to get started or explore example builds below.
            </Text>

            <URLEntryBar urlFieldData={[urlFieldValue, setUrlFieldValue]} onButtonClick={handleButtonClick} />
          </Stack>

          <Box
            flex={4}
            mx="auto"
            maxW={{ base: '90%', md: '100%' }}
            transform="perspective(1000px) rotateX(5deg) rotateY(-5deg)"
            transition="transform 0.5s ease"
            _hover={{ transform: 'perspective(1000px) rotateX(0deg) rotateY(0deg)' }}
            borderRadius="lg"
            overflow="hidden"
            boxShadow="xl"
          >
            <Link href="https://dprcalc.com/s/Korinn-Kalazar-BTWeg">
              <Image src="/example.jpg" alt="DPRcalc screenshot" width="100%" />
            </Link>
          </Box>
        </Stack>

        <Heading size={{ base: 'sm', md: 'sm' }}>Explore some example builds</Heading>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} columnGap={8} rowGap={{ base: 4, md: 6 }}>
          <SlantedBox>
            <CharacterStubCard
              stub="Reya-Lightbringer-2F8eF"
              description="This lucky Paladin with a pact weapon never misses, even against heavily armored foes."
            />
          </SlantedBox>

          <SlantedBox>
            <CharacterStubCard
              stub="The-Angry-Monk-hMELq"
              description="Stun, grapple, and dish out reliable damage every round with this strength-based dagger-wielding monk."
            />
          </SlantedBox>
          <SlantedBox>
            <CharacterStubCard stub="Nezza-qsBcw" description="A shadowy assassin who peppers their enemies with blows in the darkness." />
          </SlantedBox>
          <SlantedBox>
            <CharacterStubCard
              stub="Tikram-Ainik-NLmJc"
              description="A simple yet effective Orcish berserker swinging their greatsword as hard as they can."
            />
          </SlantedBox>
          <SlantedBox>
            <CharacterStubCard
              stub="Barnaby-Moonfall-tFSN8"
              description="This dual-wielding rogue stacks sneak attacks with hunters mark every round thanks to their vow of enmity."
            />
          </SlantedBox>
          <SlantedBox>
            <CharacterStubCard
              stub="Lucien-Blackfyre-1jVu8"
              description="Eldritch Smiting Hexblade Warlock with Elven Accuracy results in frequent and brutal critical hits."
            />
          </SlantedBox>
        </SimpleGrid>

        <Heading size={{ base: 'sm', md: 'sm' }}>Features</Heading>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} columnGap={8} rowGap={{ base: 4, md: 8 }}>
          {features.map((feature) => (
            <Stack key={feature.name} spacing={{ base: '4', md: '5' }}>
              <Square size={{ base: '10', md: '12' }} bg={accentColor} color="fg.inverted" borderRadius="lg" alignSelf={'center'}>
                <Icon as={feature.icon as ElementType} boxSize={{ base: '5', md: '6' }} />
              </Square>
              <Stack spacing={{ base: '1', md: '2' }} flex="1">
                <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="medium">
                  {feature.name}
                </Text>
                {feature.description && <Text color="fg.muted">{feature.description}</Text>}
                {feature.tsElement && feature.tsElement}
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  )

  return (
    <Box>
      {/* <WeeklyAlert /> */}
      <MainNavBar tab={DprTab.HOME}>{element}</MainNavBar>
    </Box>
  )
}
