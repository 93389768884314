import { Dice } from '../Dice'
import { FeatureSource } from '../FeatureSource'
import { FeatureParserBase } from './FeatureParserBase'
import { GrantedModifier, InventoryDefinition } from '../CharacterJSON/Inventory'

export class FeatureParserItemEffect {
  static parse(parser: FeatureParserBase): boolean {
    const { feature, action } = parser
    const { only, effects, name } = feature
    if (feature.featureSource !== FeatureSource.Item) return false
    const item: InventoryDefinition = action as InventoryDefinition

    const hasGrantedMods = item.grantedModifiers && item.grantedModifiers.length > 0
    feature.rarityString = item.rarity.toLowerCase().replace(' ', '')

    // First try to grab generic punchy items.
    // Some assumptions here… but works for now.

    if (hasGrantedMods) {
      const mods = item.grantedModifiers
      let foundFlatDamage,
        foundBonus,
        foundDiceDamage = false
      const knownWeaponBoostSubtypes = ['unarmed-attacks', 'shortbow', 'longbow', 'crossbow', 'natural-attacks', 'necrotic']
      for (const modifier of mods) {
        const { value, type, dice, subType } = modifier
        if (!knownWeaponBoostSubtypes.includes(subType)) continue

        const onCrit = modifier.restriction.includes('20 on the Attack Roll')

        if (type === 'bonus' && value > 0 && !foundBonus) {
          effects.additionalToHitDice = Dice.flatAmountDie(value)
          foundBonus = true
        } else if (type === 'damage') {
          let damageDice = onCrit ? effects.additionalDamageOnCritDice : effects.additionalDamageOnHitDice

          if (!effects.additionalDamageOnHitDice) damageDice = Dice.Create(0, 0, 0)
          if (!damageDice) damageDice = new Dice()

          if (value > 0 && !foundFlatDamage) {
            damageDice.fixedValue += value
            foundFlatDamage = true
          }

          if (dice && !foundDiceDamage) {
            damageDice.diceValue += dice.diceValue ?? 0
            damageDice.diceCount += dice.diceCount ?? 0
            foundDiceDamage = true
            if (modifier.restriction === '(Ravenous strike)') only.oncePerTurn = true
          }

          if (onCrit) {
            effects.additionalDamageOnCritDice = damageDice
          } else {
            effects.additionalDamageOnHitDice = damageDice
          }
        }
      }

      if (foundFlatDamage || foundBonus || foundDiceDamage) {
        feature.defaultEnabled = true
        // TODO - later we can do 'if in this specific list of stuff' method, way cleaner. And can also simplify all the weapon .onlys!
        const unarmed = mods.some((m: GrantedModifier) => m.subType === 'unarmed-attacks')
        const shortbow = mods.some((m: GrantedModifier) => m.subType === 'shortbow')
        const longbow = mods.some((m: GrantedModifier) => m.subType === 'longbow')
        const natural = mods.some((m: GrantedModifier) => m.subType === 'natural-attacks')
        const necrotic = mods.some((m: GrantedModifier) => m.subType === 'necrotic')

        if (shortbow || longbow) only.bow = true
        else if (unarmed) only.unarmed = true
        else if (natural) only.unarmedOrWildshape = true
        else if (necrotic) only.weapon = true
        else console.error('Unhandled weapon type when parsing items', item)
        return true
      }
    }

    if (name === 'Gloves of Soul Catching') {
      only.unarmed = true
      feature.defaultEnabled = true
      effects.additionalDamageOnHitDice = Dice.Create(2, 10)
      return true
    }

    // Finally, look for things that boost spell save DC or spell attack modifiers - Bloodwell Vial, Moon Sickle, All Purpose Tool, etc

    let foundSpellAttackOrDCItem = false
    if (hasGrantedMods && item.grantedModifiers.some((m: GrantedModifier) => m.subType.includes('spell-attacks'))) {
      const spellAttackBonus = this.calculateSpellModsOfType(item, 'spell-attacks')
      effects.additionalToHitDice = Dice.flatAmountDie(spellAttackBonus)
      foundSpellAttackOrDCItem = true
    }

    if (hasGrantedMods && item.grantedModifiers.some((m: GrantedModifier) => m.subType.includes('spell-save-dc'))) {
      effects.spellSaveDCIncrease = this.calculateSpellModsOfType(item, 'spell-save-dc')
      foundSpellAttackOrDCItem = true
    }

    if (foundSpellAttackOrDCItem) {
      only.spell = true
      feature.defaultEnabled = true

      if (effects.spellSaveDCIncrease > 0 && effects.additionalToHitDice && effects.additionalToHitDice?.fixedValue > 0) {
        feature.notes = `+${effects.additionalToHitDice.diceString()} to spell attack rolls and +${effects.spellSaveDCIncrease} to saving throw DCs`
      } else if (effects.spellSaveDCIncrease > 0) {
        feature.notes = `+${effects.spellSaveDCIncrease} to spell saving throw DCs`
      } else if (effects.additionalToHitDice && effects.additionalToHitDice?.fixedValue > 0) {
        feature.notes = `+${effects.additionalToHitDice.diceString()} to spell attack rolls`
      } else {
        console.error('Got a spell attack or dc item but no increase?', item)
      }

      return true
    }

    if (name === "Illusionist's Bracers") {
      only.oncePerTurn = true
      only.cantrip = true
      effects.convertActionToBonusAction = true
      feature.defaultEnabled = true
      feature.notes = 'Cast a cantrip again as a bonus action'
      return true
    }

    return false
  }

  // 2024 now has druid-spell-save-dc and ranger-spell-save-dc… only grab one for now
  // TODO: Once we support multi-class spell save DC, we'll have to update this
  static calculateSpellModsOfType(item: InventoryDefinition, type: string): number {
    const appliedIDs: number[] = []
    let modifierValue = 0
    for (const mod of item.grantedModifiers) {
      // TODO - fix this, how do we see if an item is attuned or not?
      //   if (mod.requiresAttunement && !item.isAttuned) continue

      if (mod.type === 'bonus' && mod.subType.includes(type)) {
        const modId = item.id
        if (appliedIDs.includes(modId)) continue
        appliedIDs.push(modId)

        modifierValue += mod.value
      }
    }

    return modifierValue
  }
}
