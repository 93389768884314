import React from 'react'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { CharacterPage } from './CharacterPage'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react'
import { TabIndicator } from '@chakra-ui/react'
import { DprTab, MainNavBar } from './MainNavBar'
import { Campaign } from '../DDB/CharacterJSON/Campaign'

export function CharacterPageTabs() {
  const { id } = useParams<{ id: string }>()
  const cid: string = id ?? '0'
  const [campaign, setCampaign] = useState<Campaign | undefined>(undefined)
  const [characterName, setCharacterName] = useState<string>('Loading…')

  // A good way to log if we have duplicate strings in NON_BUFF_ACTIONS
  //   useEffect(() => {
  //     setTimeout(() => {
  //       const strings = FeatureParserBase.logNonBuffClassFeatureMap()
  //       const nonBuffActions = NON_BUFF_ACTIONS.filter((action) => !strings.includes(action))
  //       console.log('total actions:', NON_BUFF_ACTIONS.length)
  //       console.log('Non-buff actions:', strings.length)
  //       console.log('remaining actions:', nonBuffActions.length)
  //       console.log(nonBuffActions)
  //     }, 5000)
  //   }, [id])

  const otherCharacters = campaign?.characters ? campaign.characters.filter((c) => String(c.characterId) !== cid && c.isPublic()) : []

  const multipleCharacters = otherCharacters.length > 0

  const showParty = true

  let element = undefined
  if (!showParty || !multipleCharacters) {
    element = <CharacterPage id={cid} isPrimary={true} setCampaign={setCampaign} setCharacterName={setCharacterName} />
  } else {
    element = (
      <Box borderBottomWidth="1px" width="100%">
        <Tabs variant="underline" defaultIndex={1}>
          <TabList overflowX="auto" overflowY="hidden" px={4}>
            {campaign?.name && (
              <Tab fontSize={'md'} isDisabled>
                {campaign.name}
              </Tab>
            )}
            <Tab fontSize={'md'}>{characterName}</Tab>
            {otherCharacters.map((character) => (
              <Tab key={character.characterId} fontSize={'md'}>
                {character.characterName}
              </Tab>
            ))}
          </TabList>
          <TabIndicator />
          <TabPanels>
            <TabPanel key="campaign"></TabPanel>
            <TabPanel key={id} px={0} py={0}>
              <CharacterPage id={cid} isPrimary={true} setCampaign={setCampaign} setCharacterName={setCharacterName} />
            </TabPanel>
            {otherCharacters.map((character) => (
              <TabPanel key={character.characterId} px={0} py={0}>
                <CharacterPage id={String(character.characterId)} isPrimary={false} setCampaign={setCampaign} setCharacterName={setCharacterName} />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    )
  }

  return <MainNavBar tab={DprTab.CHARACTER}>{element}</MainNavBar>
}
