export function LoadDPRTestSlugs(): string[] {
  return [
    'Artillerist-Rondo-QJbHA', // Artillerist-Rondo-lbq3u
    'Weslocke-frvX5',
    'Karlach-Lceue',
    'Reya-Greenleaf-wvrrR',
    'Fake-Bunny-w2jcf',
    'Laezel-zpsPp',
    'Flemin-9ex5Z',
    'Nameroc-WPb3b',
    'Gale-ZCE47',
    'Barnaby-ROFxs',
    'Soulknife-Rogue-iOsm5',
    'Sorc-iPmZd',
    'Shadowheart-1hVdF',
    'Vadania-Amakiir-HODhH',
    '2024-Dance-Bard-AO62x',
    '2024-Rogue-Soulknife-o4IGr',
    '2024-Cleric-5MaOM',
    '2024-Zealot-Barb-92Top',
    '2024-Berserker-Barb-DmuZS',
    '2024-Valor-Bard-KmT3b',
    'D4-Ep-30-The-Needler-W2bDs',
    'D4-171-The-Baseline-y4RLr',
    'DnDShorts-Hexblade-DdzNq',
    'D4-170-Crit-Fisher-20-eec5f',
    'Grappla-LQQCZ',
    '2024-Elemental-Glam-Bard-LUsT7',
    'Fighty-McStyles-WT4iE',
    '2024-Sea-Druid-1YGnO',
    'Thoronn-2024-HKBP0',
    '2024-Light-Cleric-ZhXKI',
    '2024-Moon-Druid-Z0iZb',
    '2024-Evoker-Wiz-ZJHKU',
    '2024-Soulknife-Cunning-OPMkE',
    '2024-Rogue-Thief-47M2Z',
    '2024-Rogue-Assassin-LpFoj',
    '2024-Warlock-Familiars-8oNE7',
    '2024-Warlock-Archfey-yji1X',
    '2024-Warlock-Fiend-orAye',
    '2024-Evoker-Wiz-3-f6ojN',
    '2024-Battle-Master-Fighter-8bclM',
    '2024-Champion-Fighter-swpjV',
    '2024-EK-Fighter-C4XaN',
    '2024-Psi-Warrior-Fighter-XwuWC',
    '2024-Weapon-Mastery-Fighter-SOXxF',
    '2024-Devotion-Paladin-F5XZS',
    '2024-Glory-Paladin-Gf8E6',
    '2024-Mercy-Monk-aKdnV',
    '2024-Open-Hand-Monk-cJhIH',
    '2024-Elements-Monk-GmZJg',
    '2024-Beast-Master-Ranger-ByUg4',
    '2024-Hunter-Ranger-poH2j',
    '2024-Gloomstalker-Ranger-3J3Hz',
    '2024-Fey-Wanderer-Ranger-dY2Pb',
    'The-Angry-Monk-byby1',
    'Bear-Hugger-HyCiI',
    '2024-Stars-Druid-AoUuj',
    'Lucien-Blackfyre-kqtWw',
    'Lucien-Blackfyre-P1QRI',
    'Niko---Death-Cleric-Dt3wj',
    'Reya-Dawnbringer-LA7hp',
    'The-Angry-Monk-EYtNI',
    'Nezza-FfgmR',
    'Tikram-Ainak-WI6GR',
    'Barnaby-Moonfall-5I8Ko',
    'Barnaby-Reaction-Moonfall-ds3Xb',
    'Tikram-Ainak-gQ2KG',
    'Korinn-Kalazar-1zOZw',
    '2024-Warlock-Celestial-7lcWa',
    '2024-Warlock-Druid-kZFtD',
    '2014-Kenku-Sorc--Cleric--Wiz-nJAZw',
    'Stick-Slapper-IGo80'
  ]
}
