import { ABILITY_NAMES } from '../Common/Constants'
import { ABILITY } from './Types'

export class Utility {
  static readonly isDevelopment: boolean = Utility.loadDevelopmentStatus()

  static saveUrlToLocalStorage(url: string | undefined, key: string) {
    if (url) {
      localStorage.setItem(key, url)
    } else {
      localStorage.removeItem(key)
    }
  }
  static range(start: number, end: number): number[] {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i)
  }
  static modifierForScore(score: number): number {
    return Math.floor((score - 10) / 2)
  }

  static loadDevelopmentStatus(): boolean {
    return process.env.NODE_ENV === 'development'
  }

  static kiPointsString(kiPoints: number): string {
    // return `${kiPoints} ki point${kiPoints > 1 ? 's' : ''}`
    return `${kiPoints} ki`
  }

  static indexOfAbility(ability: ABILITY): number {
    return ABILITY_NAMES.indexOf(ability)
  }

  static shortNameForAbility(ability: ABILITY): string | null {
    const index = Utility.indexOfAbility(ability)
    return this.shortNameForAbilityID(index)
  }

  static nameForAbilityID(id: number | null): string | null {
    if (id === null) {
      return null
    }

    if (id < 0 || id > 6) {
      console.error('Looking up invalid ability ID ' + id)
      return null
    }

    return ABILITY_NAMES[id]
  }

  static shortNameForAbilityID(id: number | null): string | null {
    const name = this.nameForAbilityID(id)
    if (name === null) return null

    return name.substring(0, 3).toUpperCase()
  }

  static hackAcToSaveMod(ac: number) {
    return ac - 10
  }

  static hackAcToSaveModString(ac: number) {
    const save = this.hackAcToSaveMod(ac)
    return `${save >= 0 ? '+' : ''}${save}`
  }

  static toTitleCase(str: string): string {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
}

export class URLUtility {
  static urlFromFieldValue(urlFieldValue: string): URL | null {
    let url = null
    try {
      if (!urlFieldValue.startsWith('http://') && !urlFieldValue.startsWith('https://')) {
        urlFieldValue = 'https://' + urlFieldValue
        console.log('Prepending https:// to URL:', urlFieldValue)
      }

      url = new URL(urlFieldValue)
      const parts: string[] = url.pathname.split('/')
      if (!parts.includes('characters')) {
        console.error('Could not parse URL:', urlFieldValue)
        return null
      }
    } catch (error) {
      console.error('Invalid URL:', urlFieldValue)
      return null
    }

    return url
  }

  static shareURLForParams(cid: number, params: URLSearchParams): string {
    const getTopLevelDomain = () => {
      const hostname = window.location.hostname
      const parts = hostname.split('.')
      const tld = parts[parts.length - 1]
      return tld
    }

    const tld = getTopLevelDomain()

    const baseURL =
      tld === 'localhost'
        ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
        : `${window.location.protocol}//${window.location.hostname}`

    return `${baseURL}/c/${cid}?${params.toString()}`
  }
}

export class DiceUtility {
  static diceFaceList(dice: number): number[] {
    return Utility.range(1, dice)
  }
}
