import { CharacterJSON } from '../DDB/CharacterJSON/CharacterJSON'
import { CharacterParser } from '../DDB/CharacterParser'
import { Character } from '../DDB/Character'

export class CharacterLoader {
  static async load(
    id: string,
    reload: boolean = false,
    loadDescriptions: boolean = false,
    loadKnownSpells: boolean = false
  ): Promise<Character | null> {
    const cache = reload ? 'reload' : 'force-cache'
    try {
      const url = `/api/c?cid=${id}${loadDescriptions ? '&descriptions=true' : ''}${loadKnownSpells ? '&loadKnownSpells=true' : ''}`

      let response = await fetch(url, { cache })
      if (!response.ok) {
        // Let's try one more time, without cache…
        response = await fetch(url, { cache: 'no-cache' })
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
      }
      const data = await response.json()

      const characterJSON = new CharacterJSON(data.data, loadDescriptions)

      if (!characterJSON) {
        throw new Error(`Could not load character JSON for ${id}`)
      }
      const characterParser = new CharacterParser(characterJSON)
      return new Character(characterParser)
    } catch (error) {
      console.error('Error fetching character:', error)
      return null
    }
  }
}
