import { ActionParser } from './ActionParser'
import { AttackAction } from '../AttackAction'
import { Range } from '../CharacterJSON/Range'
import { Dice } from '../Dice'
import { Activation } from '../CharacterJSON/Activation'
import { Action } from '../CharacterJSON/Actions'
import { Weapon } from '../Weapon'

export class ActionParserWeaponFeat {
  static parse(actionParser: ActionParser, featAction: Action, weapons: Weapon[], attackActions: AttackAction[]) {
    const character = actionParser.character

    const name = featAction.name

    if (name === 'Reactive Strike') {
      const polearmActions = attackActions.find((action) => action.attributes.is2024Polearm && action.isAction())
      const weaponAttackActions: AttackAction[] = weapons.map((weapon) => AttackAction.CreateFromWeapon(weapon, character))
      const allPolearmActions = polearmActions ? weaponAttackActions.concat(polearmActions.copy()) : weaponAttackActions

      for (const attackAction of allPolearmActions) {
        const reactiveStrike = attackAction.copy()
        reactiveStrike.attributes.displayAttributes = [...attackAction.attributes.displayAttributes] // deep copy
        reactiveStrike.attributes.displayAttributes.push('Sentinel: Reactive Strike')
        reactiveStrike.activation = Activation.Reaction()
        reactiveStrike.id = attackAction.attributes.id + featAction.id
        reactiveStrike.attributes.id = reactiveStrike.id

        reactiveStrike.activation = Activation.Reaction()
        attackActions.push(reactiveStrike)
      }

      return true
    }

    if (name === 'Hew') {
      for (const weapon of weapons) {
        if (weapon.hasProperty('Heavy')) {
          const hewAction = AttackAction.CreateFromWeapon(weapon, character)
          hewAction.name = `${featAction.name} (${weapon.customizedName ? weapon.customizedName : weapon.name})`
          hewAction.activation = new Activation(featAction.activation)
          hewAction.id = featAction.id + weapon.id
          hewAction.attributes.id = hewAction.id
          attackActions.push(hewAction)
        }
      }
      return true
    }

    if (name === 'Pole Strike' || name === 'Polearm Master - Bonus Attack') {
      const poleStrike = name === 'Pole Strike'
      const pamBonus = name === 'Polearm Master - Bonus Attack'

      const polearmActions = attackActions.find((action) => action.attributes.is2024Polearm && action.isAction())
      const weaponAttackActions: AttackAction[] = weapons.map((weapon) => AttackAction.CreateFromWeapon(weapon, character))

      const allPolearmActions = polearmActions ? weaponAttackActions.concat(polearmActions.copy()) : weaponAttackActions
      for (const attackAction of allPolearmActions) {
        if ((pamBonus && attackAction.attributes.isPolearm) || (poleStrike && attackAction.attributes.is2024Polearm)) {
          const toHit = attackAction.attackMod
          const dice = Dice.Create(1, 4, attackAction.dice.fixedValue)

          const attributes = { ...attackAction.attributes }
          attributes.displayAttributes = [...attackAction.attributes.displayAttributes] // deep copy

          if (attributes.propertyNames && attributes.propertyNames.includes('Thrown')) {
            // The only thrown polearm is a spear, give it 5ft melee range
            attributes.range = Range.makeWeaponRange(5)
          }

          attributes.displayAttributes.push(pamBonus ? 'Polearm Master Bonus' : 'Polearm Master: Pole Strike')

          attributes.id = attributes.id + featAction.id

          const ba = Activation.BonusAction()
          const pamBonusAttack = new AttackAction(attributes.id, attackAction.name, toHit, dice, attributes, ba)
          attackActions.push(pamBonusAttack)
        }
      }

      return true
    }

    return false
  }
}
