import { ActionParser } from './ActionParser'
import { AttackAction } from '../AttackAction'
import { Range } from '../CharacterJSON/Range'
import { Dice } from '../Dice'

import { Action } from '../CharacterJSON/Actions'
import { Activation } from '../CharacterJSON/Activation'

export class ActionParserWarlock {
  static parse(parser: ActionParser, action: Action, attackActions: AttackAction[]) {
    const name = action.name
    const character = parser.character

    if (character.classLevel('Warlock') === 0) return false

    const attackMod = character.attackModForAbility('charisma')
    const spellSaveDC = character.saveDcForAbility('charisma')
    const abilityMod = character.modifierForAbility('charisma')

    if (name === 'Maddening Hex') {
      // TODO - later this can scale with number of enemies around the hexed target
      const charismaMod = character.modifierForAbility('charisma')
      const spellAttackRange = Range.makeSpellAttackRange(30)
      const attributes = {
        range: spellAttackRange,
        attackMod: 0,
        type: 'Spell',
        requiresSavingThrow: false,
        requiresAttackRoll: false
      }
      const dice = Dice.flatAmountDie(charismaMod)
      const attack = new AttackAction(action.id, name, attackMod, dice, attributes, parser.bonusAction)
      attackActions.push(attack)

      return true
    }

    if (name === 'Accursed Specter') {
      const lifeDrain = 'Specter: Life Drain'
      const foundSpecter: AttackAction | undefined = attackActions.find((action) => action.name === lifeDrain)
      if (foundSpecter) {
        foundSpecter.attackMod = 4 + abilityMod
      } else {
        const toHit = 4 + abilityMod
        const dice = Dice.Create(3, 6)
        const attack = new AttackAction(action.id, lifeDrain, toHit, dice, parser.companionAttrs, parser.bonusAction)
        attackActions.push(attack)
        return true
      }
    }

    if (name === 'Tentacle of the Deeps: Attack') {
      const attack = parser.createWeaponAttackAction(character, name, action, action.range.range)
      attackActions.push(attack)
      return true
    }

    if (name === 'Necrotic Husk: Revival') {
      const attack = parser.createAoeSpellAction(name, action, parser.bonusAction, spellSaveDC, false)
      attack.activation = Activation.Reaction()
      attackActions.push(attack)
      return true
    }

    if (name === 'Searing Vengeance' && action.id === 9414642) {
      const charismaMod = character.modifierForAbility('charisma')

      const spellAttackRange = Range.makeSpellAttackRange(0)
      const attributes = {
        range: spellAttackRange,
        attackMod: 0,
        type: 'Spell',
        requiresSavingThrow: false,
        requiresAttackRoll: false
      }

      const dice = Dice.Create(2, 8, charismaMod)
      const attack = new AttackAction(action.id, name, attackMod, dice, attributes, parser.bonusAction)
      attackActions.push(attack)
      return true
    }

    return false
  }
}
