import { Tr, Td, Checkbox, Tbody, Th } from '@chakra-ui/react'
import React from 'react'
import { CheckMap } from '../../Common/Interfaces'
import { Dispatcher } from '../../Common/Types'
import { IconTooltip, WrappingTd } from '../../Common/UIComponents'
import { Feature } from '../../DDB/Feature'
import { TABLE_HEADER_COLOR } from '../CharacterSheet'
import { FeatureBox } from '../Controls/FeatureBox'

export function FeatureTable({
  title,
  features,
  checkedFeatureState,
  isMini = false
}: {
  title: string
  features: Feature[]
  checkedFeatureState: [CheckMap, Dispatcher<CheckMap>]
  isMini?: boolean
}) {
  const [checkedFeatures, setCheckedFeatures] = checkedFeatureState
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedId: number = parseInt(event.target.value)
    const newFeatures: CheckMap = {
      ...checkedFeatures,
      [checkedId]: !checkedFeatures[checkedId]
    }

    // If it's a leveled feature (like smites or upcast spells), uncheck all other features in the same family
    const feature = features.find((f) => f.id === checkedId)
    if (feature && feature.levelInfo) {
      const parentId = feature.levelInfo.id
      const featureFamily = features.filter((f) => f.levelInfo && f.levelInfo.id === parentId && f.id !== checkedId)
      for (const feature of featureFamily) newFeatures[feature.id] = false
    }

    setCheckedFeatures(newFeatures)
  }

  const handleLevelChange = (id: number, level: number) => {
    const prevFeature = features.find((f) => f.id === id)
    if (prevFeature && prevFeature.levelInfo) {
      const prevId = prevFeature.levelInfo!.id
      const newFeature = features.find((f) => f.levelInfo && f.levelInfo.id === prevId && f.levelInfo!.level === level)

      if (newFeature) {
        prevFeature.levelInfo!.visible = false
        newFeature.levelInfo!.visible = true

        const currentValue = checkedFeatures[prevFeature.id]
        const newFeatures: CheckMap = {
          ...checkedFeatures,
          [prevFeature.id]: currentValue && currentValue === true ? false : currentValue, // If it was on, turn it off
          [newFeature.id]: currentValue // copy the old value from the previous one
        }
        setCheckedFeatures(newFeatures)
      } else {
        console.error('Level changed, but no new feature was found?', prevFeature, level)
      }
    } else {
      console.error('Level changed, but no previous feature was found?', prevFeature, level)
    }
  }

  if (features.length === 0) return null

  const concentrationFeatures = features.filter((feature: Feature) => checkedFeatures[feature.id]).filter((feature) => feature.requiresConcentration)
  const concFeature = concentrationFeatures.length > 0 ? concentrationFeatures[0] : undefined

  let featuresToDisplay = { ...features }

  const leveledFeatures = features.filter((feature: Feature) => feature.levelInfo)

  if (leveledFeatures) {
    featuresToDisplay = features.filter((feature: Feature) => !feature.levelInfo)

    // Map the feature groups by their levelInfo id to work with them more easily
    const leveledFeatureMap = leveledFeatures.reduce((map, feature) => {
      const id = feature.levelInfo!.id
      if (!map.has(id)) map.set(id, [])
      map.get(id)!.push(feature)
      return map
    }, new Map<number, Feature[]>())

    // Set up what is actually visible or not in each group
    for (const [id, features] of leveledFeatureMap) {
      // First se if the group has something visible. If we have exactly one, we're good.
      const visibleFeatures = features.filter((feature) => feature.levelInfo?.visible)

      // But if we have none, we need to choose *one* to be visible
      if (visibleFeatures.length === 0) {
        const enabledFeatures = features.filter((feature) => checkedFeatures[feature.id])
        // First, look for one that may be enabled.

        if (enabledFeatures.length === 1) {
          // That gets priority, we don't want checked features to not be visible!
          enabledFeatures[0].levelInfo!.visible = true
        } else if (enabledFeatures.length === 0) {
          // If none are checked, default to the highest level as a sane default
          const highestLevelFeature = features.reduce((a, b) => (a.levelInfo!.level > b.levelInfo!.level ? a : b))
          highestLevelFeature.levelInfo!.visible = true
        } else {
          // Otherwise, something horrible happened.
          console.error('Found multipole enabled features on load', id, enabledFeatures)
        }
      } else if (visibleFeatures.length > 1) {
        // If we found multiple visible features, something is also very wrong.
        console.error('Found multiple visible features on load', id, visibleFeatures)
      }
    }

    // Finally, remove all features that are not visible.
    //  This creates the illusion of the stepper working, but it's actually different controls each time.
    const visibleLeveledFeatures = leveledFeatures.filter((feature) => feature.levelInfo?.visible)
    featuresToDisplay = [...featuresToDisplay, ...visibleLeveledFeatures].sort((a, b) => a.name.localeCompare(b.name))
  }

  const buffRows = featuresToDisplay.map((feature: Feature) => {
    if (feature.isBuff) {
      return (
        <Tr key={feature.id}>
          <Td>
            <form>
              <Checkbox
                colorScheme={'blue'}
                id={feature.id.toString()}
                value={feature.id}
                isChecked={checkedFeatures[feature.id]}
                onChange={handleCheckboxChange}
              >
                <FeatureBox
                  feature={feature}
                  warnConcentration={concFeature && concFeature !== feature && concentrationFeatures.includes(feature)}
                  ps={0}
                  onLevelChange={handleLevelChange}
                />
              </Checkbox>
              {!isMini && <IconTooltip>{feature.tooltip}</IconTooltip>}
            </form>
          </Td>
          {!isMini && <WrappingTd>{feature.generatedNotes()}</WrappingTd>}
        </Tr>
      )
    } else {
      return (
        <Tr key={feature.id}>
          <Td colSpan={2}>
            <FeatureBox feature={feature} ps={0} />
          </Td>
        </Tr>
      )
    }
  })

  return (
    <Tbody>
      {!isMini && (
        <Tr textColor={TABLE_HEADER_COLOR}>
          <Th fontSize={'medium'} colSpan={2} pt={4}>
            {title}
          </Th>
        </Tr>
      )}
      {buffRows}
    </Tbody>
  )
}

export function FeatureTableSection({
  title,
  features,
  checkedFeatureState,
  isMini = false
}: {
  title: string
  features: Feature[]
  checkedFeatureState: [CheckMap, Dispatcher<CheckMap>]
  isMini?: boolean
}) {
  if (features.length === 0) {
    return null
  }
  return <FeatureTable title={title} features={features} checkedFeatureState={checkedFeatureState} isMini={isMini} />
}
