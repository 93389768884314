import React, { useState, useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import { MDXProvider } from '@mdx-js/react'
import { compile } from '@mdx-js/mdx'
import { run } from '@mdx-js/mdx'
import * as runtime from 'react/jsx-runtime'
import { ChakraMDXComponents } from './ChakraMDXComponents'
import remarkGfm from 'remark-gfm'

export default function BlogPost({ page }: { page: string }) {
  const [postContent, setPostContent] = useState<React.ReactNode>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)

    const fetchAndProcessMDX = async (pagePath: string) => {
      try {
        const response = await fetch(`/posts/${pagePath}.mdx`)

        // If 404 and not already fetching the 404 page, fetch the 404 page instead
        if (!response.ok) {
          if (response.status === 404 && pagePath !== '404') {
            console.log(`Page ${pagePath} not found, falling back to 404 page`)
            return fetchAndProcessMDX('404')
          }
          throw new Error(`Failed to fetch page: ${response.status} ${response.statusText}`)
        }

        const text = await response.text()
        const cleanedContent = text.replace(/---[\s\S]*?---/, '').trim()
        const compiledMDX = await compile(cleanedContent, { outputFormat: 'function-body', remarkPlugins: [remarkGfm] })
        const { default: Content } = await run(compiledMDX, runtime)
        setPostContent(<Content components={ChakraMDXComponents} />)
      } catch (error) {
        console.error('Error loading or processing page:', error)
        // If there was an error with the 404 page, show a simple error message
        if (pagePath === '404') {
          setPostContent(<Box p={4}>Error: Could not load content.</Box>)
        } else {
          // If not already fetching 404 page, try to fetch it
          return fetchAndProcessMDX('404')
        }
      } finally {
        setIsLoading(false)
      }
    }

    fetchAndProcessMDX(page.toLowerCase())
  }, [page])

  if (isLoading) {
    return <Box p={4}>Loading...</Box>
  }

  return (
    <MDXProvider components={ChakraMDXComponents}>
      <Box>{postContent}</Box>
    </MDXProvider>
  )
}
