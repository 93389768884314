import { Dice } from '../Dice'

import { FeatureParserBase } from './FeatureParserBase'

export class FeatureParserPaladin {
  static parse(parser: FeatureParserBase) {
    const thisClass = 'Paladin'
    if (!parser.validate(thisClass)) return false
    const { name, only, action, feature, effects, character, requiredLevel, isSubclassFeature } = parser

    if (name === 'Channel Divinity: Vow of Enmity' || name === "Channel Divinity: Nature's Wrath") {
      only.weapon = true
      effects.advantage = true
      return true
    }

    if (name === 'Channel Divinity: Sacred Weapon') {
      const chaMod = character.modifierForAbility('charisma')
      only.weapon = true
      effects.additionalToHitDice = Dice.flatAmountDie(chaMod)
      feature.notes = `Add ${chaMod} (charisma modifier) to attack rolls`
      return true
    }

    if (name === 'Divine Smite (Undead or Fiend)') {
      only.weaponOrUnarmed = true
      feature.modifiedFeature = 'Divine Smite'
      effects.additionalDamageOnHitDice = Dice.Create(1, 8)
      feature.notes = '+1d8 dmg to Divine Smite'
      return true
    }

    if (name === 'Sacred Weapon: Imbue Weapon') {
      const charismaMod = character.modifierForAbility('charisma')
      only.attackRoll = true
      effects.additionalToHitDice = Dice.flatAmountDie(charismaMod)
      return true
    }

    if (name === 'Radiant Strikes') {
      if (requiredLevel) return feature.skip()
      only.meleeOrUnarmed = true
      effects.additionalDamageOnHitDice = new Dice(action.dice)
      feature.defaultEnabled = true
      return true
    }

    if (name === 'Unerring Strike') {
      only.attackRoll = true
      only.oncePerTurn = true
      effects.autoHit = true
      feature.name = 'Living Legend - Unerring Strike'
      feature.notes = 'Next attack roll automatically hits'
      return true
    }

    if (name === 'Vow of Enmity') {
      if (isSubclassFeature) return feature.skip()
      only.weaponOrUnarmed = true
      effects.advantage = true
      return true
    }

    if (name === 'Mortal Bulwark') {
      if (isSubclassFeature) return feature.skip()
      effects.advantage = true
      feature.usesLimitedResource = true
      feature.notes = 'Advantage on attacks against aberrations, celestials, elementals, fey, and fiends'

      return true
    }

    if (name === 'Divine Smite') {
      if (isSubclassFeature) return feature.skip()
      if (!action.upcastLevel) return feature.skip()
      const level = action.upcastLevel

      only.meleeWeapon = true

      effects.additionalDamageOnHitDice = Dice.Create(2 + level - 1, 8)
      effects.additionalDamageToFiendsAndUndeadDice = Dice.Create(1, 8) // TODO - create a sub-option for this
      effects.smiteDice = effects.additionalDamageOnHitDice.copy()
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Improved Divine Smite') {
      if (isSubclassFeature) return feature.skip()
      only.meleeWeapon = true
      effects.additionalDamageOnHitDice = Dice.Create(1, 8)
      feature.notes = '+1d8 dmg to Divine Smite'
      return true
    }

    if (name === 'Invincible Conqueror') {
      if (isSubclassFeature) return feature.skip()
      effects.expandedCrit = true
      effects.extraAttackThisAction = true
      feature.usesLimitedResource = true
      feature.notes = 'Expanded crit range, extra attack this action'
      return true
    }

    if (name === 'Avenging Angel') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true
      effects.advantage = true
      return true
    }

    if (name === 'Nature’s Wrath') {
      if (isSubclassFeature) return feature.skip()
      // For later: STR save or restrained
      only.weaponOrUnarmed = true
      effects.advantage = true
      return true
    }

    const nonBuffActions = [
      '12: Ability Score Improvement',
      '16: Ability Score Improvement',
      '8: Ability Score Improvement',
      'Ability Score Improvement',
      'Abjure Foes',
      'Activate Living Legend',
      'Aura Expansion',
      'Aura of Alacrity',
      'Aura of Courage',
      'Aura of Devotion',
      'Aura of Protection',
      'Aura of the Guardian',
      'Channel Divinity',
      'Channel Divinity: Abjure Foes',
      'Channel Divinity: Divine Sense',
      'Channel Divinity: Emissary of Peace',
      'Channel Divinity: Rebuke the Violent',
      'Core Paladin Traits',
      'Embody Legends',
      'Emissary of Redemption',
      'Epic Boon',
      'Extra Attack',
      'Faithful Steed',
      'Fighting Style',
      'Fighting Style feat',
      'Glorious Defense',
      'Harness Divine Power',
      'Holy Nimbus',
      'Imbue Aura of Protection',
      'Inspiring Smite',
      'Lay On Hands',
      'Lay On Hands: Heal',
      'Lay On Hands: Healing Pool',
      'Lay On Hands: Purify Poison',
      'Lay on Hands',
      'Lay on Hands Pool',
      'Lay on Hands: Restoring Touch',
      'Living Legend',
      'Oath Spells',
      'Oath of Devotion Spells',
      'Oath of Glory Spells',
      'Paladin Subclass',
      'Paladin’s Smite',
      'Peerless Athlete',
      'Protective Spirit',
      'Restoring Touch',
      'Sacred Weapon',
      'Saving Throw Reroll',
      'Smite of Protection',
      'Spellcasting',
      'Weapon Mastery'
    ]

    if (nonBuffActions.includes(name)) return feature.skip()

    return false
  }
}
