import React from 'react'
import { Link, Box, Text, Tooltip, IconButton, Flex, HStack, Input, Button } from '@chakra-ui/react'
import { Tr, Td, Thead, Th } from '@chakra-ui/react'
import { useClipboard, useMediaQuery, useToast } from '@chakra-ui/react'
import { Popover, PopoverTrigger, PopoverContent, PopoverCloseButton } from '@chakra-ui/react'
import { PopoverHeader, PopoverBody, PopoverFooter } from '@chakra-ui/react'
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Badge } from '@chakra-ui/react'
import { Range } from '../DDB/CharacterJSON/Range'
import { DamageData } from '../Common/Interfaces'
import { AddIcon, ExternalLinkIcon, LinkIcon, MinusIcon, RepeatIcon, DownloadIcon } from '@chakra-ui/icons'

import { TurnEngine } from '../Data/TurnEngine'
import { Dictionary, Dispatcher } from './Types'
import { InfoIcon } from '@chakra-ui/icons'
import { useTheme } from '@chakra-ui/react'
import { TABLE_HEADER_COLOR } from '../UI/CharacterSheet'
import { Utility } from './Utility'

export function TableHeader({ pt = 4, children }: { pt?: number; children: React.ReactNode }) {
  return (
    <Thead>
      <Tr textColor={TABLE_HEADER_COLOR}>
        <Th fontSize={'medium'} colSpan={2} pt={pt}>
          {children}
        </Th>
      </Tr>
    </Thead>
  )
}

export function TextTableHeader({ pt = 4, children }: { pt?: number; children: React.ReactNode }) {
  return (
    <Text as="div" textColor={TABLE_HEADER_COLOR} fontSize={'medium'} pt={pt} textTransform={'uppercase'} fontWeight={'bold'}>
      {children}
    </Text>
  )
}

export function WrappingTd({ children }: { children: React.ReactNode }) {
  return (
    <Td whiteSpace="normal" wordBreak="break-word">
      {children}
    </Td>
  )
}

export function StatsRowForNumbers({
  stats,
  px = 1.5,
  py = 0.5,
  bgColor = 'gray.900',
  label = undefined
}: {
  stats: (number | string)[]
  bgColor?: string
  px?: number
  py?: number
  label?: string
}) {
  const boxes = stats.map((score, index) => {
    const shortName = Utility.shortNameForAbilityID(index)
    return (
      <Flex key={`stat-${index}`} alignItems="center" mr={index < stats.length - 1 ? 1.5 : 0}>
        <Box textAlign="center" key={`stat-${index}`} fontSize="sm">
          <Box bgColor={bgColor} borderRadius={'md'} px={px} py={py}>{`${shortName} ${score}`}</Box>
        </Box>
      </Flex>
    )
  })

  if (label) {
    return (
      <Flex>
        <Text fontWeight={'bold'}>{label}:</Text>
        <Flex ms={1} mt="-1px">
          {' '}
          {boxes}
        </Flex>
      </Flex>
    )
  }
  return boxes
}

export function BoldTextSpan({ children, ...props }: { children: React.ReactNode }) {
  return (
    <Text fontWeight="bold" as="span" {...props}>
      {' '}
      {children}
    </Text>
  )
}

export function BoldFlexSpan({ children, ...props }: { ps?: number; pe?: number; children: React.ReactNode }) {
  return (
    <Flex fontWeight="bold" as="span" {...props}>
      {' '}
      {children}
    </Flex>
  )
}

export function IconTooltip({ children }: { children: React.ReactNode }) {
  const theme = useTheme()
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  if (!children) return null

  return isMobile ? (
    <Popover>
      <PopoverTrigger>
        <InfoIcon fontSize="sm" ms={1} color={theme.colors.gray[400]} />
      </PopoverTrigger>
      <PopoverContent maxWidth="90vw" maxHeight="50vh" overflowY="auto" bgColor={theme.colors.gray[800]}>
        <PopoverHeader>
          <PopoverCloseButton />
        </PopoverHeader>

        <PopoverBody padding="1rem" whiteSpace="pre-wrap" textTransform="none">
          <Flex p={2}>{children}</Flex>
        </PopoverBody>
        <PopoverFooter />
      </PopoverContent>
    </Popover>
  ) : (
    <Tooltip label={children} hasArrow>
      <InfoIcon fontSize="sm" ms={1} color={theme.colors.gray[400]} />
    </Tooltip>
  )
}

export function TableActionButton({
  id,
  value,
  add,
  onClick
}: {
  id: string
  value: string
  add: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <IconButton
      bgColor={'brand'}
      color={'white'}
      id={id}
      value={value}
      aria-label="Add attack"
      h={'24px'}
      minH={'24px'}
      w={'24px'}
      minW={'24px'}
      onClick={onClick}
      icon={add ? <AddIcon boxSize="3" /> : <MinusIcon boxSize="3" />}
    />
  )
}

export function ReloadButton({ reloading, onClick }: { reloading: boolean; onClick: React.MouseEventHandler<HTMLButtonElement> }) {
  return (
    <Tooltip label="Reload character">
      <IconButton
        color={'white'}
        top={0}
        right={0}
        id={'reload'}
        aria-label="Reload character"
        onClick={onClick}
        isLoading={reloading}
        icon={<RepeatIcon boxSize={6} />}
      />
    </Tooltip>
  )
}

export function errorToast(toast: ReturnType<typeof useToast>, title: string, description: string) {
  toast({ title, description, status: 'error', duration: 3000, isClosable: true })
}

// TODO can be done without passing in a turn engine lol
export function ShareButton({ turnEngine }: { turnEngine: TurnEngine }) {
  const shareURL = turnEngine.shareableURL()
  const toast = useToast()

  const handleShareButtonClick = async () => {
    try {
      await navigator.clipboard.writeText(shareURL)
      toast({
        title: 'Success',
        description: 'Sharing URL copied to clipboard.',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
        colorScheme: 'gray'
      })
    } catch (err) {
      console.log('Failed to copy: ', err)
      toast({
        title: 'Error',
        description: 'Failed to copy sharing URL',
        status: 'error',
        duration: 3000,
        isClosable: true,
        colorScheme: 'red'
      })
    }
  }

  return (
    <Tooltip label="Share link to DPR analysis">
      <IconButton
        color={'white'}
        top={0}
        right={0}
        id={'reload'}
        aria-label="Reload character"
        onClick={handleShareButtonClick}
        icon={<LinkIcon boxSize={5} ms={1} />}
      />
    </Tooltip>
  )
}

export function LinkButton({ url, isMini = false, label = 'Open character sheet' }: { isMini?: boolean; url: string; label?: string }) {
  return (
    <Tooltip label={label}>
      <Link href={url} isExternal>
        <ExternalLinkIcon boxSize={isMini ? 5 : 6} />
      </Link>
    </Tooltip>
  )
}

export function SpacerRow() {
  return (
    <Tr>
      <Td>
        <Box boxSize={0} />
      </Td>
    </Tr>
  )
}

export function StackedTextBox({ title, subtitle }: { title: string; subtitle?: string }) {
  if (title === 'Self' && subtitle) {
    title = subtitle
    subtitle = ''
  }
  return (
    <Box alignItems="left" padding={0}>
      <Text>{title}</Text>
      {subtitle && <Text fontSize="sm">{subtitle}</Text>}
    </Box>
  )
}

export function BasicTableRow({ name, value }: { name: string; value: string }) {
  if (value === null || value === 'None') return null

  return (
    <Tr>
      <Td style={{ fontWeight: 'bold' }} valign="top">
        {name}
      </Td>
      <WrappingTd>{value}</WrappingTd>
    </Tr>
  )
}

export function RangeText({ range }: { range: Range }) {
  if (!range) {
    return <Text />
  }

  const rangeString = range.rangeString()
  const notesString = range.notesString()

  //   console.log('range', range, rangeString, notesString)
  return <StackedTextBox title={rangeString} subtitle={notesString} />
}

export function DamageCell({ damageData }: { damageData: DamageData }) {
  const diceString = damageData ? damageData.expression : ''
  const { onCopy } = useClipboard(diceString)

  return (
    <Td>
      <Tooltip label={diceString}>
        <Box onClick={onCopy} cursor="pointer">
          {damageData && damageData.average >= 0 ? damageData.average.toFixed(2) : ''}
        </Box>
      </Tooltip>
    </Td>
  )
}

export function CopyDataButton(data: Dictionary) {
  const jsonString = JSON.stringify(data, null, 2) // Convert Dictionary to JSON string

  const { onCopy } = useClipboard(jsonString)

  return (
    <Tooltip label="Download test data">
      <IconButton
        color={'white'}
        top={0}
        right={0}
        id={'download'}
        aria-label="Download character data"
        onClick={onCopy}
        icon={<DownloadIcon boxSize={6} />}
      />
    </Tooltip>
  )
}

export function AccordionView({ title, children, ...props }: { title: string; children: React.ReactNode }) {
  return (
    <Accordion allowToggle pb={2}>
      <AccordionItem bgColor={'bg.surface'}>
        {({ isExpanded }) => (
          <>
            <AccordionButton>
              <AccordionIcon />
              <Text textTransform="uppercase" fontWeight="bold" ps={2} textColor={'gray.400'}>
                {title}
              </Text>
            </AccordionButton>
            <AccordionPanel pb={2}>
              <Box {...props}>{isExpanded && children}</Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}

export function BasicBadge({ children }: { children: React.ReactNode }) {
  return (
    <Badge color="white" bgColor="brand" borderRadius="full" px={3} py={0} maxH="24px" alignItems="center" justifyContent="center">
      {children}
    </Badge>
  )
}
export function ActionLevelBadge({ level }: { level: number }) {
  return (
    <Badge bgColor="brand" color="white" borderRadius="full" px={2} py={0} maxH={'20px'} display="flex" alignItems="center" justifyContent="center">
      L{level}
    </Badge>
  )
}

export function URLEntryBar({ urlFieldData, onButtonClick }: { urlFieldData: [string, Dispatcher<string>]; onButtonClick: () => void }) {
  const [urlFieldValue, setUrlFieldValue] = urlFieldData
  return (
    <HStack direction={{ base: 'column', md: 'row' }} spacing="3" width="100%">
      <Input
        placeholder="Enter your dndbeyond.com character URL"
        size={'lg'}
        value={urlFieldValue}
        onChange={(event) => {
          setUrlFieldValue(event.target.value)
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onButtonClick()
          }
        }}
      />

      <Button size={'md'} colorScheme="blue" onClick={onButtonClick}>
        Analyze
      </Button>
    </HStack>
  )
}
