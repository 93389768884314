import { Button, Container, Heading, HStack, Spacer, Text } from '@chakra-ui/react'
import React, { useEffect, useState, JSX } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { CheckCircleIcon, CloseIcon, SpinnerIcon } from '@chakra-ui/icons'
import { Utility } from '../Common/Utility'
import { TurnEngine } from '../Data/TurnEngine'
import { sumDamageArrays } from '../Data/ChartData'
import { Link } from '@chakra-ui/react'
import { LoadDPRTestSlugs } from '../Test/DPRTestData'
import { CharacterLoader } from '../Loader/CharacterLoader'
import { Character } from '../DDB/Character'

enum DPRStatus {
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  PASS = 'PASS',
  FAIL = 'FAIL'
}

export function TestPage() {
  const [forceReload, setForceReload] = useState(false)

  useEffect(() => {
    if (forceReload) setForceReload(false)
  }, [forceReload])
  const [testResults, setTestResults] = useState<(CharacterTestResult | null)[]>([])

  const timeSinceStart = 0
  useEffect(() => {
    async function loadCharacter(cid: string, reloadCache: boolean): Promise<Character | null> {
      return await CharacterLoader.load(cid, reloadCache)
    }

    const testSlugs = LoadDPRTestSlugs()

    function loadCharacters() {
      // Initialize array with loading placeholders
      setTestResults(testSlugs.map((slug, index) => new CharacterTestResult(slug, undefined, index)))

      // Load each character independently
      testSlugs.forEach(async (slug, index) => {
        try {
          const character = await loadCharacter(slug, forceReload)
          setTestResults((prev) => {
            const newResults = [...prev]
            newResults[index] = new CharacterTestResult(slug, character || undefined, index, !character)
            return newResults
          })
        } catch (error) {
          setTestResults((prev) => {
            const newResults = [...prev]
            newResults[index] = new CharacterTestResult(slug, undefined, index, true)
            return newResults
          })
        }
      })
    }

    loadCharacters()
  }, [forceReload])

  const currentResults: CharacterTestResult[] = testResults.filter((result) => result !== null) as CharacterTestResult[]
  const completedTestResults: CharacterTestResult[] = currentResults.filter((result) => result!.DPRStatus() !== DPRStatus.LOADING)

  const sortedTestResults = currentResults.sort((a, b) => {
    // Pull errors to the top
    if (a.DPRStatus() === DPRStatus.ERROR && b.DPRStatus() !== DPRStatus.ERROR) {
      return -1
    }
    if (a.DPRStatus() !== DPRStatus.ERROR && b.DPRStatus() === DPRStatus.ERROR) {
      return 1
    }

    // For non-errors or when both are errors, maintain the original order
    return a.index - b.index
  })
  const successfulTestResults = completedTestResults.filter((result) => result.DPRStatus() === DPRStatus.PASS)
  const failedTestResults = completedTestResults.filter((result) => result.DPRStatus() === DPRStatus.FAIL || result.DPRStatus() === DPRStatus.ERROR)

  return (
    <Container>
      <HStack width="100%">
        <Heading size="sm">
          {`Testing ${completedTestResults.length}/${currentResults.length} - `}
          <CheckCircleIcon color="green.500" ps={3} />
          {` ${successfulTestResults.length}`}
          <CloseIcon color="red.500" ps={3} />
          {` ${failedTestResults.length} `}

          {` - ${timeSinceStart.toFixed(2)}ms`}
        </Heading>
        <Spacer />
        <Button colorScheme="blue" size="xs" onClick={() => setForceReload(true)}>
          Reload
        </Button>
      </HStack>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Character</Th>
            <Th>Status</Th>
            <Th>Notes</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedTestResults.map((result) => {
            return (
              <Tr key={result.slug!}>
                <Td>
                  {result?.character ? (
                    <Link href={`http://dprcalc.com/c/${result.slug}`} isExternal>
                      {result.character.name()} ({result.id})
                    </Link>
                  ) : (
                    result.name
                  )}
                </Td>
                <Td>{result.DPRStatusText()}</Td>
                <Td whiteSpace="normal">{result?.character ? result.character.testDescriptorForDisplay() : '…'}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Container>
  )
}

class CharacterTestResult {
  id: number
  index: number
  name: string
  slug: string

  character?: Character
  duration?: number
  error?: boolean

  targetDPR?: string
  calculatedDPR?: string

  private turnEngine?: TurnEngine

  constructor(
    slug: string,
    character: Character | undefined,
    index: number,
    error: boolean | undefined = undefined,
    duration: number | undefined = undefined
  ) {
    this.character = character
    this.id = index // Backup in case there is an error
    this.name = slug
    this.error = error
    this.duration = duration
    this.index = index
    this.slug = slug

    // TODO what happens when charater OR share data are missing?
    if (this.character) {
      // console.log(`Creating CharacterTestResult for ${character.id()}`, character)
      this.id = this.character.id()
      this.targetDPR = this.character.shareData()?.dpr || '0'
      this.name = this.character.name()

      const shareData = this.character.shareData()
      if (shareData) {
        const { checkedFeatures, actionIdList, advantageOverrides, targetAC, shareKey } = shareData

        if (shareKey) {
          this.slug = shareKey
        }

        const acs = Utility.range(targetAC, targetAC)

        this.turnEngine = new TurnEngine(this.character, checkedFeatures, actionIdList, advantageOverrides, acs, targetAC, false)

        const allTurns = this.turnEngine.allTurnActionsAndDamageRiders
        const damageTotals = sumDamageArrays(allTurns, [targetAC])
        this.calculatedDPR = damageTotals[0].toFixed(2)
      }
    }
  }

  shareURL(): string {
    return this.turnEngine?.shareableURL() || ''
  }

  DPRStatus(): DPRStatus {
    if (this.error) return DPRStatus.ERROR
    if (!this.calculatedDPR) return DPRStatus.LOADING
    if (this.targetDPR === this.calculatedDPR) return DPRStatus.PASS
    return DPRStatus.FAIL
  }

  DPRStatusText(): JSX.Element {
    switch (this.DPRStatus()) {
      case DPRStatus.ERROR:
        return (
          <HStack>
            <CloseIcon color="red.500" />
            <Text>{`Error`}</Text>
          </HStack>
        )
      case DPRStatus.LOADING:
        return (
          <HStack>
            <SpinnerIcon color="grey.500" />
            <Text>Loading…</Text>
          </HStack>
        )
      case DPRStatus.PASS:
        return (
          <HStack>
            <CheckCircleIcon color="green.500" />
            <Text>{this.targetDPR}</Text>
          </HStack>
        )
      case DPRStatus.FAIL:
        return (
          <HStack>
            <CloseIcon color="red.500" />
            <Text>{`${this.targetDPR} != ${this.calculatedDPR}`}</Text>
          </HStack>
        )
    }
  }
}
