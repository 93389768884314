import { FeatureConstraints } from './FeatureConstraints'
import { Utility } from '../Common/Utility'
import { TurnAction } from './TurnAction'
import { Character } from './Character'
import { Spell } from './Spell'
import { Activation } from './CharacterJSON/Activation'
import { Dice, DiceCollection } from './Dice'
import { Feature } from './Feature'

export class FeatureEffects {
  critDiceDealMaxDamage: boolean = false
  addWeaponStatModifier: boolean = false
  extraAttackThisTurn: boolean = false
  extraAttackThisAction: boolean = false
  extraBonusAttacksThisAction: number = 0
  extraAttackThisActionIfWeaponEquipped: string | undefined = undefined
  damageMultiplier: number = 1
  additionalMeleeRange: number = 0
  isBoomingBlade: boolean = false
  isBoomingBladeTargetMoved: boolean = false
  targetMoved: boolean = false // for BB
  useAbilityModForAttacks?: number
  canUseCharismaAbilityModForAttacks: boolean = false
  rerollOnAdvantage: boolean = false // Elven accuracy
  extraAttackFirstRoundDice?: Dice
  expandedCrit: boolean = false
  actionSurge: boolean = false
  extraTurn: boolean = false // Strength before death
  advantage: boolean = false
  forgoAdvantageNextAttack: boolean = false
  canAlsoUseBowsAndCrossbows: boolean = false
  mundaneWeapon: boolean = false // Improved Pact Weapon
  applyToNextAttack: boolean = false // Maneuver: Feinting Attack (Advantage and extra damage). // WARNING: Only maneuvers and studied attacks tested with applyToNextAttack (and it doesn't even check if the *next* turn is an attack, jutt blindly looks at the next TURN)
  isManeuver: boolean = false // Battle Masters can only use 1 maneuver at a time
  abilityScoreMinimumDamage: boolean = false
  subduePositiveDamageModifier: boolean = false // Cleave
  convertBonusActionToAction: boolean = false // Nick
  isHuntersMark: boolean = false
  applyHuntersMarkDamageTwice: boolean = false
  addsHuntersMarkDamage: boolean = false

  autoCrit: boolean = false // Assassinate on surprise
  autoHit: boolean = false // Stroke of Luck capstone
  doubleDamage: boolean = false // Thief's Reflexes

  // TODO these are hacks, we should generalize these
  nextPsychicBladeAttackHasAdvantage: boolean = false // Psychic Blade (Vex) - and Vex Weapon Mastery
  nextPsychicBladeAttacksHaveAdvantage: boolean = false // Psychic Blade (Rend Mind)

  // TODO - unify all of these 'reset dice
  huntersMarkDice?: Dice = undefined
  smiteDice: Dice | undefined = undefined
  sneakAttackDice?: Dice // Used to retain original dice when it is modified by cunning/devious strikes
  cunningStrikeDice?: Dice // Used to retain original dice when it is modified
  attackAfterSneakAttackAutoCrits: boolean = false // Knock Out
  attackAfterSneakAttackHasAdvantage: boolean = false // Knock Out
  additionalDamageDiceOnCrit: number = 0 // brutal critical
  raging: boolean = false // 2024 frenzy
  damageVulnerability: boolean = false
  maxDamage: boolean = false
  disadvantage: boolean = false // Heightened Spell
  convertActionToBonusAction: boolean = false // Quickened Spell
  convertActionToReaction: boolean = false // TODO
  minimumD20Roll: number = 0 // Trance of Order
  spellSaveDCIncrease: number = 0 // Innate Sorcery, All-Purpose Tool, etc
  additionalEffectCount: number = 0
  potentCantrip: boolean = false
  secondAttack: boolean = false // Tenser's Transformation - an extra attack, but only if there are no other features that give it
  replacementDamageDice?: Dice // Toll the Dead, Ranger capstone
  additionalToHitDice?: Dice // precision attack
  additionalDamageOnHitDice?: Dice // booming blade, hex, favored foe, sneak attack, smite
  additionalDamageOnCritDice?: Dice // boon of irresistable offense
  additionalDamageOnMoveDice?: Dice // booming blade
  additionalDamageToFiendsAndUndeadDice?: Dice // smite // TODO
  additionalDamageDiceOnHit: number = 0 // Undead form of dread
  rerollDamageDiceThreshold: number = 0 // Great Weapon Fighting 2014
  minimumDamageDieRoll: number = 0 // Great Weapon Fighting 2024 (and Elemental Adept)
  oneHandedOverride: boolean = false // Lance while mounted
  rerollAllDamageDiceOnHit: boolean = false // Savage Attacker
  rerollToHit: boolean = false
  doNotPruneSpellSource: boolean = false // Normally spells get deleted when turned into features, this overrides that
  nextAttacksHaveAdvantage: boolean = false // Grappler 2024
  rerollDamageDice: number = 0 // Empowered Spell

  stringForEffects(only: FeatureConstraints) {
    if (this.actionSurge) return 'Additional action'

    if (this.additionalDamageOnHitDice || this.additionalDamageOnCritDice) {
      const freqString = only.oncePerTurn ? (only.opportunityAttack ? 'next opportunity attack' : 'next hit') : 'per hit'
      const weaponString = only.stringForConstraints()
      const advString = this.advantage ? ', with advantage' : ''
      const ragingString = only.raging ? ' while raging' : ''
      const toHitString = this.additionalToHitDice ? `+${this.additionalToHitDice.diceString()} to hit and ` : ''

      const whichDice = this.additionalDamageOnHitDice ? this.additionalDamageOnHitDice : this.additionalDamageOnCritDice
      const dmgString = `+${whichDice!.diceString()} dmg `
      const critString = this.additionalDamageOnCritDice ? ' on crit' : ''
      return `${toHitString}${dmgString}${freqString}${weaponString ? ' with ' + weaponString : ''}${ragingString}${advString}${critString}`
    }

    if (this.additionalToHitDice) {
      const weaponString = only.stringForConstraints()
      const ragingString = only.raging ? ' while raging' : ''
      return `+${this.additionalToHitDice.diceString()} to hit${weaponString ? ' with ' + weaponString : ''}${ragingString}`
    }

    if (this.extraAttackThisTurn) {
      return 'Extra attack this turn'
    }

    if (this.extraAttackThisAction) {
      return 'Extra attack this action'
    }

    if (this.extraBonusAttacksThisAction > 0) {
      if (this.extraBonusAttacksThisAction === 1) {
        return 'Extra bonus action attack this turn'
      }
      return `Extra ${this.extraBonusAttacksThisAction} bonus action attacks this turn`
    }

    if (this.additionalDamageDiceOnCrit > 0) {
      const count = this.additionalDamageDiceOnCrit
      const dieString = count === 1 ? 'die' : 'dice'
      return `+${count} weapon ${dieString} on crit`
    }

    if (this.rerollToHit) {
      return 'Reroll 1s on attack rolls'
    }

    if (this.rerollAllDamageDiceOnHit) {
      if (only.oncePerTurn) {
        return 'Reroll weapon damage dice once per turn'
      }
      return 'Reroll weapon damage dice'
    }

    if (only.damageTypes && only.damageTypes.length > 0 && this.minimumDamageDieRoll > 1) {
      return `Minimum ${only.damageTypes[0]} dmg roll is ${this.minimumDamageDieRoll}`
    }

    if (this.advantage) {
      const weaponType = only.stringForConstraints()

      if (weaponType) {
        if (weaponType === 'attack roll') {
          return only.oncePerTurn ? `Next attack roll is made with advantage` : `Attack rolls this turn are made with advantage`
        }

        let result = only.oncePerTurn
          ? `Next ${weaponType} attack is made with advantage`
          : `${Utility.toTitleCase(weaponType)} attacks are made with advantage`
        result = result.replace('Attack attacks', 'attacks')

        return result
      }
      return only.oncePerTurn ? 'Next attack is made with advantage' : 'Attacks this turn are made with advantage'
    }

    if (this.disadvantage) {
      return only.oncePerTurn ? 'Next attack is made with disadvantage' : 'Attacks this turn are made with disadvantage'
    }

    return ''
  }

  applyEffectsToTurn(
    addFeature: (featureCode: (turn: TurnAction) => void) => void,
    turnActions: TurnAction[],
    elvenAccuracy: boolean,
    character: Character,
    features: Feature[],
    only: FeatureConstraints
  ) {
    const advantageValue = elvenAccuracy ? 2 : 1

    if (this.nextAttacksHaveAdvantage) {
      addFeature((turn) => {
        const currentIndex = turnActions.indexOf(turn)
        for (let i = currentIndex + 1; i < turnActions.length; i++) {
          const turn = turnActions[i]
          if (turn.isAttackRoll()) {
            turn.advantage = elvenAccuracy ? 2 : 1
          }
        }
      })
    }

    if (this.subduePositiveDamageModifier) {
      if (only.specificWeaponMasteryType) {
        // Only have Cleave apply to next weapon attack of same type
        addFeature((turn) => (turn.subduePositiveDamageModifier = turn.isWeaponType(only.specificWeaponMasteryType)))
      } else {
        // If there is no weapon mastery or specific weapon, just apply it
        addFeature((turn) => (turn.subduePositiveDamageModifier = this.subduePositiveDamageModifier))
      }
    }

    if (this.damageVulnerability) addFeature((turn) => (turn.damageVulnerability = this.damageVulnerability))
    if (this.additionalToHitDice) addFeature((turn) => turn.bonusToHitDice.push(this.additionalToHitDice!))
    if (this.critDiceDealMaxDamage) addFeature((turn) => (turn.critDiceDealMaxDamage = this.critDiceDealMaxDamage))
    if (this.rerollDamageDice) addFeature((turn) => (turn.rerollDamageDice = this.rerollDamageDice))

    if (this.useAbilityModForAttacks !== undefined || this.canUseCharismaAbilityModForAttacks) {
      addFeature((turn) => {
        // Modifier override takes precedence
        if (turn.modifierOverride) return

        // If this is already using the ability mod for attacks, don't double count (like Shillelagh)
        if (this.useAbilityModForAttacks && this.useAbilityModForAttacks === turn.attackAction?.attributes.spellcastingModifier) return

        const chaMod = character.modifierForAbility('charisma')
        const newModifier = this.useAbilityModForAttacks ? this.useAbilityModForAttacks : chaMod
        turn.modifierOverride = newModifier

        const abilityIndex = turn.attackStatIndex()
        const score = character.abilityScoreForIndex(abilityIndex) // is this off by one?
        const statModifier = Utility.modifierForScore(score)

        if (abilityIndex !== undefined) {
          // If STR or DEX is actually higher than CHA… don't use CHA
          if (this.canUseCharismaAbilityModForAttacks && statModifier > chaMod) return
          turn.bonusDamageDiceCollection.modifier -= statModifier
          turn.bonusDamageDiceCollection.modifier += newModifier

          turn.bonusToHitDice.push(Dice.flatAmountDie(-statModifier))
          turn.bonusToHitDice.push(Dice.flatAmountDie(newModifier))
        } else {
          console.error('No attack stat index found for weapon')
        }
      })
    }

    if (this.applyHuntersMarkDamageTwice) {
      const huntersMark = features.find((feature) => feature.name === `Hunter's Mark`)
      if (huntersMark && huntersMark.effects.additionalDamageOnHitDice) {
        const diceCollection = new DiceCollection().addDice(huntersMark.effects.additionalDamageOnHitDice)
        addFeature((turn) => turn.bonusDamageDiceCollection.addDiceCollection(diceCollection))
      }
    }

    if (this.additionalDamageOnCritDice) addFeature((turn) => (turn.additionalDamageOnCritDice = this.additionalDamageOnCritDice))

    if (this.additionalDamageOnHitDice) {
      const diceCollection = new DiceCollection().addDice(this.additionalDamageOnHitDice)

      if (this.targetMoved && this.additionalDamageOnMoveDice) {
        diceCollection.addDice(this.additionalDamageOnMoveDice)
      }

      if (only.oneBeam) {
        addFeature((turn) => turn.singleEffectBonusDamageDiceCollection.addDiceCollection(diceCollection))
      } else {
        addFeature((turn) => turn.bonusDamageDiceCollection.addDiceCollection(diceCollection))
      }
    }

    if (this.extraAttackFirstRoundDice) {
      const validTurnActions = [...turnActions.filter((turn) => turn.attackAction !== undefined && turn.attackAction.activation.usesAction())]

      // TODO if it's this.extraAttackThisAction (dread ambusher, etc AND we have action surge), apply it twice
      if (validTurnActions.length > 0) {
        const lastTurnAttack = validTurnActions[validTurnActions.length - 1]
        lastTurnAttack.bonusDamageDiceCollection.addDice(this.extraAttackFirstRoundDice)
      }
    }

    if (this.forgoAdvantageNextAttack) {
      const firstTurnAttack = turnActions[0]
      firstTurnAttack.forgoAdvantage = true // for future features that may try to turn on advantage

      if (firstTurnAttack.advantage > 0) {
        firstTurnAttack.advantage = 0
      }
    }

    if (this.additionalDamageDiceOnCrit > 0) addFeature((turn) => (turn.additionalCritDiceCount += this.additionalDamageDiceOnCrit))

    if (this.addWeaponStatModifier) {
      addFeature((turn) => {
        const abilityIndex = turn.attackStatIndex()
        const score = character.abilityScores()[abilityIndex]
        const statModifier = Utility.modifierForScore(score)
        if (abilityIndex !== undefined) {
          turn.bonusDamageDiceCollection.modifier += statModifier
        } else {
          console.error('No attack stat index found for weapon')
        }
      })
    }

    if (this.additionalDamageDiceOnHit > 0) addFeature((turn) => (turn.additionalDamageDice += this.additionalDamageDiceOnHit))
    if (this.rerollDamageDiceThreshold > 0) addFeature((turn) => (turn.rerollDamageDiceThreshold = this.rerollDamageDiceThreshold))
    if (this.minimumDamageDieRoll > 0) addFeature((turn) => (turn.minimumDieRoll = this.minimumDamageDieRoll))
    if (this.rerollToHit) addFeature((turn) => (turn.rerollToHit = this.rerollToHit))
    if (this.rerollAllDamageDiceOnHit) addFeature((turn) => (turn.rerollAllDamageDiceOnHit = this.rerollAllDamageDiceOnHit))
    if (this.maxDamage) addFeature((turn) => (turn.maxDamage = this.maxDamage))
    if (this.disadvantage) addFeature((turn) => (turn.disadvantage = this.disadvantage))
    if (this.damageMultiplier > 1) addFeature((turn) => (turn.damageMultiplier = this.damageMultiplier))
    if (this.convertBonusActionToAction) addFeature((turn) => (turn.activation = Activation.Action()))
    if (this.convertActionToBonusAction) addFeature((turn) => (turn.activation = Activation.BonusAction()))
    if (this.minimumD20Roll) addFeature((turn) => (turn.minimumD20Roll = this.minimumD20Roll))
    if (this.additionalEffectCount > 0) addFeature((turn) => (turn.attributes().effectCount += this.additionalEffectCount))
    if (this.advantage) {
      addFeature((turn) => {
        if (turn instanceof Spell) return
        if (!turn.forgoAdvantage && !turn.damageRider) {
          turn.advantage = turn.isCompanion() ? 1 : advantageValue
        }
      })
    }

    if (this.expandedCrit) addFeature((turn) => (turn.critThreshold -= 1))
    if (this.replacementDamageDice) addFeature((turn) => (turn.replacementDamageDice = this.replacementDamageDice!.copy()))
    if (this.autoCrit) addFeature((turn) => (turn.autoCrit = this.autoCrit))
    if (this.autoHit) addFeature((turn) => (turn.autoHit = this.autoHit))

    if (this.abilityScoreMinimumDamage) {
      addFeature((turn) => {
        const abilityIndex = turn.attackStatIndex()
        const score = character.abilityScoreForIndex(abilityIndex)
        const statModifier = Utility.modifierForScore(score)
        // TODO - later see if this works with hexblade curse and how to make it work with true strike
        // Maybe we chance true strike to happen first? Or just check for true strike in here?
        // Override attack stat index on the turn (temporarily) so when it calculates it, it uses it?

        turn.missDamage = statModifier
        const foundFeature = features.find((feature) => feature.effects.useAbilityModForAttacks !== undefined)
        if (foundFeature) turn.missDamage = foundFeature.effects.useAbilityModForAttacks!
      })
    }

    if (only.specificWeaponMasteryType) addFeature((turn) => (turn.specificweaponMasteryType = only.specificWeaponMasteryType))

    if (this.potentCantrip) addFeature((turn) => (turn.saveForHalfDamage = turn.missForHalfDamage = this.potentCantrip))
    if (this.spellSaveDCIncrease > 0) addFeature((turn) => (turn.spellSaveDCIncrease += this.spellSaveDCIncrease))
  }

  affectsBaseDice() {
    // NOTE: add these to TurnEngine.createTurnsFromDamageRiders
    return this.critDiceDealMaxDamage || this.autoCrit || this.damageMultiplier !== 1
  }
}

// TODO maybe move generateNotes to here?
