import { dice } from './ClockworkDice'
import { DamageData } from '../Common/Interfaces'
import * as ClockworkMod from './ClockworkDice.js'
import { DiceEvaluationCache } from '../Common/Cache'
import { Utility } from '../Common/Utility'

export type DiceMap = Record<number, number>
const diceCache = new DiceEvaluationCache()

export class DiceEvaluation {
  expression: string
  rawDice?: dice

  valuesCached?: DiceMap
  hitValuesCached?: DiceMap
  critValuesCached?: DiceMap
  averageCached?: number
  totalCached?: number
  percentCached?: DiceMap

  constructor(expression: string, dice?: dice) {
    this.expression = expression
    this.rawDice = dice
  }

  static evaluate(expression: string): DiceEvaluation {
    if (diceCache.has(expression)) {
      return diceCache.get(expression) || new DiceEvaluation(expression)
    }

    try {
      const value: dice = ClockworkMod.evalDice(expression)

      const evaluation = new DiceEvaluation(expression, value)
      diceCache.set(expression, evaluation)
      return evaluation
    } catch (error) {
      if (error instanceof TypeError) {
        if (Utility.isDevelopment) {
          console.error(`Cannot evaluate dice string [${expression}) due to TypeError: ${error.message}`)
        }
        return new DiceEvaluation(expression)
      }

      throw error
    }
  }

  static Create(expression: string, average: number, total: number, percent: DiceMap): DiceEvaluation {
    const evaluation = new DiceEvaluation(expression)
    evaluation.averageCached = average
    evaluation.totalCached = total
    evaluation.percentCached = percent
    evaluation.totalCached = total

    // TODO we should also be taking these in…
    evaluation.valuesCached = {}
    evaluation.critValuesCached = {}

    return evaluation
  }

  average(): number {
    if (!this.averageCached) this.averageCached = this.rawDice?.average() || 0
    return this.averageCached
  }

  percent(): DiceMap {
    if (!this.percentCached) this.percentCached = (this.rawDice?.percent() as unknown as DiceMap) || {}
    return this.percentCached
  }

  values(): DiceMap {
    // We need to use rawDice as the actual values are at the root level,
    // not rawDice.valueS() which just grabs an array of the values, not a mapping of face->value
    if (!this.valuesCached) this.valuesCached = this.rawDice as unknown as DiceMap
    return this.valuesCached
  }

  hitValues(): DiceMap {
    if (this.hitValuesCached) return this.hitValuesCached

    const hitValues: DiceMap = {}
    const values = this.values()
    const critValues = this.critValues()

    if (!critValues) return values
    for (const face of Object.keys(values)) {
      const f: number = Number(face)
      const crit = critValues[f] || 0
      const all = values[f]
      hitValues[f] = all - crit
    }

    this.hitValuesCached = hitValues
    return hitValues
  }

  normalize(pHit: number, pCrit: number, damageRider: boolean): DiceEvaluation {
    if (pHit < 0) console.error('pHit is less than 0')
    if (pHit > 1) console.error('pHit is greater than 1')
    if (pCrit < 0) console.error('pCrit is less than 0')
    if (pCrit > 1) console.error('pCrit is greater than 1')

    const critValues: DiceMap = this.critValues() || {}
    const hitValues: DiceMap = this.hitValues()

    // Damage riders can't crit if they don't have any chance of actually hitting
    if (pHit === 0 && damageRider) pCrit = 0
    const newPercentiles = this.renormalize(hitValues, pHit, critValues, pCrit) // TODO: I think this is actually wrong, but it should only affect the graphs a tiny amount. Revisit later.
    newPercentiles[0] = 1 - pHit
    const newAverage = this.calculateAverage(hitValues) * (pHit - pCrit) + this.calculateAverage(critValues) * pCrit

    return DiceEvaluation.Create(this.expression, newAverage, this.calculateTotal(hitValues), newPercentiles)
  }

  renormalize(hit: DiceMap, pHit: number, crit: DiceMap, pCrit: number) {
    const ret: DiceMap = []

    const total = this.total()
    for (const key of Object.keys(hit)) {
      const face = parseInt(key)
      if (face < 0) console.error('face is less than 0', face)
      const value = hit[face] * (pHit - pCrit) + (crit[face] || 0) * pCrit
      if (value >= 0) ret[face] = value / total
    }

    return ret
  }

  private calculateAverage(dice: DiceMap) {
    if (this.calculateTotal(dice) === 0) return 0
    const total = this.calculateTotal(dice)
    return Object.keys(dice).reduce((sum, key) => sum + Number(key) * dice[Number(key)], 0) / total
  }

  private calculateTotal(dice: DiceMap) {
    return Object.values(dice).reduce((sum, v) => sum + v, 0)
  }

  critValues(): DiceMap | undefined {
    if (!this.critValuesCached) this.critValuesCached = this.rawDice?.critValues()
    return this.critValuesCached
  }

  total(): number {
    this.totalCached = (this.totalCached || this.rawDice?.total()) ?? 0

    // Here temporarily because the compiler is warning about number | undefined
    if (this.totalCached === undefined) {
      console.error(`Total is NaN for expression: ${this.rawDice}`)
      this.totalCached = 0
    }
    return this.totalCached
  }

  // Hack for now until we can get rid of DamageData
  damageData(): DamageData {
    return {
      average: this.average(),
      total: this.total(),
      expression: this.expression,
      percentiles: this.percent()
    }
  }

  calculateHalfDamageEvaluation(originalPercentiles: DiceMap | undefined, missDamage: number = 0): DiceEvaluation {
    // NOTE: this is broken for autohit (which is ok because this is for miss damage), but it just returns 0
    const percentilesCopy = { ...this.percent() }
    const missChance = this.percent()[0]

    const nonMissOdds = { ...originalPercentiles }
    const originalPercentilesMissChance = nonMissOdds[0]
    nonMissOdds[0] = 0
    let nonMissTotal = 0

    if (missDamage === 0) {
      // TODO - we shouldn't need to do this repeatedly, we can pull it out to where we first cache originalPercentages and cache it
      for (const [damage, odds] of Object.entries(nonMissOdds)) {
        if (Number(damage) === 0) {
          continue
        }

        const fullDamageOdds = Number(odds)
        nonMissOdds[Number(damage)] = fullDamageOdds / (1 - originalPercentilesMissChance)
        nonMissTotal = nonMissTotal + nonMissOdds[Number(damage)]
      }

      percentilesCopy[0] = 0 // didn't miss, so no damage
      const hitChance = 1 - missChance
      let blendTotal = 0

      for (const [damage, odds] of Object.entries(percentilesCopy)) {
        const damageIndex = Number(damage)

        if (damageIndex === 0) {
          continue
        }

        const halfDamageOdds = nonMissOdds[damageIndex] * 0.5
        const fullDamageOdds = Number(odds)
        percentilesCopy[damageIndex] = fullDamageOdds * hitChance + halfDamageOdds * missChance
        blendTotal = blendTotal + percentilesCopy[damageIndex]
      }
    } else {
      // Set the miss chance to 0, then add the miss damage odds to the existing odds
      percentilesCopy[0] = 0
      percentilesCopy[missDamage] = missChance + (percentilesCopy[missDamage] ? percentilesCopy[missDamage] : 0)
    }

    let nonMissAverage = 0
    for (const [damage, odds] of Object.entries(nonMissOdds)) {
      nonMissAverage = nonMissAverage + Number(damage) * Number(odds)
    }

    const average = (1 - missChance) * nonMissAverage + missChance * (missDamage > 0 ? missDamage : 0.5 * nonMissAverage)
    const total = this.calculateTotal(percentilesCopy)
    return DiceEvaluation.Create(this.expression, average, total, percentilesCopy)
  }
}
