import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { Feature } from '../../DDB/Feature'
import { DiamondIcon } from '../CustomIcons'
import { ActionLevelBadge } from '../../Common/UIComponents'
import { NumberStepper } from './NumberStepper'

export function FeatureBox({
  feature,
  ps,
  warnConcentration = false,
  onLevelChange = undefined
}: {
  feature: Feature
  ps: number
  onLevelChange?: (id: number, level: number) => void
  warnConcentration?: boolean
}) {
  const name = feature.name
  const conc = feature.requiresConcentration

  const upcastLevel = feature.levelInfo?.level
  const fontSize = 'sm'
  const info = feature.levelInfo

  const _onLevelChange = (value: number | ((prevLevel: number) => number)) => {
    const level = typeof value === 'function' ? value(info?.level ?? 0) : value
    if (onLevelChange) onLevelChange(feature.id, level)
  }

  return (
    <Flex align="center">
      <Text fontSize={fontSize} pe={conc || upcastLevel ? 1 : 0} ps={ps} variant={feature.rarityString}>
        {name}
      </Text>
      {upcastLevel && !info && (
        <Box pe={1}>
          <ActionLevelBadge level={upcastLevel} />
        </Box>
      )}

      {info && (
        <Box pe={1}>
          <NumberStepper
            label="L"
            range={[info.minLevel, info.maxLevel]}
            showControls={info.minLevel !== info.maxLevel}
            numberData={[info.level, _onLevelChange]}
          />
        </Box>
      )}

      {conc && <DiamondIcon color={warnConcentration ? '#FAA' : '#AAA'} letter="C" />}
    </Flex>
  )
}
