import { Json, SpellcastingId } from '../../Common/Types'
import { SpellRules } from './Spell'

export class DDBClass {
  classFeatures: ClassFeature[]
  definition: ClassDefinition
  definitionId: number
  entityTypeId: number
  id: number
  isStartingClass: boolean
  level: number
  subclassDefinition?: ClassDefinition
  subclassDefinitionId: number

  constructor(json: Json) {
    this.classFeatures = json.classFeatures.map((f: Json) => new ClassFeature(f))
    this.definition = new ClassDefinition(json.definition)

    this.definitionId = json.definitionId // Later can be used to pull up the DDB page as a link…
    this.entityTypeId = json.entityTypeId
    this.id = json.id
    this.isStartingClass = json.isStartingClass
    this.level = json.level
    this.subclassDefinition = json.subclassDefinition ? new ClassDefinition(json.subclassDefinition) : undefined
    this.subclassDefinitionId = json.subclassDefinitionId // Maybe can also be used to deep link to the DDB page?
  }

  spellcastingAbilityId() {
    if (this.definition.spellCastingAbilityId) {
      return this.definition.spellCastingAbilityId
    }

    if (this.subclassDefinition && this.subclassDefinition.spellCastingAbilityId) {
      return this.subclassDefinition.spellCastingAbilityId
    }

    return undefined
  }
}

export class ClassFeature {
  id: number
  name: string
  requiredLevel: number

  constructor(json: Json) {
    this.id = json.id
    this.name = json.name
    this.requiredLevel = json.requiredLevel
  }
}

export class ClassDefinition {
  classFeatures: ClassFeature[]
  id: number
  isHomebrew: boolean
  moreDetailsUrl: string
  name: string
  spellCastingAbilityId: SpellcastingId
  spellRules?: SpellRules

  constructor(json: Json) {
    this.classFeatures = json.classFeatures.map((f: Json) => new ClassFeature(f))
    this.id = json.id
    this.isHomebrew = json.isHomebrew
    this.moreDetailsUrl = json.moreDetailsUrl
    this.name = json.name
    this.spellCastingAbilityId = json.spellCastingAbilityId
    this.spellRules = json.spellRules ? new SpellRules(json.spellRules) : undefined
  }
}
