import { Dictionary } from '../Common/Types'
import { Spell as SpellJson } from '../DDB/CharacterJSON/Spell'

export interface ShareDataInterface {
  cid: number
  name: string
  race: string
  classNames: string
  checkedFeatures: CheckMap
  actionIdList: ActionLevelMap[]
  advantageOverrides: NumberMap
  targetAC: number
  elvenAccuracy: boolean
  avatarUrl: string
  shareKey?: string
  dpr: string
  slug?: string
}

export interface ActionLevelMap {
  actionId: number
  level: number
}

export interface DamageData {
  average: number
  total: number
  expression: string
  percentiles: { [key: number]: number }
}

export class NumberMap {
  [key: number]: number

  length(): number {
    return Object.keys(this).length
  }

  static Create(map: NumberMap): NumberMap {
    const newMap = new NumberMap()
    for (const key in map) newMap[key] = map[key]
    return newMap
  }

  copy(): NumberMap {
    const newMap = new NumberMap()
    for (const key in this) {
      newMap[key] = this[key]
    }
    return newMap
  }
}

export interface CheckMap {
  [key: number]: boolean
}

export interface DictMap {
  [key: number]: Dictionary
}

export interface SpellSource {
  spells: SpellJson[]
  forceKnown: boolean
}
