import React from 'react'
import { DprTab, MainNavBar } from './MainNavBar'
import { Box } from '@chakra-ui/react'
import BlogPost from './BlogPost'
import { useParams } from 'react-router-dom'
import { track } from '@vercel/analytics/react'
import { PAGE_POST, TrackPageView } from '../Common/AppAnalytics'

export function PostPage() {
  const params = useParams()
  const pid = params.pid
  const page: string = pid ?? '404'

  TrackPageView(PAGE_POST)

  track('post', { page: page })

  return (
    <MainNavBar tab={DprTab.POSTS}>
      <Box px={8} py={2}>
        <BlogPost page={page} />
      </Box>
    </MainNavBar>
  )
}
