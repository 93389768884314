import { URLUtility } from './Utility'
import { NavigateFunction } from 'react-router'

export function extractCharacterID(urlFieldValue: string) {
  const url = URLUtility.urlFromFieldValue(urlFieldValue)
  if (url) {
    const parts = url.pathname.split('/')
    const characterIndex = parts.indexOf('characters')
    const characterId = parts[characterIndex + 1]
    const cid = parseInt(characterId)
    return cid
  }
  return undefined
}

export function handleCharacterButtonClick(navigate: NavigateFunction, urlFieldValue: string) {
  const cid = extractCharacterID(urlFieldValue)

  if (cid) {
    navigate(`/c/${cid}`)
  }
}
