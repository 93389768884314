import React, { Dispatch } from 'react'

import { AddIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Checkbox, Flex, FormControl, FormLabel, Grid, Input, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { InputChangeEvent } from '../Common/Types'
import { TableActionButton } from '../Common/UIComponents'
import { TABLE_HEADER_COLOR } from './CharacterSheet'
import { NumberStepper } from './Controls/NumberStepper'
import { DprTab, MainNavBar } from './MainNavBar'
import { AttackAction } from '../DDB/AttackAction'
import { AttackState } from '../Common/AttackState'

function Thc({ py, children, fontSize = 'xs' }: { fontSize?: string; py?: number; children: React.ReactNode }) {
  return (
    <Th textAlign="center" py={py} fontSize={fontSize}>
      {children}
    </Th>
  )
}

function AttackRow({
  attack,
  index,
  larger = false,
  fontSize = 'sm',
  onUpdate,
  onDelete,
  py,
  px
}: {
  attack: AttackState
  index: number
  larger?: boolean
  fontSize?: string
  onUpdate: (index: number, attack: AttackState) => void
  onDelete?: (index: number) => void
  py: number
  px: number
}) {
  const updateField = <K extends keyof AttackState>(field: K, value: AttackState[K]) => {
    onUpdate(index, { ...attack, [field]: value })
  }

  return (
    <>
      <Tr>
        <Td py={py} px={px}>
          <Box display="flex" alignItems="center">
            <Box
              as="button"
              mr={2}
              transform={`rotate(${attack.isOpen ? '90deg' : '0deg'})`}
              transition="transform 0.2s"
              onClick={() => {
                updateField('isOpen', !attack.isOpen)
              }}
            >
              <ChevronRightIcon boxSize={5} />
            </Box>
            <NumberStepper
              numberData={[attack.count, (value) => updateField('count', value as number)]}
              range={[1, 20]}
              showControls={true}
              larger={larger}
            />
          </Box>
        </Td>

        <Td py={py} px={px}>
          <NumberStepper
            label="+"
            numberData={[attack.toHit, (value) => updateField('toHit', value as number)]}
            range={[0, 30]}
            showControls={true}
            minW={larger ? '1.8rem' : '1.5rem'}
            larger={larger}
          />
        </Td>
        <Td py={py} px={px}>
          <NumberStepper
            numberData={[attack.ac, (value) => updateField('ac', value as number)]}
            range={[5, 25]}
            showControls={true}
            minW={larger ? '1.25rem' : '1rem'}
            larger={larger}
          />
        </Td>
        <Td py={py} px={px}>
          <ValueInput
            placeholder="2d8+1d6+5"
            fontSize={fontSize}
            valueData={[attack.damage, (value) => updateField('damage', value)]}
            minW={onDelete ? '7.5rem' : '10rem'}
            maxH={larger ? '1.5rem' : '1.25rem'}
          />
        </Td>
        {onDelete && (
          <Td py={0} px={px} textAlign="center">
            <TableActionButton id={`delete-${index}`} value={`delete-${index}`} add={false} onClick={() => onDelete(index)} />
          </Td>
        )}
      </Tr>
      {attack.isOpen && (
        <Tr>
          <Td colSpan={onDelete ? 5 : 4}>
            <Box px={px} py={py} borderRadius="md">
              <Grid templateColumns={['1fr', '1fr', '1fr 1fr']} gap={2}>
                <Box>
                  <Stack spacing={1}>
                    <FormLabel fontSize={fontSize} mb={1} fontWeight={'bold'}>
                      Reroll:
                    </FormLabel>
                    <Checkbox
                      size={larger ? 'md' : 'sm'}
                      colorScheme="blue"
                      isChecked={attack.rerollDamageDice}
                      onChange={(e) => updateField('rerollDamageDice', e.target.checked)}
                    >
                      <Flex align="center">
                        <Text>Damage rolls ≤</Text>
                        <NumberStepper
                          numberData={[attack.rerollDamageAmount, (value) => updateField('rerollDamageAmount', value as number)]}
                          range={[1, 3]}
                          ps={1}
                          showControls={true}
                          minW="0.5rem"
                          isDisabled={attack.rerollDamageDice === false}
                          larger={false}
                        />
                      </Flex>
                    </Checkbox>

                    <Checkbox
                      size={larger ? 'md' : 'sm'}
                      colorScheme="blue"
                      isChecked={attack.rerollOne}
                      onChange={(e) => updateField('rerollOne', e.target.checked)}
                    >
                      Attack roll of 1
                    </Checkbox>

                    {/* Miss Options */}
                    <FormControl>
                      <FormLabel fontSize={fontSize} mb={1} fontWeight={'bold'} mt={2}>
                        On Miss:
                      </FormLabel>
                      <RadioGroup value={attack.missOption} onChange={(value) => updateField('missOption', value as AttackState['missOption'])}>
                        <Stack direction="column">
                          <Radio value="normal" size="sm">
                            No damage
                          </Radio>
                          <Radio value="half" size="sm">
                            Half damage
                          </Radio>
                          <Radio value="minimum" size="sm">
                            <Flex align="center">
                              Min damage:
                              <NumberStepper
                                numberData={[attack.minimumDamageRoll, (value) => updateField('minimumDamageRoll', value as number)]}
                                range={[1, 3]}
                                ps={1}
                                showControls={true}
                                minW="0.5rem"
                                isDisabled={attack.missOption !== 'minimum'}
                                larger={false}
                              />
                            </Flex>
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                </Box>

                <Box>
                  {/* Critical Hit Settings */}
                  <FormControl mb={2}>
                    <FormLabel fontSize={fontSize} fontWeight={'bold'} mb={1}>
                      Hits:
                    </FormLabel>
                    <Stack direction="column" mb={2}>
                      <Checkbox
                        size={larger ? 'md' : 'sm'}
                        colorScheme="blue"
                        isChecked={attack.isAutoHit}
                        onChange={(e) => updateField('isAutoHit', e.target.checked)}
                      >
                        Automatic hit
                      </Checkbox>
                    </Stack>

                    <FormLabel fontSize={fontSize} fontWeight={'bold'} mb={1} mt={4}>
                      Crits:
                    </FormLabel>
                    <Stack direction="column">
                      <Flex align="center">
                        <Text fontSize={'sm'} mr={1} ps={6}>
                          Crits on
                        </Text>
                        <NumberStepper
                          numberData={[attack.critThreshold, (value) => updateField('critThreshold', value as number)]}
                          range={[15, 20]}
                          showControls={true}
                          minW="1rem"
                        />
                      </Flex>
                      <Flex align="center">
                        <Text fontSize={'sm'} ps={6} pe={1}>
                          Bonus damage:
                        </Text>
                        <ValueInput
                          placeholder="10"
                          fontSize={'sm'}
                          valueData={[attack.critDamage, (value) => updateField('critDamage', value)]}
                          minW="4rem"
                          maxH="1.25rem"
                        />
                      </Flex>
                    </Stack>
                  </FormControl>

                  <Stack direction="column">
                    <Checkbox
                      size={larger ? 'md' : 'sm'}
                      colorScheme="blue"
                      isChecked={attack.isAutoCrit}
                      onChange={(e) => updateField('isAutoCrit', e.target.checked)}
                    >
                      Guaranteed crit
                    </Checkbox>

                    <Checkbox
                      size={larger ? 'md' : 'sm'}
                      colorScheme="blue"
                      isChecked={attack.isPerkinsCrit}
                      onChange={(e) => updateField('isPerkinsCrit', e.target.checked)}
                    >
                      Perkins crit
                    </Checkbox>
                  </Stack>
                </Box>
              </Grid>
            </Box>
          </Td>
        </Tr>
      )}
    </>
  )
}

export function RawCalculatorPage() {
  const [attacks, setAttacks] = React.useState<AttackState[]>([
    {
      count: 1,
      toHit: 5,
      ac: 10,
      damage: '1d6+3',
      isOpen: true,
      missOption: 'normal',
      critThreshold: 20,
      critDamage: '',
      isAutoCrit: false,
      isPerkinsCrit: false,
      rerollDamageDice: false,
      rerollDamageAmount: 1,
      minimumDamageRoll: 2,
      rerollOne: false,
      isAutoHit: false
    }
  ])

  const handleAddAttack = () => {
    setAttacks([...attacks, { ...attacks[attacks.length - 1] }])
  }

  const handleUpdateAttack = (index: number, updatedAttack: AttackState) => {
    const updatedAttacks = [...attacks]
    updatedAttacks[index] = updatedAttack
    setAttacks(updatedAttacks)
  }

  const handleDeleteAttack = (index: number) => {
    const updatedAttacks = attacks.filter((_, i) => i !== index)
    setAttacks(updatedAttacks)
  }

  const larger = true

  const py = larger ? 2 : 1.5
  const px = larger ? 2 : 2

  const hasDelete = attacks.length > 1
  const headerSize = larger ? 'sm' : 'xs'

  for (const attack of attacks) {
    const index = attacks.indexOf(attack)
    const attackAction = AttackAction.CreateFromRawAttackState(attack, index)
    console.log('made attack action', attackAction)
  }

  // Turn engine… createTurnsFromActions

  return (
    <MainNavBar tab={DprTab.CALCULATOR}>
      <Box p={8}>
        <Table size={larger ? 'md' : 'sm'} width={'0'} variant={'unstyled'} borderBottomRadius="xl">
          <Thead>
            <Tr textColor={TABLE_HEADER_COLOR}>
              <Th py={py} fontSize={headerSize} padding={0}>
                <Text ps={7} textAlign={'center'}>
                  Count
                </Text>
              </Th>
              <Thc py={py} fontSize={headerSize}>
                To Hit
              </Thc>
              <Thc py={py} fontSize={headerSize}>
                AC
              </Thc>
              <Th py={py} fontSize={headerSize} padding={0}>
                <Text ps={3}>Damage</Text>
              </Th>
              {attacks.length > 1 && <Th py={py}></Th>}
            </Tr>
          </Thead>
          <Tbody>
            {attacks.map((attack, index) => (
              <AttackRow
                key={index}
                attack={attack}
                index={index}
                fontSize={larger ? 'md' : 'sm'}
                onUpdate={handleUpdateAttack}
                onDelete={hasDelete ? handleDeleteAttack : undefined}
                py={py}
                px={px}
                larger={larger}
              />
            ))}
            <Tr>
              <Td py={0} px={0} colSpan={hasDelete ? 5 : 4}>
                <Box
                  textAlign="center"
                  alignContent="center"
                  minH={larger ? '2rem' : '1.25rem'}
                  cursor="pointer"
                  _hover={{ bg: 'gray.800' }}
                  onClick={handleAddAttack}
                  borderBottomRadius="xl"
                  pb={larger ? 1 : 0}
                >
                  <AddIcon boxSize={3} />
                </Box>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </MainNavBar>
  )
}

function ValueInput({
  placeholder,
  minW = '10rem',
  fontSize = 'sm',
  maxH = '1.25rem',
  valueData
}: {
  minW?: string
  maxH?: string
  fontSize?: string
  placeholder: string
  valueData: [value: string, setValue: Dispatch<string>]
}) {
  const [value, setValue] = valueData
  const handleInputChange = (e: InputChangeEvent) => setValue(e.target.value)

  return <Input maxH={maxH} fontSize={fontSize} minW={minW} placeholder={placeholder} value={value} onChange={handleInputChange} />
}

// function FixedNumberInput({ defaultValue }: { defaultValue: number }) {
//   return (
//     <NumberInput min={0} max={99} defaultValue={defaultValue} size="md" width="2rem" precision={0} py={0}>
//       <NumberInputField px={1.5} py={0} textAlign={'right'} />
//     </NumberInput>
//   )
// }

// function DieCountInput({ text, defaultValue }: { text: string; defaultValue: number }) {
//   return (
//     <HStack>
//       <FixedNumberInput defaultValue={defaultValue} />
//       <Text fontSize="md">{text}</Text>
//     </HStack>
//   )
// }

// function CalculatingInput({
//   placeholder,
//   state
// }: {
//   placeholder: string
//   state: [Dictionary | undefined, Dispatcher<Dictionary | undefined>]
// }) {
//   const [, setEvalaution] = state
//   const [, setParseError] = useState<unknown | undefined>(undefined)

//   const handleInputChange = (e: InputChangeEvent) => {
//     const value = e.target.value

//     try {
//       if (value) {
//         const diceEval = DiceEvaluation.evaluate(value)
//         setParseError(undefined)
//         setEvalaution(diceEval)
//       } else {
//         setEvalaution(undefined)
//       }
//     } catch (error) {
//       setParseError(error)
//       setEvalaution(undefined)
//     }
//   }

//   return <InputField placeholder={placeholder} onChange={handleInputChange} />
// }

// function CalculatingDamageInputRows({
//   title,
//   placeholder,
//   state
// }: {
//   title: string
//   placeholder: string
//   state: [string, Dispatcher<string>]
// }) {
//   const [, setInputValue] = state
//   const [average, setAverage] = useState<number | undefined>(0)
//   const [, setParseError] = useState<unknown | undefined>(undefined)

//   const handleInputChange = (e: InputChangeEvent) => {
//     const value = e.target.value
//     setInputValue(value)

//     try {
//       if (value) {
//         const diceEval = DiceEvaluation.evaluate(value)
//         setParseError(undefined)
//         setAverage(diceEval.average())
//       } else {
//         setAverage(undefined)
//       }
//     } catch (error) {
//       setParseError(error)
//       setAverage(undefined)
//     }
//   }

//   return (
//     <Tr>
//       <Td>
//         <Text fontSize="md">{title}</Text>
//       </Td>
//       <Td>
//         <InputField placeholder={placeholder} onChange={handleInputChange} />
//       </Td>
//       <Td>
//         <Text fontSize="md" width={'3rem'}>
//           {average?.toFixed(Number.isInteger(average) ? 0 : 1)}
//         </Text>
//       </Td>
//     </Tr>
//   )
// }
//
//
// Inputs
// Attack Bonus (11)
// Target AC (15)
// Damage
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12 + X
// Crits on X
// Number of Attacks 1
// Advanced Options
//  Elven Accuracy
//  Lucky (Halfling)
//  Great Weapon Fighter Style
//  Elemental Adept
//  GWM Crit Bonus
//  Half damage on miss
//  Min damage on miss

// TODO: How do we do all the masteries?

// Bonus on first hit (Sneak Attack, Divine Strike, etc)
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12 + X

// Bonus Dice to HIt (Bless, Inspiration, etc)
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12 +

// Penalty Dice to Hit (Bane, Synaptic Static, etc)
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12

// Bonus on Crit (Brutal Criticals, Half Orc, etc)

//Bonus Action Attacks
// Attack Bonus  | Number of Attacks
// Damage
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12 + X
// Bonus on Crit
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12 + X
// Bonus Dice to Hit (Bless)
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12
// Penalty Dice to Hit (Bane)
//  0d4 + 0d6 + 0d8 + 1d10 + 1d12
// Advantage

// Outputs (Normal / Adv / DIS)
// Chance to Hit %
// Adverage DPR
// Damage Per Hit
// Damage Per Crit
// Critical Hit Chance
// Chance of at least one hit
// Chance of at least one crit
// -5/+10 DPR
// -5/+10 Hit Difference
// -5/+10 Crit Chance to HIt
// -5/+10 Chance of at least one hit
