import { useState, useEffect } from 'react'

// Create a custom event emitter for same-window synchronization
const storageEventTarget = new EventTarget()

export const useLocalStorage = <T>(storageKey: string, initialValue: T[] = []) => {
  const [items, setItems] = useState<T[]>(() => {
    if (typeof window === 'undefined') return initialValue

    try {
      const stored = localStorage.getItem(storageKey)
      return stored ? (JSON.parse(stored) as T[]) : initialValue
    } catch {
      return initialValue
    }
  })

  // Listen for changes from other instances or browser tabs
  useEffect(() => {
    // Function to update state when storage changes
    const handleStorageChange = (event: StorageEvent | CustomEvent) => {
      if ('key' in event && event.key !== storageKey) return

      try {
        const newValue = localStorage.getItem(storageKey)
        // Only update state if the value actually changed
        if (newValue && JSON.stringify(items) !== newValue) {
          setItems(JSON.parse(newValue))
        }
      } catch (error) {
        console.error('Failed to sync with localStorage:', error)
      }
    }

    // Custom event for same-window sync
    const handleCustomEvent = (e: Event) => {
      const customEvent = e as CustomEvent
      if (customEvent.detail.key === storageKey) {
        handleStorageChange(customEvent)
      }
    }

    // Listen for changes from other tabs/windows
    window.addEventListener('storage', handleStorageChange)
    // Listen for changes from other components in the same window
    storageEventTarget.addEventListener('localStorageChange', handleCustomEvent)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
      storageEventTarget.removeEventListener('localStorageChange', handleCustomEvent)
    }
  }, [storageKey, items])

  // Modified to notify other instances when we change localStorage
  const setItemsAndNotify = (newItems: T[]) => {
    setItems(newItems)

    try {
      localStorage.setItem(storageKey, JSON.stringify(newItems))

      // Dispatch custom event to notify other instances in the same window
      const event = new CustomEvent('localStorageChange', {
        detail: { key: storageKey, newValue: JSON.stringify(newItems) }
      })
      storageEventTarget.dispatchEvent(event)
    } catch (error) {
      console.error('Failed to save items to localStorage:', error)
    }
  }

  const addItem = (item: T) => {
    const newItems = ((prev: T[]) => {
      const itemStr = JSON.stringify(item)
      const existingIndex = prev.findIndex((existingItem) => JSON.stringify(existingItem) === itemStr)

      const filteredItems = existingIndex >= 0 ? prev.filter((_, i) => i !== existingIndex) : prev

      return [item, ...filteredItems].slice(0, 10)
    })(items)

    setItemsAndNotify(newItems)
  }

  const removeItem = (itemToRemove: T) => {
    const itemStr = JSON.stringify(itemToRemove)
    const newItems = items.filter((item) => JSON.stringify(item) !== itemStr)
    setItemsAndNotify(newItems)
  }

  const clear = () => setItemsAndNotify([])

  const updateItem = (index: number, newItem: T) => {
    const newItems = items.map((item, i) => (i === index ? newItem : item))
    setItemsAndNotify(newItems)
  }

  return {
    items,
    addItem,
    removeItem,
    updateItem,
    clear,
    setItems: setItemsAndNotify
  }
}
