import React from 'react'
import { DamageData, NumberMap } from '../Common/Interfaces'
import { spanDamageDataGaps } from '../Data/ChartData'
import { LineChart, BarChart } from './ChartJSCharts'
import { Dispatcher } from '../Common/Types'
import { Utility } from '../Common/Utility'
import { Card, CardBody, Box, Text } from '@chakra-ui/react'

export function DamagePercentilesChart({ damageData, chartAC, requiresSave }: { damageData: DamageData[]; chartAC: number; requiresSave: boolean }) {
  // todo - may be redundant to span damage data gaps by this point
  const disadvantageData = spanDamageDataGaps(damageData[0])
  const normalData = spanDamageDataGaps(damageData[1])
  const advantageData = spanDamageDataGaps(damageData[2])
  const asSpecifiedData = spanDamageDataGaps(damageData[3])

  const xAxisValues = Object.keys(normalData?.percentiles ?? {})
  const newXAxis = xAxisValues.slice(1)

  const dis = Object.values(disadvantageData.percentiles).slice(1)
  const norm = Object.values(normalData.percentiles).slice(1)
  const adv = Object.values(advantageData.percentiles).slice(1)
  const asSpec = Object.values(asSpecifiedData.percentiles).slice(1)

  const data = {
    labels: newXAxis,
    datasets: [
      {
        label: 'As Specified',
        data: asSpec,
        fill: false,
        tension: 0,
        borderColor: 'rgba(144, 202, 249, 0.75)',
        backgroundColor: 'rgba(144, 202, 249, 1)' // blue.200
      },
      {
        label: `Disadvantage`,
        data: dis,
        fill: false,
        tension: 0,
        borderColor: 'rgba(239, 154, 154, 0.75)',
        backgroundColor: 'rgba(239, 154, 154, 1)' // red.200
      },
      {
        label: `Flat`,
        data: norm,
        fill: false,
        tension: 0,
        borderColor: 'rgba(189, 189, 189, 0.75)',
        backgroundColor: 'rgba(189, 189, 189, 1)' // gray.400
      },
      {
        label: `Advantage`,
        data: adv,
        fill: false,
        tension: 0,
        borderColor: 'rgba(165, 214, 167, 0.75)',
        backgroundColor: 'rgba(165, 214, 167, 1)' // green.200
      }
    ]
  }
  let title = `Damage distribution odds vs AC ${chartAC}`
  if (requiresSave) {
    chartAC -= 10
    title = `Damage odds vs ${chartAC >= 0 ? `+` : ``}${chartAC} Save`
  }

  return (
    <Box height="100%">
      <Text textTransform="uppercase" fontWeight="bold" ps={2} textColor={'gray.400'} fontSize="sm" textAlign={'center'}>
        {title}
      </Text>
      <Box height="90%">
        <BarChart data={data} chartAC={chartAC} />
      </Box>
    </Box>
  )
}

export function DamageACChart({
  numberMaps,
  acState,
  requiresSave,
  isMini = false
}: {
  numberMaps: NumberMap[]
  acState: [number, Dispatcher<number>]
  requiresSave: boolean
  isMini?: boolean
}) {
  const disadvantageMap = numberMaps[0]
  const normalMap = numberMaps[1]
  const advantageMap = numberMaps[2]
  const asSpecifiedMap = numberMaps[3]

  let xAxisValues = Object.keys(normalMap)
  if (requiresSave) {
    xAxisValues = xAxisValues.map((value) => Utility.hackAcToSaveModString(Number(value)))
  }
  const data = {
    labels: xAxisValues,
    datasets: [
      {
        label: isMini ? 'Normal' : 'As Specified',
        data: Object.values(asSpecifiedMap),
        fill: false,
        tension: 0,
        borderColor: 'rgba(144, 202, 249, 0.75)',
        backgroundColor: 'rgba(144, 202, 249, 1)' // blue.200
      },
      {
        label: isMini ? 'Disadv.' : 'Disadvantage',
        data: Object.values(disadvantageMap),
        fill: false,
        tension: 0,
        borderColor: 'rgba(239, 154, 154, 0.75)',
        backgroundColor: 'rgba(239, 154, 154, 1)' // red.200
      },
      {
        label: 'Flat',
        data: Object.values(normalMap),
        fill: false,
        tension: 0,
        borderColor: 'rgba(189, 189, 189, 0.75)',
        backgroundColor: 'rgba(189, 189, 189, 1)' // gray.400
      },
      {
        label: isMini ? 'Adv.' : `Advantage`,
        data: Object.values(advantageMap),
        fill: false,
        tension: 0,
        borderColor: 'rgba(165, 214, 167, 0.75)',
        backgroundColor: 'rgba(165, 214, 167, 1)' // green.200
      }
    ]
  }

  const title = isMini ? undefined : `Average Damage per Round vs ${requiresSave ? 'Saving Throw Modifier' : 'AC'}`

  if (isMini) {
    return (
      <Box height="150" width="100%">
        <Box height="100%" width="100%">
          <LineChart data={data} acState={acState} requiresSave={requiresSave} />
        </Box>
      </Box>
    )
  }
  return (
    <Card height={isMini ? '250' : '100%'} direction={{ base: 'row', sm: 'row' }} overflow="hidden" variant="unstyled" borderRadius={'xl'}>
      <CardBody ps={2} pe={2} py={2} height="100%" width="100%">
        <Box height="90%" width="100%">
          <Text textTransform="uppercase" fontWeight="bold" ps={2} textColor={'gray.400'} fontSize="sm" textAlign={'center'}>
            {title}
          </Text>
          <LineChart data={data} acState={acState} requiresSave={requiresSave} />
        </Box>
      </CardBody>
    </Card>
  )
}
