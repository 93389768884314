import { Activation } from './CharacterJSON/Activation'
import { Dice, DiceCollection } from './Dice'
import { Utility } from '../Common/Utility'
import { AttackAction } from '../DDB/AttackAction'
import { Dictionary, SpellLevel } from '../Common/Types'
import { CustomizationValueMap, CustomizationValues } from './CustomizationValues'
import { Character } from './Character'
import { Range } from './CharacterJSON/Range'
import { Duration, Spell as SpellJson } from './CharacterJSON/Spell'

// TODO: This still takes character as a parameter, it would be nice to decouple that out somehow or use some interface

export class Spell {
  id: number
  name: string // the DDB name before it's overriden
  displayName: string
  level: SpellLevel
  attackType?: number // 1: Melee/touch, 2: Ranged
  activation?: Activation
  duration?: Duration
  damageTypes: string[] | undefined = undefined
  countsAsKnownSpell: boolean = false
  requiresSavingThrow: boolean = false
  requiresAttackRoll: boolean = false
  addPrimaryStat: boolean = false
  damageMod: number = 0
  attackMod: number = 0
  concentration: boolean

  saveDcAbilityId: number | null = null
  saveDc: number = 0

  spellcastingAbilityIndex?: number
  spellcastingModifier?: number

  range?: Range
  school: string | null = null
  aoeType: string | null = null
  aoeValue: number | null = null
  isPrepared: boolean
  isPreparedOrForceKnown: boolean = false
  definitionId: number | null = null
  ddbURL: string | null = null
  description?: string
  componentsDescription?: string
  components?: number[]
  isEffect: boolean = false
  ritual: boolean = false

  // Scaling stuff
  scaleType: string | null = null
  effectCount: number = 0
  multipleAttacks: boolean = false
  dice: Dice | null = null
  healingDice: Dice | null = null
  higherLevelDice: Dice | null = null
  additionalDamageDice: Dice | null = null
  higherLevelIncrement: number = 0 // How many spell slots levels are needed before higherLevel are added
  effectCountLabel: string | null = null // Beams, Darts, Creatures, etc

  sanitizedDescriptionCache: string | undefined = undefined
  spellSheetInfoCache: Dictionary[] | undefined = undefined

  // Hack… this is just used when parsing, and it set to a higher level each time
  upcastLevel: number = 0

  constructor(character: Character, spellData: SpellJson, customizations: CustomizationValueMap) {
    this.id = spellData.id

    const definition = spellData.definition
    if (!definition) {
      this.name = ''
      this.displayName = ''
      this.level = 0
      this.requiresSavingThrow = false
      this.concentration = false
      this.isPrepared = false

      //console.error('No definition for spell ', spellData)
      return
    }

    const { name, level, school, concentration, requiresSavingThrow, range, modifiers, scaleType, ritual } = definition

    this.definitionId = definition.id
    this.ritual = ritual
    this.name = name
    this.displayName = name
    this.level = level as SpellLevel
    this.school = school
    this.concentration = concentration
    this.requiresSavingThrow = requiresSavingThrow
    this.description = definition.description
    this.componentsDescription = definition.componentsDescription
    this.components = definition.components
    this.range = new Range(range)

    this.scaleType = scaleType
    const additionalDamageDieBlob = modifiers.find((m: Dictionary) => m.type === 'damage' && m.subType === 'additional')
    if (additionalDamageDieBlob !== undefined) {
      // Example Chaos Bolt is 2d8+1d6 at base level
      // TODO - this only applies if there is higher level damage dice. Are there spells that don't meet that?
      this.additionalDamageDice = new Dice(additionalDamageDieBlob.die!)
    }

    this.spellcastingAbilityIndex = spellData.spellCastingAbilityId ? spellData.spellCastingAbilityId - 1 : undefined
    if (this.spellcastingAbilityIndex !== undefined) {
      this.spellcastingModifier = character.modifierForAbilityIndex(this.spellcastingAbilityIndex)
    }

    const pb = character.proficiencyBonus()
    if (definition.saveDcAbilityId !== undefined) {
      this.saveDcAbilityId = definition.saveDcAbilityId - 1
      if (this.spellcastingModifier !== undefined) {
        this.saveDc = 8 + pb + this.spellcastingModifier
      }
    }

    this.countsAsKnownSpell = spellData.countsAsKnownSpell
    if (this.spellcastingModifier !== undefined) {
      this.attackMod = pb + this.spellcastingModifier
    }
    this.isPrepared = true // spellData.prepared || spellData.alwaysPrepared

    const { attackType, requiresAttackRoll, activation, duration } = definition

    this.attackType = attackType
    this.requiresAttackRoll = requiresAttackRoll
    this.activation = new Activation(activation)
    this.duration = duration ? duration : undefined

    for (const modifier of modifiers) {
      const isDamage = this.isDamageModifier(modifier)
      const isHealing = this.isHealingModifier(modifier)

      if (!isDamage && !isHealing) continue
      const { die, usePrimaryStat, subType } = modifier

      if (subType) {
        if (!this.damageTypes) this.damageTypes = []
        if (subType !== 'additional') this.damageTypes.push(subType)
      }

      //   if (this.name === 'Goodberry') console.log('Goodberry', modifier, die)
      if (die) {
        if (isDamage) this.dice = this.calculateDice(die)
        if (isHealing) this.healingDice = this.calculateDice(die)
      }

      const characterLevel = character.totalLevel()
      this.calculateInitialSpellDetails(modifiers, characterLevel) // Do this before higher level dice
      this.calculateHigherLeveLDice(definition.atHigherLevels, modifiers, characterLevel)

      this.addPrimaryStat = usePrimaryStat
    }

    if (this.name === 'Magic Stone') this.activation = Activation.Action()

    if (this.definitionId) {
      const spellSuffix = this.name.replace(/•/g, '').replace(/ {2}/g, ' ').replace(/ /g, '-').replace(/'/g, '').toLowerCase()
      this.ddbURL = `https://www.dndbeyond.com/spells/${this.definitionId}-${spellSuffix}`
    }

    // This is nearly the same code as in Weapon.ts
    const customizationValues: CustomizationValues = customizations[this.id]
    if (customizationValues && customizationValues.isCustomized()) {
      if (customizationValues.bonusDamage) {
        if (!this.dice) {
          this.dice = Dice.flatAmountDie(customizationValues.bonusDamage)
        } else {
          this.dice.fixedValue += customizationValues.bonusDamage
        }
      }

      const { toHitBonus, toHitOverride, dcBonus, dcOverride } = customizationValues
      if (toHitBonus) this.attackMod += toHitBonus
      if (toHitOverride && toHitOverride !== 0) this.attackMod = toHitOverride
      if (dcBonus) this.saveDc += dcBonus
      if (dcOverride && dcOverride !== 0) this.saveDc = dcOverride
    }
  }

  private scalesWithSpellLevel() {
    return this.scaleType && this.scaleType === 'spellscale'
  }

  private scalesWithCharacterLevel() {
    return this.scaleType && this.scaleType === 'characterlevel'
  }

  attackAction(): AttackAction {
    return new AttackAction(this.id, this.displayName, this.attackMod, this.damageDice(), this.spellAttributes(), this.activation!)
  }

  isHealingModifier(modifier: Dictionary) {
    return modifier.type === 'bonus' && modifier.subType === 'hit-points'
  }

  isDamageModifier(modifier: Dictionary) {
    return modifier.type === 'damage'
  }

  // lol at what point is this the entire class
  spellAttributes(): Dictionary {
    let activationTypeString = 'Unknown'
    if (this.activation) {
      activationTypeString = this.activation.activationTypeString()
    }

    const effectCountsForLevels = []
    const diceCollectionsForLevels: DiceCollection[] = []

    if (this.higherLevelIncrement > 0) {
      for (let level = 1; level <= 9; level++) {
        const count = this.effectCount + this.higherLevelIncrement * Math.max(0, level - this.level)
        effectCountsForLevels.push(count)
      }
    }

    if (this.higherLevelDice) {
      // TODO later for spells that do both… this won't work
      const dice = this.healingDice ? this.calculatedHealingDice() : this.damageDice()

      if (!dice) {
        console.error('No dice for upcast spell ' + this.name)
      }

      for (let level = 1; level <= 9; level++) {
        const N = Math.max(level - this.level, 0)
        const value = this.higherLevelDice.copy()
        const upscaledDice = new Array(N).fill(value)
        const diceCollection = new DiceCollection().addDiceList([dice, upscaledDice].flat())
        if (this.additionalDamageDice !== null) {
          diceCollection.addDice(this.additionalDamageDice)
        }

        diceCollectionsForLevels.push(diceCollection)
      }
    }
    const isCantrip = this.level === 0

    return {
      id: this.id,
      definitionId: this.definitionId,
      ddbURL: this.ddbURL,
      name: this.name,
      displayName: this.displayName,
      type: 'Spell',
      level: this.level,
      isCantrip: isCantrip,
      damageTypes: this.damageTypes,
      requiresAttackRoll: this.requiresAttackRoll,
      requiresConcentration: this.concentration,
      requiresSavingThrow: this.requiresSavingThrow,
      effectCount: this.effectCount,
      effectCountsForLevels: effectCountsForLevels,
      effectCountLabel: this.effectCountLabel,
      diceCollectionsForLevels: diceCollectionsForLevels,
      attackMod: this.attackMod,
      range: this.range,
      saveDcAbility: Utility.shortNameForAbilityID(this.saveDcAbilityId),
      saveDcValue: this.saveDc,
      spellcastingModifier: this.spellcastingModifier,
      activation: activationTypeString,
      school: this.school,
      dealsDamage: true,
      multipleAttacks: this.multipleAttacks,
      displayAttributes: []
    }
  }

  private calculateDice(die: Dice | null): Dice | null {
    if (!die) return null
    const newDice: Dice = new Dice(die)
    if (this.dice === null) return newDice
    if (this.dice.isGreaterThan(newDice)) return this.dice
    return newDice
  }

  hasUpcastDamageDice() {
    return this.isDamageSpell() && this.higherLevelDice !== null
  }

  isDamageSpell() {
    return this.dice && (this.dice.diceCount > 0 || this.dice.fixedValue > 0)
  }

  hasUpcastHealingDice() {
    return this.isHealingSpell() && this.higherLevelDice !== null
  }

  isHealingSpell() {
    return this.healingDice && (this.healingDice.diceCount > 0 || this.healingDice.fixedValue > 0)
  }

  private calculateInitialSpellDetails(modifiers: Dictionary, characterLevel: number) {
    const name = this.name
    if (name === 'Eldritch Blast') {
      this.effectCount = 1 + Math.floor((characterLevel + 1) / 6)
      this.effectCountLabel = 'Beams'
    } else if (name === 'Magic Missile') {
      this.multipleAttacks = true
      this.effectCount = 3
      this.effectCountLabel = 'Darts'
    } else if (name === "Tasha's Mind Whip") {
      this.effectCountLabel = 'Creatures'
      this.effectCount = 1
    } else if (name === 'Hex' || name === "Hunter's Mark") {
      this.effectCountLabel = 'Concentration'
      this.effectCount = 1
    } else if (name === 'Scorching Ray') {
      this.effectCountLabel = 'Rays'
      this.effectCount = 3
      this.multipleAttacks = true
    } else if (name === 'Chain Lightning') {
      this.effectCountLabel = 'Targets'
      this.effectCount = 3
    } else if (name === 'Flame Strike') {
      // The spell doesn't have beams, but it does have optional damage types.
      this.effectCount = 1
      this.effectCountLabel = 'Strike'
    } else if (name === 'Flame Arrows') {
      // this just creates more ammo
      this.effectCountLabel = 'Ammunition'
      this.effectCount = 1
    } else if (name === 'Magic Weapon') {
      this.effectCount = 1
      this.effectCountLabel = 'Weapon'
    } else if (name === "Jim's Magic Missile") {
      this.effectCount = 3
      this.effectCountLabel = 'Darts'
      this.multipleAttacks = true
    } else if (name === 'Sorcerous Burst') {
      this.multipleAttacks = true
      // Setting up the path towards bouncing
      // spellcasting ability modifier max… but it's just an extra d8 per bounce, not copies of the attack
    }
  }

  private calculateHigherLeveLDice(atHigherLevels: Dictionary, modifiers: Dictionary[], characterLevel: number) {
    if (atHigherLevels.higherLevelDefinitions.length > 0) {
      this.higherLevelIncrement = atHigherLevels.higherLevelDefinitions[0].value

      //   if (!this.effectCountLabel) {
      //     console.warn(`New spell with new beam name '${this.name}', details = ${details}, typeId = ${typeId}`)
      //   }
    }

    for (const modifier of modifiers) {
      const atHigherLevels = modifier.atHigherLevels
      if (atHigherLevels) {
        const isDamage = this.isDamageModifier(modifier)
        const isHealing = this.isHealingModifier(modifier)

        if (this.scalesWithCharacterLevel()) {
          // Cantrips & such
          const definitions: Dictionary[] = atHigherLevels.higherLevelDefinitions
          for (const spellDefinition of definitions) {
            const spellLevel = spellDefinition.level
            if (spellLevel <= characterLevel) {
              if (isDamage) this.dice = this.calculateDice(spellDefinition.dice)
              if (isHealing) this.healingDice = this.calculateDice(spellDefinition.dice)
            }
          }
        } else if (this.scalesWithSpellLevel()) {
          const definitions: Dictionary[] = atHigherLevels.higherLevelDefinitions
          if (definitions.length === 0) {
            // Do nothing, it's probably handled in the base atHigherLevels
          } else if (definitions.length === 1) {
            const definition = definitions[0]
            const dice = definition.dice
            const value = definition.value
            if (dice) {
              this.higherLevelDice = new Dice(dice)
              //this.higherLevelIncrement = definition.level // TODO - Spiritual Weapon has this at 2
            } else if (value) {
              this.higherLevelDice = Dice.flatAmountDie(value)
            } else {
              console.warn('No dice for higher level spell ' + this.name)
              console.log(definition)
            }
          } else {
            if (this.name !== 'Arms of Hadar') {
              console.error('Multiple higher level definitions for spell?' + this.name)
              console.error(definitions)
            }
          }
        }
      }
    }
  }

  private damageDice(): Dice {
    if (!this.isDamageSpell()) return Dice.flatAmountDie(0)
    if (!this.dice) {
      if (this.name !== 'Ray of Enfeeblement') {
        console.error('Trying to get damage dice for spell ' + this.name + ' but there are none.')
      }
      return Dice.flatAmountDie(0)
    }

    const damageDice: Dice = this.dice.copy()

    if (this.addPrimaryStat) {
      // TODO… why is this assigning? Is that safe? Investigate.
      this.damageMod = this.spellDamageBonusForCharacter()
      damageDice.fixedValue += this.damageMod
    }

    return damageDice
  }

  private calculatedHealingDice(): Dice {
    if (!this.isHealingSpell()) return Dice.flatAmountDie(0)
    if (!this.healingDice) {
      return Dice.flatAmountDie(0)
    }

    const healingDice: Dice = this.healingDice.copy()

    if (this.addPrimaryStat) {
      // TODO… why is this assigning? Is that safe? Investigate.
      this.damageMod = this.spellDamageBonusForCharacter()
      healingDice.fixedValue += this.damageMod
    }

    return healingDice
  }

  spellDamageBonusForCharacter(): number {
    if (this.addPrimaryStat) {
      return this.spellcastingModifier || 0
    }
    return 0
  }

  levelString() {
    if (this.level === 0) return this.school + ' Cantrip'
    return 'Level ' + this.level + ' ' + this.school
  }

  concentrationTimeString(): string | undefined {
    return this.duration?.concentrationTimeString()
  }

  durationString() {
    return this.duration?.durationTypeString()
  }

  rangeString() {
    return this.range?.rangeString()
  }

  activationString() {
    if (this.activation?.activationTypeString() === 'None') {
      console.log('Activation for ' + this.name + ' is None')
    }
    return this.activation?.activationTypeString()
  }

  castingNotesString(): string | undefined {
    if (this.concentration && this.ritual) return 'Concentration, Ritual'
    if (this.concentration) return 'Concentration'
    if (this.ritual) return 'Ritual'
    return undefined
  }

  basicComponentsString() {
    const components = this.components
    if (!components || components.length === 0) return 'None'

    const strComponents = []
    if (components.includes(1)) strComponents.push('V')
    if (components.includes(2)) strComponents.push('S')
    if (components.includes(3)) strComponents.push('M')

    return strComponents.join(', ')
  }

  componentsString() {
    const basicComponentsString = this.basicComponentsString()
    if (!this.componentsDescription) return basicComponentsString
    return basicComponentsString + ' (' + this.componentsDescription + ')'
  }

  getDivByClass(html: string, targetClass: string) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html') // Parse the HTML string into a document
    const targetDiv = doc.querySelector(`.${targetClass}`) // Find the first matching div

    return targetDiv ? targetDiv.outerHTML : null // Return full div block if found
  }

  convertAbilityScoresToTable(htmlString: string) {
    // Regex to match the entire stat block
    const statBlockDiv = this.getDivByClass(htmlString, 'stat-block-ability-scores')
    if (!statBlockDiv) return htmlString

    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const serializedHtml = doc.body.innerHTML

    if (!serializedHtml.includes(statBlockDiv)) {
      console.error('div not found in serialized html')
      return htmlString
    }

    // Parse the matched content

    const statBlock = doc.querySelector('.stat-block-ability-scores')

    if (!statBlock) {
      console.error('No stat block found in the match')
      return htmlString // Return original if no data found
    }

    // Create arrays to hold our data
    const headers: string[] = []
    const scores: string[] = []
    const modifiers: string[] = []

    // Extract headers
    const headingElements = statBlock.querySelectorAll('.stat-block-ability-scores-heading')
    headingElements.forEach((heading) => {
      headers.push((heading.textContent ?? '').trim())
    })

    // Extract scores and modifiers
    const scoreElements = statBlock.querySelectorAll('.stat-block-ability-scores-stat')
    scoreElements.forEach((stat) => {
      const scoreElement = stat.querySelector('.stat-block-ability-scores-score')
      const modifierElement = stat.querySelector('.stat-block-ability-scores-modifier')
      if (scoreElement) scores.push(scoreElement.textContent?.trim() ?? '')
      if (modifierElement) modifiers.push(modifierElement.textContent?.trim() ?? '')
    })

    // Create table only if we have data
    if (headers.length === 0 || scores.length === 0) {
      console.error('No ability scores found in the match')
      return htmlString // Return original if no data found
    }

    // Build values array
    const values = scores.map((score, i) => `${score} ${modifiers[i] || ''}`)

    // Create table HTML
    let tableHTML = '<table class="abilities">\n<tr>\n'

    // Add headers
    headers.forEach((header) => {
      tableHTML += `<th>${header}</th>\n`
    })

    tableHTML += '</tr>\n<tr>\n'

    // Add values
    values.forEach((value) => {
      tableHTML += `<td>${value}</td>\n`
    })

    tableHTML += '</tr>\n</table>'

    return serializedHtml.replace(statBlockDiv, tableHTML)
  }

  spellSheetInfo(): Dictionary[] {
    if (!this.spellSheetInfoCache) {
      this.spellSheetInfoCache = [
        { label: 'Time', value: this.activationString() },
        { label: 'Type', value: this.castingNotesString(), hidden: !this.castingNotesString() },
        { label: 'Duration', value: this.concentration ? this.concentrationTimeString() : this.durationString() },
        { label: 'Range', value: this.rangeString() },
        { label: 'Save', value: `${this.savingThrowAbility()} ${this.saveDc}`, hidden: !this.requiresSavingThrow },
        { label: 'To Hit', value: this.attackAction().attackModString(), hidden: !this.requiresAttackRoll },
        { label: 'Components', value: this.basicComponentsString() }
      ]
    }
    return this.spellSheetInfoCache
  }

  sanitizeDescription(): string {
    if (this.sanitizedDescriptionCache) return this.sanitizedDescriptionCache

    if (this.description === undefined)
      // YIKES THIS JUST NUKES THE DESCRIPTION OBJECT, DEFINITELY DON'T CALL THIS MULTIPLE TIMES

      return ''

    // Find <h4> block with class "compendium-hr" and any id, then replace the text with <em> tags
    this.description = this.description.replace(/<h4[^>]*>([^<]+)<\/h4>/g, (_, text) => `<h4><strong>${text}</strong></h4>`)

    this.description = this.convertAbilityScoresToTable(this.description)

    this.sanitizedDescriptionCache = this.description
      ?.replace(/<li class="Core-Styles_Core-Bulleted">/g, '• ')
      .replace(/<li>/g, '• ')
      .replace(/<p>/g, '')
      .replace(/<p class="Core-Styles_Core-Body">/g, '')
      .replace(/<p class="s19">/g, '')
      .replace(/<p class="monster-header">([^<]+)<\/p>/g, (_, text) => `<h4><strong>${text}</h4></h4>\r`)
      .replace(/<p class="Stat-Block-Styles_Stat-Block-Heading">([^<]+)<\/p>/g, (_, text) => `<h4><strong>${text}</strong></h4>\r`)
      .replace(/<p class="Stat-Block-Styles_Stat-Block-Title">([^<]+)<\/p>/g, (_, text) => `<b> ${text}</b>\r`)
      .replace(/<p class="Stat-Block-Styles_Stat-Block-Metadata">([^<]+)<\/p>/g, (_, text) => `<em> ${text}</em>\r`)
      .replace(/<p class="Stat-Block-Styles_Stat-Block-Data">/g, '')
      .replace(/<p class="Stat-Block-Styles_Stat-Block-Data-Last">/g, '')
      .replace(/<p class="Stat-Block-Styles_Stat-Block-Body">/g, '')
      .replace(/<div class="Basic-Text-Frame stat-block-finder stat-block-background">/g, '')
      .replace(/<div class="Basic-Text-Frame stat-block-background stat-block-finder">/g, '')
      .replace(/<\/p>/g, '\r')
      .replace(/<\/li>/g, '')
      .replace(/<div class="stat-block">/g, '')
      .replace(/<ul>/g, '')
      .replace(/<\/ul>/g, '')
      .replace(/<a[^>]*>/g, '')
      .replace(/<\/a>/g, '')
      .replace(/<Span(.+)>/g, '')
      .replace(/<\Span(.+)>/g, '')
      .replace(/&mdash;/g, '—')
      .replace(/&ndash;/g, '–')
      .replace(/&nbsp;/g, ' ')
      .replace(/&ldquo;/g, '“')
      .replace(/&rdquo;/g, '”')
      .replace(/&rsquo;/g, '’')
      .replace(/&lsquo;/g, '‘')
      .replace(/&times;/g, '×')
      .replace(/&hellip;/g, '…')
      .replace(/&minus;/g, '-')
      .replace(/<div class="stats">/g, '')
      .replace(/<\/div>/g, '')
      .replace(/<blockquote>/g, '')
      .replace(/<\/blockquote>/g, '')

    return this.sanitizedDescriptionCache
  }

  savingThrowAbility(): string | undefined {
    const name = Utility.nameForAbilityID(this.saveDcAbilityId)
    return name === null ? undefined : Utility.toTitleCase(name)
  }

  effectCountForLevel(level: number): number {
    const attributes = this.spellAttributes()
    const effectCountsForLevels = attributes.effectCountsForLevels
    let effectCount = attributes.effectCount
    if (!attributes.isCantrip && effectCountsForLevels && effectCountsForLevels.length > 0) {
      effectCount = effectCountsForLevels[Math.max(0, level - 1)]
    }

    return effectCount
  }

  rpgCardDamageValues() {
    // this works for upcasting spells… but not cantrips
    // OH SHIT, if there is no upcast data… just show the damage data on the left side!
    // https://www.dndbeyond.com/characters/127834466
    // {spell
    //                         .spellAttributes()
    //                         .diceCollectionsForLevels.map((diceCollection: DiceCollection, index: number) => (
    //                           <Box key={index}>
    //                             <Text>
    //                               <BoldTextSpan>{index + 1}:</BoldTextSpan> {diceCollection.displayString()}
    //                             </Text>
    //                           </Box>
    //                         ))}
  }

  rpgCardJson() {
    const sanitizedDescription = this.sanitizeDescription()
    const descriptionItems = sanitizedDescription?.split('\n').filter((str) => str.trim() !== '')

    return {
      count: 1,
      color: 'darkgreen',
      title: this.displayName,
      icon: 'magic-swirl',
      icon_back: 'magic-swirl',
      contents: [
        `subtitle | ${this.levelString()}`,
        `rule`,
        `property | Casting Time | ${this.activationString()}`,
        `property | Range | ${this.rangeString()}`,
        `property | Components | ${this.componentsString()}`,
        `property | Duration | ${this.durationString()}`,
        `rule`,
        ...(descriptionItems?.map((item) => `text | ${item}`) || [])
      ],
      tags: ['spell'],
      // US poker
      //   card_width: '2.5in',
      //   card_height: '3.5in',
      // Index card
      card_width: '3in',
      card_height: '5in',
      page_width: '8.5in',
      page_height: '11in',
      card_font_size: '11',
      title_size: '13'
    }
  }

  isCantrip() {
    return this.level === 0
  }

  hasSpellCardEffect() {
    return (
      this.hasUpcastDamageDice() ||
      this.hasUpcastHealingDice() ||
      (this.isHealingSpell() && !this.hasUpcastHealingDice()) ||
      (this.level === 0 && this.isDamageSpell()) ||
      (this.level > 0 && this.isDamageSpell() && this.spellAttributes().effectCountLabel)
    )
  }
}

export interface SpellSource {
  spells: Spell[]
  forceKnown: boolean
}
