import { Character } from '../DDB/Character'
import { Feature } from '../DDB/Feature'
import { Scenario, TurnAction } from '../DDB/TurnAction'
import { ActionLevelMap, NumberMap, CheckMap } from '../Common/Interfaces'
import { calculateAverageDamageForTurns } from '../Data/ChartData'
import { URLUtility } from '../Common/Utility'
import { TurnActionEngine } from '../DDB/TurnActionEngine'
import { AttackAction } from '../DDB/AttackAction'
import { ActivationType } from '../DDB/CharacterJSON/Activation'

export class TurnEngine {
  readonly cid: number
  readonly checkedFeatures: CheckMap
  readonly targetAC: number
  readonly actionIdList: ActionLevelMap[]
  readonly advantageOverrides: NumberMap
  turnActions: TurnAction[]
  bonusTurnActions: TurnAction[]
  reactionTurnActions: TurnAction[]
  readonly oncePerTurnDamageRiderTurnActions: TurnAction[] = []
  private allTurnActions: TurnAction[]
  readonly allTurnActionsAndDamageRiders: TurnAction[] = []
  readonly elvenAccuracy: boolean
  readonly actionRequiresSave: boolean
  readonly bonusActionRequiresSave: boolean
  readonly reactionRequireSave: boolean
  readonly anyActionRequiresSave: boolean
  readonly bonusActionFirst: boolean
  readonly activeWeaponMasteryWeaponTypes: string[]
  readonly simulatedAttackActions: AttackAction[]
  readonly totalAttackCount: number
  readonly totalBonusActionAttackCount: number
  readonly averageDamageMaps: NumberMap[] = [new NumberMap(), new NumberMap(), new NumberMap(), new NumberMap()]

  constructor(
    character: Character,
    checkedFeatures: CheckMap,
    actionIdList: ActionLevelMap[],
    advantageOverrides: NumberMap,
    acs: number[],
    targetAC: number,
    damageRiders: boolean,
    simulatedAttackActions: AttackAction[] = []
  ) {
    this.targetAC = targetAC
    this.checkedFeatures = checkedFeatures
    this.actionIdList = actionIdList
    this.advantageOverrides = advantageOverrides
    this.cid = character.id()
    this.simulatedAttackActions = simulatedAttackActions

    const checkedFeatureList = character.features().filter((feature: Feature) => checkedFeatures[feature.id])
    this.bonusActionFirst = checkedFeatureList.some((feature) => feature.bonusActionFirst)

    const features = this.removeInvalidFeatureCombinations(checkedFeatureList)
    const allActions = this.getCharacterActionsFromIDs(character, actionIdList)

    // Separate out the actions into their respective categories
    const actions = allActions.filter((action) => action.isAction())
    const bonusActions = allActions.filter((action) => action.isBonusAction())
    const reactions = allActions.filter((action) => action.isReaction())

    // Create the TurnAction objects
    this.turnActions = this.createTurnsFromActions(actions)
    this.bonusTurnActions = this.createTurnsFromActions(bonusActions)
    this.reactionTurnActions = this.createTurnsFromActions(reactions)
    this.allTurnActions = this.loadTurnsInOrder()
    const nonReactionTurnActions = this.allTurnActions.filter((turnAction) => !turnAction.isReaction())

    // Determine if any of the action categories require a saving throw
    this.actionRequiresSave = this.attacksRequireSave(actions)
    this.bonusActionRequiresSave = this.attacksRequireSave(bonusActions)
    this.reactionRequireSave = this.attacksRequireSave(reactions)
    this.anyActionRequiresSave = this.actionRequiresSave || this.bonusActionRequiresSave || this.reactionRequireSave
    this.elvenAccuracy = features.some((thisFeature) => thisFeature.effects.rerollOnAdvantage)

    // Find all weapon masteries
    this.activeWeaponMasteryWeaponTypes = this.findWeaponMasteries(features)

    // Count the total number of attacks
    this.totalBonusActionAttackCount = this.countBonusActionAttacks(features)
    this.totalAttackCount = this.totalMeleeActionCount(character, features, this.allTurnActions) // + freeAction count

    // Grab these before things are modified
    const diceModFeatures = features.filter((feature) => feature.effects.affectsBaseDice())

    const mainTurnActionEngine: TurnActionEngine = new TurnActionEngine()
    mainTurnActionEngine.modifyFeaturesForTurnActions(features, this.allTurnActions)

    let oncePerTurnDamageRiders: Feature[] = []
    if (damageRiders) {
      // Apply the non-once-per-turn damage-rider features to the Action & Bonus Actions
      oncePerTurnDamageRiders = features
        .filter((feature) => feature.only.oncePerTurn)
        .filter((feature) => feature.effects.additionalDamageOnHitDice)
        .filter((feature) => !feature.modifiesAnotherFeature())
        .filter((feature) => !feature.isCantrip)

      const nonDamageRiderFeatures = features.filter((f) => !oncePerTurnDamageRiders.includes(f))
      mainTurnActionEngine.applyFeaturesToTurnActions(nonDamageRiderFeatures, nonReactionTurnActions, character)
    } else {
      // do the normal thing
      mainTurnActionEngine.applyFeaturesToTurnActions(features, nonReactionTurnActions, character)
    }

    // Re-apply features to the Reactions, as they presumably on a different turn
    // Don't re-apply things that require a reaction or bonus action to use, like Absorb Elements or Steady Aim
    const reactionTurnActionEngine: TurnActionEngine = new TurnActionEngine()
    const reactionTurnActions = this.allTurnActions.filter((turnAction) => turnAction.isReaction())
    const onlyActionFeatures = features.filter((f) => !((f.isBonusAction() || f.isReaction()) && f.only.oncePerTurn))

    reactionTurnActionEngine.applyFeaturesToTurnActions(onlyActionFeatures, reactionTurnActions, character)

    // Some of the actions become BA (Quicken), some BA become A (Nick). Make sure they are moved to the right places.
    this.rectifyTurnActivationTypes()

    if (damageRiders) {
      // This is safe to run even with damage riders turned off
      this.oncePerTurnDamageRiderTurnActions = this.createTurnsFromDamageRiders(oncePerTurnDamageRiders)

      // Make sure stuff like auto-crit are applied to riders as well
      const riderActions = this.oncePerTurnDamageRiderTurnActions
      const riderTAE: TurnActionEngine = new TurnActionEngine()
      riderTAE.applyFeaturesToTurnActions(diceModFeatures, riderActions, character)
    }

    this.allTurnActionsAndDamageRiders = [...this.allTurnActions, ...this.oncePerTurnDamageRiderTurnActions]

    // Then calculate all the damage
    const start = performance.now()

    this.calculateDamageData(this.allTurnActions, acs, damageRiders)
    this.averageDamageMaps = this.calculateDamageMaps(features, acs, damageRiders)
    const end = performance.now()
    if (end - start > 250) {
      console.warn(`Damage data calculations took too long: ${end - start}ms`)
    }

    // And fix up the data for the UI
    this.createStubsForUnassignedTurns()
  }

  private loadTurnsInOrder(): TurnAction[] {
    return this.bonusActionFirst
      ? [this.bonusTurnActions, this.turnActions, this.reactionTurnActions].flat()
      : [this.turnActions, this.bonusTurnActions, this.reactionTurnActions].flat()
  }

  private calculateDamageMaps(features: Feature[], acs: number[], damageRiders: boolean) {
    if (damageRiders) {
      const nonReactionTurnActions = this.allTurnActions.filter((turnAction) => !turnAction.isReaction())
      this.calculateDamageRiderOdds(features, nonReactionTurnActions, acs)
      this.calculateDamageData(this.oncePerTurnDamageRiderTurnActions, acs, damageRiders)
      return calculateAverageDamageForTurns(this.allTurnActionsAndDamageRiders, acs)
    } else {
      return calculateAverageDamageForTurns(this.allTurnActions, acs)
    }
  }

  private calculateDamageRiderOdds(features: Feature[], nonReactionTurnActions: TurnAction[], acs: number[]) {
    for (const rider of this.oncePerTurnDamageRiderTurnActions) {
      const riderFeature = features.find((f) => f.id === rider.damageRiderFeatureId)
      if (!riderFeature) {
        console.error('Could not find rider for', rider.name())
        continue
      }
      const constraints = riderFeature.only
      const turns = nonReactionTurnActions
      const matchingTurns = turns.filter((t) => constraints.featureAppliesToTurn(riderFeature, t, features, turns))

      const calc = (hitChanceMethod: (turn: TurnAction, ac: number, acs: number[]) => number) => {
        return acs.map((ac) => {
          const missChances = matchingTurns.map((turn) => hitChanceMethod(turn, ac, acs))
          return 1 - missChances.reduce((product, chance) => product * chance, 1)
        })
      }

      rider.userScenario.hitMultiplier = calc((turn, ac, acs) => turn.userScenario.chanceToHitAc(ac, acs))
      rider.flatScenario.hitMultiplier = calc((turn, ac, acs) => turn.flatScenario.chanceToHitAc(ac, acs))
      rider.advantageScenario.hitMultiplier = calc((turn, ac, acs) => turn.advantageScenario.chanceToHitAc(ac, acs))
      rider.disadvantageScenario.hitMultiplier = calc((turn, ac, acs) => turn.disadvantageScenario.chanceToHitAc(ac, acs))
    }
  }

  private critOddsForScenario(turns: TurnAction[], scenario: (turn: TurnAction) => Scenario): number {
    if (turns.length === 0) return 0
    const allCritOdds = turns.map((turn) => turn.critOdds(scenario(turn)))
    // Return the average crit odds
    return allCritOdds.reduce((sum, odds) => sum + odds, 0) / allCritOdds.length
  }

  private calculateDamageData(turnActions: TurnAction[], acs: number[], damageRiders: boolean) {
    // The other 3 lists can just be always false, always neutral, and always advantage/elven advantage

    // First configure the turn scenarios
    for (const turn of this.allTurnActions) {
      if (turn.damageRider) continue
      const overriddenAdvantage = this.advantageOverrides[turn.id]
      const advantage = overriddenAdvantage !== undefined ? overriddenAdvantage : turn.advantageType()

      turn.userScenario.advantage = advantage
      turn.flatScenario.advantage = 0
      turn.advantageScenario.advantage = this.elvenAccuracy ? 2 : 1
      turn.disadvantageScenario.advantage = -1
    }

    const userCritOdds = this.critOddsForScenario(this.allTurnActions, (turn) => turn.userScenario)
    const flatCritOdds = this.critOddsForScenario(this.allTurnActions, (turn) => turn.flatScenario)
    const advCritOdds = this.critOddsForScenario(this.allTurnActions, (turn) => turn.advantageScenario)
    const disCritOdds = this.critOddsForScenario(this.allTurnActions, (turn) => turn.disadvantageScenario)

    // Then walk all turns and calculate the damage.
    // This is awkward, we should start using the -1->2 advantage system from scenarios here.
    for (const turn of turnActions) {
      let { advantage, disadvantage } = turn
      const overriddenAdvantage = this.advantageOverrides[turn.id]
      if (overriddenAdvantage !== undefined && overriddenAdvantage >= 0) advantage = overriddenAdvantage
      else if (overriddenAdvantage !== undefined && overriddenAdvantage < 0) disadvantage = true

      const adv = advantage
      const dis = disadvantage
      const t = true
      const f = false
      const eaAdv = this.elvenAccuracy ? 2 : 1

      turn.calculateScenarioDamage(turn.userScenario, acs, damageRiders, adv, dis, userCritOdds)
      turn.calculateScenarioDamage(turn.flatScenario, acs, damageRiders, 0, f, flatCritOdds)
      turn.calculateScenarioDamage(turn.advantageScenario, acs, damageRiders, eaAdv, f, advCritOdds)
      turn.calculateScenarioDamage(turn.disadvantageScenario, acs, damageRiders, 0, t, disCritOdds)

      turn.extendedDamageDataACs = acs
    }
  }

  private createStubsForUnassignedTurns() {
    if (this.turnActions.length === 0 || this.turnActions[0].isWeapon() || this.turnActions[0].isUnarmed()) {
      while (this.turnActions.length < this.totalAttackCount) {
        this.turnActions.push(new TurnAction(this.turnActions.length + 1))
      }
    }

    if (this.bonusTurnActions.length > 0 || this.totalBonusActionAttackCount > 1) {
      while (this.bonusTurnActions.length < this.totalBonusActionAttackCount) {
        this.bonusTurnActions.push(new TurnAction(this.bonusTurnActions.length + 1))
      }
    }
  }

  private rectifyTurnActivationTypes() {
    // Actions can become bonus actions (Quicken)
    const newBonusActionTurns = this.allTurnActions.filter((turnAction) => turnAction.activation?.activationType === ActivationType.BONUS_ACTION)

    // Some bonus actions can become actions (Nick)
    const newActionTurns = this.allTurnActions.filter((turnAction) => turnAction.activation?.activationType === ActivationType.ACTION)

    if (newBonusActionTurns.length > 0 || newActionTurns.length > 0) {
      for (const turn of newBonusActionTurns) {
        const index = this.turnActions.findIndex((action) => action.id === turn.id)
        if (index !== -1) {
          this.turnActions.splice(index, 1)
          this.bonusTurnActions.push(turn)
        }
      }

      for (const turn of newActionTurns) {
        const index = this.bonusTurnActions.findIndex((action) => action.id === turn.id)

        if (index !== -1) {
          this.bonusTurnActions.splice(index, 1)
          this.turnActions.push(turn)
        }
      }

      // We also have to renumber all of the actions and bonus actions. This is clumsy.
      const assignAttackNumbers = (turns: TurnAction[]) => {
        turns.forEach((turn, index) => (turn.attackNumber = index + 1))
      }

      assignAttackNumbers(this.turnActions)
      assignAttackNumbers(this.bonusTurnActions)
    }
  }

  private attacksRequireSave(attacks: AttackAction[]) {
    return attacks.length > 0 && attacks[0].attributes.requiresSavingThrow
  }

  private countBonusActionAttacks(features: Feature[]): number {
    return 1 + features.reduce((sum, feature) => sum + (feature.effects.extraBonusAttacksThisAction || 0), 0)
  }

  private findWeaponMasteries(features: Feature[]): string[] {
    const getMasteries = (extractor: (feature: Feature) => string | undefined) =>
      features.map(extractor).filter((name): name is string => name !== undefined)

    const generalMasteries = getMasteries((feature) => feature.only.specificWeaponMasteryType)
    const nickMasteries = getMasteries((feature) => feature.effects.extraAttackThisActionIfWeaponEquipped)

    return [...generalMasteries, ...nickMasteries]
  }

  private removeInvalidFeatureCombinations(checkedFeatureList: Feature[]): Feature[] {
    const featurePredicates = [
      (feature: Feature) => feature.effects.isManeuver,
      (feature: Feature) => feature.requiresConcentration,
      (feature: Feature) => feature.isUnarmedFightingDamage // "Unarmed Fighting (Armed) / (Unarmed)"
    ]

    return [
      ...checkedFeatureList.filter((feature) => !featurePredicates.some((predicate) => predicate(feature))),
      ...featurePredicates.flatMap((predicate) => this.onlyFirstFromEach(checkedFeatureList, predicate))
    ]
  }

  private onlyFirstFromEach(features: Feature[], predicate: (feature: Feature) => boolean): Feature[] {
    const filtered = features.filter(predicate)
    return filtered.length > 0 ? [filtered[0]] : []
  }

  shareableURL(): string {
    return URLUtility.shareURLForParams(this.cid, this.urlSearchParams())
  }

  private urlSearchParams(): URLSearchParams {
    const featureNumbers = Object.keys(this.checkedFeatures).filter((key: string) => this.checkedFeatures[Number(key)] === true)
    const actionIdListObject = this.actionIdList.map((map) => [map.actionId, map.level])

    const jsonDictionary = {
      features: featureNumbers.join(','),
      actions: actionIdListObject.toString(),
      overrides: JSON.stringify(this.advantageOverrides),
      ac: String(this.targetAC)
    }
    return new URLSearchParams(jsonDictionary)
  }

  getCharacterActionsFromIDs(character: Character, actionIdList: ActionLevelMap[]): AttackAction[] {
    const actions: AttackAction[] = []

    // TODO: This is silly, what are we trying to filter OUT here by doing this? Experiment later, we can probably really simplify this
    const fullList = character
      .attackActions()
      .concat(character.attackBonusActions())
      .concat(character.attackReactions())
      .concat(character.damagingSpellActions())
      .concat(character.warcasterReactionSpellAttackActions())
      .concat(this.simulatedAttackActions)

    for (const actionIdMap of actionIdList) {
      for (const attackAction of fullList) {
        const attrId = Number(attackAction.attributes.id)
        const actionId = Number(actionIdMap.actionId)

        if (attrId === actionId) {
          const newAttackAction = attackAction.copy()
          newAttackAction.turnLevel = attackAction.turnLevel
          if (actionIdMap.level) {
            newAttackAction.turnLevel = actionIdMap.level
          }
          actions.push(newAttackAction)
        }
      }
    }
    return actions
  }

  isNickEnabled(features: Feature[], turnActions: TurnAction[]): boolean {
    const nickFeatures = features.filter((feature) => feature.effects.extraAttackThisActionIfWeaponEquipped)
    if (nickFeatures.length) {
      const nickTurns = nickFeatures.map((feature) => feature.effects.extraAttackThisActionIfWeaponEquipped || '').filter((value) => value !== '')
      const specificLightWeaponTurns = turnActions
        .filter((turn) => turn.hasWeaponProperty('Light'))
        .filter((turn) => nickTurns.includes(turn.weaponType()))
      const baNickWeapons = specificLightWeaponTurns.filter((turn) => turn.isBonusAction())
      const aNickWeapons = specificLightWeaponTurns.filter((turn) => !turn.isBonusAction())
      return baNickWeapons.length > 0 || aNickWeapons.length > 0
    }
    return false
  }

  extraAttacksThisTurn(features: Feature[], turnActions: TurnAction[]): number {
    return features.filter((feature) => feature.effects.extraAttackThisTurn).length + turnActions.filter((turn) => turn.hasFreeAction()).length
  }

  extraAttacksThisAction(features: Feature[], nickEnabled: boolean): number {
    return features.filter((feature) => feature.effects.extraAttackThisAction).length + (nickEnabled ? 1 : 0)
  }

  totalMeleeActionCount(character: Character, features: Feature[], turnActions: TurnAction[]): number {
    const actionSurge: boolean = features.some((feature) => feature.effects.actionSurge)
    const nickEnabled = this.isNickEnabled(features, turnActions)
    const extraAttacksThisTurn = this.extraAttacksThisTurn(features, turnActions)
    const extraAttacksThisAction = this.extraAttacksThisAction(features, nickEnabled)
    const secondAttack: boolean = features.some((feature) => feature.effects.secondAttack)
    const extraTurn: boolean = features.some((feature) => feature.effects.extraTurn)

    let totalAttackCount = character.attackCount() + extraAttacksThisAction
    if (totalAttackCount === 1 && secondAttack) totalAttackCount = 2
    if (actionSurge) totalAttackCount *= 2
    totalAttackCount += extraAttacksThisTurn
    if (extraTurn) totalAttackCount *= 2
    return totalAttackCount
  }

  createTurnsFromDamageRiders(features: Feature[]): TurnAction[] {
    return features.map((feature, index) => {
      const turnAction = new TurnAction(index + 1, AttackAction.CreateFromDamageRider(feature))
      turnAction.damageRider = true
      turnAction.autoHit = true
      turnAction.attackNumber = 0
      turnAction.damageRiderFeatureId = feature.id

      // These are taken from FeatureEffects.affectsBaseDice and should be updated as that is
      turnAction.autoCrit = feature.effects.autoCrit
      turnAction.critDiceDealMaxDamage = feature.effects.critDiceDealMaxDamage
      turnAction.damageMultiplier = feature.effects.damageMultiplier || 1
      return turnAction
    })
  }

  createTurnsFromActions(actions: AttackAction[]): TurnAction[] {
    if (!actions.length) return []
    const turns = []
    const addedTurnCount = 0
    for (let index = 0; index < actions.length; index++) {
      const action = actions[index]
      const turnAction = new TurnAction(index + addedTurnCount + 1, action)
      turns.push(turnAction)

      // WOrk in progress for multiple attacks
      //   if (turnAction.attributes().multipleAttacks && turnAction.attributes().effectCount) {
      //     const effectCount = turnAction.attributes().effectCount
      //     for (let i = 1; i < effectCount; i++) {
      //       // start at 1, we already added the first
      //       addedTurnCount++
      //       const newTurn = new TurnAction(index + addedTurnCount + 1, action.copy())
      //       turns.push(newTurn)

      //       // We have to create unique IDs
      //       // We have to mark the fake ones as dependent on the original somehow, then have the UI not show the number or delete button
      //       // We have to stop multiplying damage by effectCount
      //       // Maybe we get rid of effectCount for these and store the number in multipleAttacks? Is it redundant? Maybe we ditch chain lightning here, and just have it be a single property
      //       // Test Eldritch Blast and all the other +1s
      //       // Quickening Scorching Ray needs to quicken ALL of these... not just one of the beams
      //     }
      //   }
    }
    return turns
  }
}
