import React from 'react'
import { Container, Heading, Stack, VStack, Box, Text, ListItem, UnorderedList, HStack, Link, Image } from '@chakra-ui/react'
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, AccordionIcon } from '@chakra-ui/react'
import { DprTab, MainNavBar } from './MainNavBar'
import { Code } from '@chakra-ui/react'
import { TrackPageView, PAGE_FAQ } from '../Common/AppAnalytics'
import { DISCORD_URL, PATREON_URL } from '../Common/Constants'

const accentColor = 'blue.200'

function DiscordLink({ children }: { children?: React.ReactNode }) {
  return (
    <Link color={accentColor} target="_blank" href={DISCORD_URL}>
      {children ?? 'Discord'}
    </Link>
  )
}

function FAQList({ items }: { items: string[] }) {
  return (
    <UnorderedList>
      {items.map((item) => (
        <ListItem key={item}>{item}</ListItem>
      ))}
    </UnorderedList>
  )
}

function FAQSection({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <AccordionItem>
      <AccordionButton fontSize={'xl'}>
        <Box as="span" flex="1" textAlign="left">
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel pb={4} fontSize={'md'}>
        <HStack>
          <Box px={8}>{children}</Box>
        </HStack>
      </AccordionPanel>
    </AccordionItem>
  )
}

export function FAQPage() {
  const title = 'Frequently Asked Questions'
  const description = ''

  TrackPageView(PAGE_FAQ)

  const element = (
    <Container py={{ base: '2', md: '2' }}>
      <VStack>
        <Stack>
          <Heading size={{ base: 'sm', md: 'sm' }} color={accentColor}>
            {title}
          </Heading>
        </Stack>
        <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
          {description}
        </Text>

        <Accordion allowMultiple width="100%">
          <FAQSection title="What is this for?">
            This is a tool that helps you understand your character better, specifically through the lens of dealing damage. For new players, or even
            seasoned ones, it can often be intimidating to understand all of the features of a character and how they interact. It can also sometimes
            be unintuitive to know which feat, stat increase, choice of equipment, fighting style would be most impactful. This tool lets you quickly
            try out various builds and options and simulate what sort of damage you can do with each.
          </FAQSection>
          <FAQSection title="Where do I send feedback, feature requests, bug reports, or hate mail? Do you have a Discord?">
            <Text>
              Yep, you can join the official <DiscordLink />.
            </Text>
          </FAQSection>
          <FAQSection title="Does this work with Foundry or Roll20 characters?">
            Unfortunately, no. This site currently only works with D&D Beyond characters.
          </FAQSection>
          <FAQSection title="Do I need a DDB character to use this?">
            Currently, yes. This tool is built around understanding your characters in DDB. If there is interest, I am definitely interested in
            buoding a a DDB-free general DPR calculator.
          </FAQSection>
          <FAQSection title="How can I support this project?">
            <Text>
              The best way is to support me on{' '}
              <Link color={accentColor} href={PATREON_URL} target="_blank" isExternal>
                Patreon
              </Link>
              . This recurring support can help cover the hosting and server costs of this site, and help me justify spending even more time on it.
            </Text>
          </FAQSection>
          <FAQSection title="What are the currently known issues?">
            <FAQList
              items={[
                "Don't support all reaction skills",
                "Doesn't support creature multi-attack, and sometimes can't parse more complex attacks",
                "Doesn't yet support all magical item effects (but does support most)",
                'With high level builds, features with limited resources like superiority dice can be used more than the maximum available',
                'And probably a whole lot more! If you find any, please let me know on Discord!'
              ]}
            />
          </FAQSection>
          <FAQSection title="What are some examples of things I can do with this?">
            <VStack align="left">
              <Text>Ultimately, this helps answer all those burning questions you may have. For example:</Text>
              <FAQList
                items={[
                  'Is Flames of Phlegethos is worth it for my Tiefling Sorcerer?',
                  'How much damage can I do on a first nova round versus a baseline or even resourceless round?',
                  'How impactful is Bless on my attacks?',
                  'Should my Rogue take the Duel Wielder or Piercer feat?',
                  'Will +2 STR or Great Weapon Fighting be more impactful?',
                  'How often will I completely miss with two Sharpshooter attacks per turn?',
                  'I have a level 4 spell slot, which spell will do most single target damage when upcast?',
                  'How likely am I to hit that AC 20 enemy with Elven Accuracy versus without?',
                  'How much do various features contribute to my overall damage per round?'
                ]}
              />
            </VStack>
          </FAQSection>
          <FAQSection title="Is this open source?">Not yet! If there is interest, let me know.</FAQSection>
          <FAQSection title="Is this mathematically accurate?">
            <Box>
              <Text>As far as I know, yes!</Text>
              <Text>
                However, for once-per-turn-on-hit effects like sneak attack, which could trigger on the first or second attack but not both, this tool
                will under-estimate the damage unless you enable the Experimental &ldquo;Damage Riders&rduo; feature. The DPR seems to work, but there
                are still a few bugs around charting with the feature.
              </Text>
            </Box>
          </FAQSection>
          <FAQSection title="How do I see the math behind the calculations?">
            <Text>
              You can see the breakdown of the dice math if you hover over the average numbers in the actions table in the top left. You can even tap
              it to copy it, then try it out yourself at{' '}
              <Link color={accentColor} href="https://dice.clockworkmod.com/" isExternal>
                dice.clockworkmod.com
              </Link>
              . That site doesn&apos;t support Bless and other dice modifiers to hit, but otherwise all attacks and features should be supported.
            </Text>
          </FAQSection>
          <FAQSection title="Does this support every class feature, feat, racial trait, etc?">
            As far as I know, I support over 99% of them. If you find any missing, let us know on our <DiscordLink />!
          </FAQSection>
          <FAQSection title="Do you use any open source libraries?">
            <VStack>
              <Text>
                Yes! This couldn&apos;t be possible without the amazing{' '}
                <Link color={accentColor} href="http://dice.clockworkmod.com/" isExternal>
                  http://dice.clockworkmod.com/
                </Link>{' '}
                , which was the inspiration for this whole site. This uses a modified copy of{' '}
                <Link color={accentColor} href="https://github.com/koush/dice.clockworkmod.com" isExternal>
                  https://github.com/koush/dice.clockworkmod.com
                </Link>{' '}
                which is licensed under the Apache 2.0 License. Over time I may open source my version, once it is actually good enough.
              </Text>
              <Code p={2} colorScheme="gray">
                Copyright 2013 Koushik Dutta (2013) Licensed under the Apache License, Version 2.0 (the &quot;License&quot;); you may not use this
                file except in compliance with the License. You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0 Unless
                required by applicable law or agreed to in writing, software distributed under the License is distributed on an &quot;AS IS&quot;
                BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing
                permissions and limitations under the License.
              </Code>
            </VStack>
          </FAQSection>
          <FAQSection title="How can I remove my character from this site?">
            Mark your character as private or campaign only on D&D Beyond. This site can only read public characters.
          </FAQSection>
          <FAQSection title="Does this store my character on your servers?">
            No, using this site does not store any character data at all, all the caching happens client-side (on your computer). If you create a
            share link, however, it stores just enough to create a little thumbnail preview: character name, class, url, and numbers repreesnting
            which attacks and features you chose. If you would like me to fully delete one of your share links for any reason, just let me know on our
            <DiscordLink />. This feature may be exposed to users some time in the future if there is interest.
          </FAQSection>
          <FAQSection title="What features are coming in the future?">
            I have a ton of ideas, but honestly - whatever the users want the most. Come recommend ideas on <DiscordLink />.
          </FAQSection>

          <FAQSection title="This is great, can I buy you a coffee?">
            <HStack alignContent={'left'}>
              <Text>
                While I prefer Patreon, I understand that is not for everyone. I&apos;m currently unemployed and working on this throughout the week,
                so every little bit helps.
              </Text>
              <Link href="https://ko-fi.com/M4M5YHTAV" target="_blank" isExternal>
                <Image height={'36px'} src="https://storage.ko-fi.com/cdn/kofi2.png?v=3" alt="Buy Me a Coffee at ko-fi.com" />
              </Link>
            </HStack>
          </FAQSection>
        </Accordion>
      </VStack>
    </Container>
  )

  return <MainNavBar tab={DprTab.FAQ}>{element}</MainNavBar>
}
