import { Dice } from '../Dice'

import { FeatureParserBase } from './FeatureParserBase'
import { Utility } from '../../Common/Utility'
import { ActivationType } from '../CharacterJSON/Activation'

export class FeatureParserMonk {
  static parse(parser: FeatureParserBase) {
    const thisClass = 'Monk'
    if (!parser.validate(thisClass)) return false
    const { name, only, action, feature, effects, character, isSubclassFeature } = parser

    if (name === 'Elemental Epitome') {
      if (action.id !== 10292775) return feature.skip()
      const monkDieSize = character.monkDieSize()
      only.monkWeaponOrUnarmed = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(1, monkDieSize)
      feature.usesLimitedResource = true
      feature.name = `${feature.name} – Empowered Strikes`
      return true
    }

    if (name === 'Flurry of Blows: Topple') {
      only.unarmed = true
      only.activationType = ActivationType.BONUS_ACTION
      only.featureNameEnabled = 'Flurry of Blows'

      effects.nextAttacksHaveAdvantage = true
      effects.advantage = false
      feature.usesLimitedResource = true
      feature.notes = 'Flurry of Blows attacks can topple enemies on failed Dex save'
      return true
    }

    if (name === 'Ki-Fueled Attack') {
      effects.extraBonusAttacksThisAction = 1
      feature.notes = 'Extra bonus action monk attack this turn'
      return true
    }

    if (name === 'Focused Aim') {
      const kiPoints = action.kiPoints ?? 1
      only.weaponOrUnarmed = true
      effects.additionalToHitDice = Dice.flatAmountDie(2 * kiPoints)
      feature.usesLimitedResource = true
      feature.name = `Focused Aim (${Utility.kiPointsString(kiPoints)})`
      return true
    }

    if (name === 'Quivering Palm') {
      if (isSubclassFeature || character.is2024Class('Monk')) return feature.skip()

      only.unarmed = true
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(10, 10)
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Fangs of the Fire Snake') {
      if (isSubclassFeature) return feature.skip()
      only.unarmed = true
      effects.additionalMeleeRange = 10
      effects.additionalDamageOnHitDice = Dice.Create(1, 10)
      return true
    }

    if (name === 'Radiant Sun Bolt') {
      if (isSubclassFeature) return feature.skip()
      only.abilityNamed = 'Radiant Sun Bolt'
      effects.additionalEffectCount = 2
      feature.notes = '2 additional radiant sun bolts'
      return true
    }

    if (name === 'Sharpen the Blade') {
      if (isSubclassFeature) return feature.skip()
      const kiPoints = action.kiPoints ?? 1
      only.weapon = true
      effects.additionalToHitDice = Dice.flatAmountDie(kiPoints)
      effects.additionalDamageOnHitDice = Dice.flatAmountDie(kiPoints)
      feature.usesLimitedResource = true
      feature.name = `Sharpen the Blade (${Utility.kiPointsString(kiPoints)})`
      feature.notes = `+${kiPoints} to attack and dmg`
      return true
    }

    if (name === 'Deft Strike') {
      if (isSubclassFeature) return feature.skip()
      const monkDieSize = character.monkDieSize()
      only.oncePerTurn = true
      effects.additionalDamageOnHitDice = Dice.Create(1, monkDieSize)
      return true
    }

    if (name === "Kensei's Shot") {
      if (isSubclassFeature) return feature.skip()
      only.weaponProperties = ['Range']
      effects.additionalDamageOnHitDice = Dice.Create(1, 4)
      return true
    }

    if (name === 'Stunning Strike') {
      if (isSubclassFeature) return feature.skip()

      only.monkWeaponOrUnarmed = true
      effects.nextAttacksHaveAdvantage = true
      feature.usesLimitedResource = true
      feature.notes = 'Unarmed or monk attacks stun enemy on failed Con save'
      return true
    }

    if (name === 'Flurry of Blows') {
      effects.extraBonusAttacksThisAction = 1
      feature.usesLimitedResource = true
      feature.notes = 'As a bonus action, you can make 2 unarmed strikes'
      return true
    }

    if (name === 'Flurry of Blows (Heightened)') {
      if (isSubclassFeature) return feature.skip()
      only.unarmed = true
      only.activationType = ActivationType.BONUS_ACTION
      only.featureNameEnabled = 'Flurry of Blows'
      effects.extraBonusAttacksThisAction = 1
      feature.usesLimitedResource = true
      feature.notes = 'Flurry of Blows gains 1 additional strike'
      return true
    }

    if (name === 'Intoxicated Frenzy') {
      if (isSubclassFeature) return feature.skip()
      only.unarmed = true
      only.activationType = ActivationType.BONUS_ACTION
      only.featureNameEnabled = 'Flurry of Blows'
      effects.additionalEffectCount = 3
      feature.usesLimitedResource = true
      feature.notes = 'Flurry of Blows gains 3 additional strikes'
      return true
    }

    if (name === 'Awakened Astral Self') {
      if (isSubclassFeature) return feature.skip()
      effects.extraAttackThisAction = true
      feature.name = 'Astral Barrage'
      feature.notes = 'Extra attack if all attacks are astral arms'
      return true
    }

    if (name === 'Body of the Astral Self') {
      if (isSubclassFeature) return feature.skip()
      const monkDieSize = character.monkDieSize()
      only.oncePerTurn = true
      only.abilityNamed = 'Astral Arms'
      effects.additionalDamageOnHitDice = Dice.Create(1, monkDieSize)
      return true
    }

    if (name === 'Hand of Harm') {
      if (isSubclassFeature) return feature.skip()
      const wisMod = character.modifierForAbility('wisdom')
      const monkDieSize = character.monkDieSize()
      only.oncePerTurn = true
      only.unarmed = true
      effects.additionalDamageOnHitDice = Dice.Create(1, monkDieSize, wisMod)
      feature.usesLimitedResource = true
      return true
    }

    if (name === 'Flurry of Healing and Harm') {
      if (isSubclassFeature) return feature.skip()
      const wisMod = character.modifierForAbility('wisdom')
      const monkDieSize = character.monkDieSize()

      only.oncePerTurn = true
      only.unarmed = true
      only.activationType = ActivationType.BONUS_ACTION
      only.featureNameEnabled = 'Flurry of Blows'
      effects.additionalDamageOnHitDice = Dice.Create(1, monkDieSize, wisMod)
      feature.usesLimitedResource = true
      feature.notes = `+${effects.additionalDamageOnHitDice.diceString()} dmg to next Flurry of Blows strike`
      return true
    }

    if (name === 'Unerring Accuracy') {
      if (isSubclassFeature) return feature.skip()
      only.weapon = true
      only.oncePerTurn = true
      effects.advantage = true
      return true
    }

    if (name === 'Shadow Step') {
      if (isSubclassFeature) return feature.skip()
      only.oncePerTurn = true
      only.meleeOrUnarmed = true
      effects.advantage = true
      feature.usesLimitedResource = true
      return true
    }

    if (this.nonBuffActions().includes(name))
      //   if (parser.idsToIgnore.includes(action.id)) console.log(`${thisClass} has erroneous action ${name}`)
      return feature.skip()

    return false
  }

  static nonBuffActions() {
    return [
      '12: Ability Score Improvement',
      '16: Ability Score Improvement',
      '8: Ability Score Improvement',
      'Ability Score Improvement',
      'Acrobatic Movement',
      'Arms of the Astral Self',
      'Arms of the Astral Self (DEX/STR)',
      'Arms of the Astral Self (WIS)',
      'Arms of the Astral Self: Summon',
      'Body and Mind',
      'Core Monk Traits',
      'Deflect Attack',
      'Deflect Attack: Redirect Attack',
      'Deflect Attacks',
      'Deflect Energy',
      'Deflect Missiles',
      'Deflect Missiles Attack',
      'Diamond Soul',
      'Disciplined Survivor',
      'Elemental Attunement',
      'Elemental Burst',
      'Empowered Strikes',
      'Empty Body',
      'Epic Boon',
      'Equipment',
      'Evasion',
      'Extra Attack',
      'Fleet Step',
      'Flurry of Blows: Addle',
      'Flurry of Blows: Push',
      'Focus Points',
      'Hand of Harm: Physician’s Touch',
      'Hand of Healing',
      'Hand of Healing: Physician’s Touch',
      'Hand of Ultimate Mercy',
      'Heightened Focus',
      'Hit Points',
      'Implements of Mercy',
      'Ki',
      'Ki Points',
      'Ki-Empowered Strikes',
      'Manipulate Elements',
      'Martial Arts',
      'Monastic Tradition',
      'Monk Subclass',
      'Monk’s Focus',
      'Open Hand Technique',
      'Patient Defense',
      'Patient Defense (Heightened)',
      'Perfect Focus',
      'Perfect Self',
      'Physician’s Touch',
      'Proficiencies',
      'Purity of Body',
      'Quickened Healing',
      'Searing Arc Strike',
      'Searing Sunburst',
      'Self-Restoration',
      'Slow Fall',
      'Sneaky',
      'Step of the Wind',
      'Step of the Wind (Heightened)',
      'Step of the Wind: Fleet Step',
      'Stillness of Mind',
      'Stride of the Elements',
      'Sun Shield',
      'Superior Defense',
      'Timeless Body',
      'Tongue of the Sun and Moon',
      'Unarmed Strike',
      'Unarmored Defense',
      'Unarmored Movement',
      'Unarmored Movement Improvement',
      'Uncanny Metabolism',
      'Visage of the Astral Self',
      'Wholeness of Body'
    ]
  }
}
