import { ActionParser } from './ActionParser'
import { AttackAction } from '../AttackAction'
import { Dice } from '../Dice'
import { Action } from '../CharacterJSON/Actions'
import { Range } from '../CharacterJSON/Range'

export class ActionParserBard {
  static parse(parser: ActionParser, action: Action, attackActions: AttackAction[]) {
    const name = action.name
    const character = parser.character
    if (character.classLevel('Bard') === 0) return false

    const spellAttackMod = character.attackModForAbility('charisma')
    if (name === 'Animating Performance: Dancing Item') {
      const dice = Dice.Create(1, 10, parser.proficiencyBonus)
      const slam = 'Dancing Item: Force-Empowered Slam'
      const sam = spellAttackMod
      const attributes = { range: Range.makeWeaponRange(5), type: 'Spell Attack' }
      const attack = new AttackAction(action.id, slam, sam, dice, attributes, parser.bonusAction)
      attackActions.push(attack)
      return true
    }

    return false
  }
}
