import { Json } from '../../Common/Types'
import { Dice } from '../Dice'

export class InventoryDefinition {
  id: number
  name: string
  type: string
  damageType: string
  magic: boolean
  rarity: string
  filterType: string
  attackType: number
  range: number
  longRange: number
  isMonkWeapon: boolean
  baseArmorName?: string
  subType?: string
  damage?: Dice
  weaponBehaviors: WeaponBehavior[]
  canEquip: boolean
  canAttune: boolean
  grantedModifiers: GrantedModifier[]
  properties: InventoryProperties[]

  constructor(json: Json) {
    this.id = json.id
    this.baseArmorName = json.baseArmorName
    this.properties = json.properties ? json.properties.map((property: Json) => new InventoryProperties(property)) : []

    this.canAttune = json.canAttune
    this.filterType = json.filterType
    this.weaponBehaviors = json.weaponBehaviors.map((behavior: Json) => new WeaponBehavior(behavior))

    this.damageType = json.damageType
    this.subType = json.subType
    this.name = json.name
    this.magic = json.magic
    this.rarity = json.rarity
    this.canEquip = json.canEquip
    this.type = json.type
    this.attackType = json.attackType
    this.range = json.range
    this.longRange = json.longRange
    this.isMonkWeapon = json.isMonkWeapon
    this.grantedModifiers = json.grantedModifiers.map((modifier: Json) => new GrantedModifier(modifier))
    this.damage = json.damage
  }
}

export class Inventory {
  id: number
  definition: InventoryDefinition
  isAttuned: boolean
  equipped: boolean

  constructor(json: Json) {
    this.id = json.id
    this.isAttuned = json.isAttuned
    this.equipped = json.equipped
    this.definition = new InventoryDefinition(json.definition)
  }
}

export class InventoryProperties {
  id: number
  name: string
  notes: string

  constructor(json: Json) {
    this.id = json.id
    this.name = json.name
    this.notes = json.notes
  }
}

export class GrantedModifier {
  fixedValue?: number
  value: number
  id: string
  entityTypeId?: string
  type: string
  subType: string
  dice?: Dice
  restriction: string
  statId?: number
  requiresAttunement: boolean
  friendlyTypeName: string
  friendlySubtypeName: string
  isGranted: boolean
  componentId: number

  constructor(json: Json) {
    this.fixedValue = json.fixedValue
    this.value = json.value
    this.id = json.id
    this.entityTypeId = json.entityTypeId
    this.type = json.type
    this.subType = json.subType
    this.dice = new Dice(json.dice)
    this.restriction = json.restriction
    this.statId = json.statId
    this.requiresAttunement = json.requiresAttunement
    this.friendlyTypeName = json.friendlyTypeName
    this.friendlySubtypeName = json.friendlySubtypeName
    this.isGranted = json.isGranted
    this.componentId = json.componentId
  }
}

export class WeaponBehavior {
  damage: Dice
  damageType: string
  range: number
  longRange: number
  isMonkWeapon: boolean
  properties: InventoryProperties[]

  constructor(json: Json) {
    this.damage = new Dice(json.damage)
    this.damageType = json.damageType
    this.range = json.range
    this.longRange = json.longRange
    this.isMonkWeapon = json.isMonkWeapon
    this.properties = json.properties ? json.properties.map((property: Json) => new InventoryProperties(property)) : []
  }
}
