import { Json } from '../../Common/Types'

export enum ActivationType {
  ACTION = 1,
  BONUS_ACTION = 3,
  REACTION = 4,
  ONE_MINUTE_CAST = 6, // Guessing this is 1 minute casting…
  HOUR_TEN_CAST = 7, // Just find familiar so far, but need to check…
  FREE_ACTION = 8 // Sneak Attack, Divine Smite, etc. Is there a better name for this?
}

export class Activation {
  activationType: ActivationType
  activationTime: number | null

  constructor(json: Json) {
    this.activationType = json.activationType
    this.activationTime = json.activationTime
  }

  static Action() {
    return new Activation({ activationType: ActivationType.ACTION })
  }

  static BonusAction() {
    return new Activation({ activationType: ActivationType.BONUS_ACTION })
  }

  static Reaction() {
    return new Activation({ activationType: ActivationType.REACTION })
  }

  static None() {
    return new Activation({ activationType: 0 })
  }

  usesAction(): boolean {
    return this.activationType === ActivationType.ACTION
  }

  usesBonusAction(): boolean {
    return this.activationType === ActivationType.BONUS_ACTION
  }

  usesReaction(): boolean {
    return this.activationType === ActivationType.REACTION
  }

  isHourTenCast(): boolean {
    return this.activationType === ActivationType.HOUR_TEN_CAST
  }
  isCastingTimeOneMinute(): boolean {
    return this.activationType === ActivationType.ONE_MINUTE_CAST
  }

  activationTypeString(): string {
    if (this.usesAction()) return 'Action'
    if (this.usesBonusAction()) return 'Bonus Action'
    if (this.usesReaction()) return 'Reaction'
    if (this.isCastingTimeOneMinute()) return '1 Minute'
    if (this.isHourTenCast()) return '1 Hour, 10 Minutes' // Find Familiar…

    console.log('Unknown activation type', this.activationType)
    return 'None'
  }
}
