import { Json } from '../../Common/Types'

export class Range {
  origin: string // Weapon, etc
  range: number
  longRange?: number
  rangeValue?: number
  aoeType?: string
  aoeSize?: number
  aoeValue?: number

  constructor(data: Json) {
    this.origin = data.origin
    this.range = data.range
    this.longRange = data.longRange
    this.rangeValue = data.rangeValue
    this.aoeType = data.aoeType
    this.aoeValue = data.aoeValue
    this.aoeSize = data.aoeSize
  }

  static makeWeaponRange(theRange: number, longRange?: number) {
    return new Range({
      origin: 'Weapon',
      range: theRange,
      longRange: longRange && longRange > 0 ? longRange : theRange
    })
  }

  static makeSpellAttackRange(theRange: number) {
    return new Range({
      origin: 'Spell Attack',
      range: theRange,
      longRange: theRange
    })
  }

  static makeConeAoeRange(theRange: number) {
    return new Range({
      origin: 'Self',
      range: 0,
      rangeValue: 0,
      aoeValue: theRange,
      aoeType: 'Cone'
    })
  }

  static makeLineAoeRange(theRange: number) {
    return new Range({
      origin: 'Self',
      range: 0,
      rangeValue: 0,
      aoeValue: theRange,
      aoeType: 'Line'
    })
  }

  rangeString() {
    const { origin, range, longRange, rangeValue, aoeType, aoeSize } = this
    let rangeString = origin

    if (origin === 'Weapon' || origin === 'Spell Attack') {
      if (longRange !== range) {
        rangeString = `${range} (${longRange})`
      } else {
        rangeString = `${range} ft`
      }
    } else if (this.origin === 'Ranged') {
      rangeString = `${rangeValue} ft`
    }

    if (aoeType && aoeSize) {
      if (parseInt(aoeType) === 5) {
        rangeString = `${aoeSize} ft Sphere`
      } else if (parseInt(aoeType) === 1) {
        rangeString = `${aoeSize} ft Cone`
      } else {
        console.warn('Unknown AOE type', this.aoeType)
      }
    }
    return rangeString
  }

  notesString() {
    const { aoeType, aoeValue } = this

    if (aoeType && aoeValue) {
      return `${aoeValue} ft ${aoeType}`
    }

    return undefined
  }
}
