import { Dictionary } from '../Common/Types'
import { CharacterFeat } from './Feat'
import { WeaponMastery } from './WeaponMastery'
import { FightingStyle } from './FightingStyle'
import { Ammunition } from './Ammunition'
import { Feature, FeatureLevelInfo } from './Feature'
import { FeatureSource } from './FeatureSource'
import { Character } from './Character'
import { Spell } from './Spell'
import { Dice } from './Dice'
import { Activation } from './CharacterJSON/Activation'
import { FIGHTING_STYLES } from '../Common/Constants'
import { CharacterJSON } from './CharacterJSON/CharacterJSON'
import { AttackAction } from './AttackAction'
import { FeatureParserSpellEffects } from './FeatureParsers/FeatureParserSpellEffects'
import { Weapon } from './Weapon'

export class FeatureLoader {
  static loadAllFeatures(
    characterData: CharacterJSON,
    weapons: Weapon[],
    weaponMasteries: WeaponMastery[],
    feats: CharacterFeat[],
    fightingStyles: FightingStyle[],
    attackActions: AttackAction[],
    ammunition: Ammunition[],
    spells: Spell[],
    spellIDsToPrune: number[],
    character: Character
  ): Feature[] {
    const {
      actions: { class: classActions, race: raceActionsData },
      classes: classesData,
      race: { racialTraits: racialTraitsData },
      options: { feat: featOptionsData, class: classOptionsData },
      inventory: inventoryData
    } = characterData

    const features: Feature[] = []

    // TO make this some global ID generator?
    let fakeIDIncrement = 1
    const fakeIDBase = 1000000

    // Create external effects
    const effect: FeatureSource = FeatureSource.Effect

    // IMPORTANT NOTE: If we ever remove one of these, we'll have to keep it's fake ID base or itll break all saved URLs
    features.push(new Feature({ name: 'Advantage this turn', id: fakeIDBase + fakeIDIncrement++ }, character, effect))
    features.push(new Feature({ name: 'Advantage on next attack', id: fakeIDBase + fakeIDIncrement++ }, character, effect))
    features.push(new Feature({ name: 'Bless', id: fakeIDBase + fakeIDIncrement++ }, character, effect))
    features.push(new Feature({ name: 'Bardic Inspiration die', id: fakeIDBase + fakeIDIncrement++ }, character, effect))
    features.push(new Feature({ name: 'Haste', id: fakeIDBase + fakeIDIncrement++ }, character, effect))
    features.push(new Feature({ name: 'Perkins crit', id: fakeIDBase + fakeIDIncrement++ }, character, effect))
    features.push(new Feature({ name: 'Heroic Inspiration', id: fakeIDBase + fakeIDIncrement++ }, character, effect))
    features.push(new Feature({ name: 'Bonus Actions first', id: fakeIDBase + fakeIDIncrement++ }, character, effect))

    // Disabling this for now, it conflicts with the new level steppers
    //    const critSmiteId = fakeIDBase + fakeIDIncrement++
    // if (spells.some((spell) => spell.name === 'Divine Smite')) {
    //   features.push(new Feature({ name: 'Divine Smite on crit', id: critSmiteId }, character, effect))
    // }
    const attackIDsToIgnore = attackActions.map((action) => action.id)

    // Weapons

    if (weapons.find((weapon) => weapon.type === 'Lance')) {
      features.push(new Feature({ name: 'Mounted (Lance)', id: fakeIDBase + fakeIDIncrement++ }, character, FeatureSource.FightingStyle))
    }

    // Weapon Masteries
    for (const mastery of weaponMasteries) {
      features.push(new Feature(mastery, character, FeatureSource.WeaponMastery))
    }

    // Ammo
    for (const ammo of ammunition) {
      features.push(new Feature(ammo, character, FeatureSource.Ammunition))
    }

    // Fighting Styles
    for (const fightingStyle of fightingStyles) {
      if (fightingStyle.name === 'Unarmed Fighting') {
        const unarmedFightingFeatures = this.createUnarmedFightingFeatures(fightingStyle, character, fakeIDBase)
        features.push(...unarmedFightingFeatures)
      } else {
        const name = fightingStyle.name
        features.push(new Feature({ name, id: fightingStyle.id }, character, FeatureSource.FightingStyle))
      }
    }

    // Metamagic Adept
    for (const option of characterData.options.feat) {
      if (option.definition.name.includes('Spell')) {
        const definition = { ...option.definition, name: `Metamagic: ${option.definition.name}` }
        const feature = new Feature(definition, character, FeatureSource.Class, attackIDsToIgnore, classOptionsData)
        features.push(feature)
      }
    }

    // Class Actions
    for (const action of classActions) {
      const feature = new Feature(action, character, FeatureSource.Class, attackIDsToIgnore, classOptionsData)
      features.push(feature)

      // Create leveled smites if needed
      if (action.name === 'Divine Smite') {
        const smiteBaseId = fakeIDBase + feature.id

        const uofFeatureName = 'Divine Smite (Undead or Fiend)'
        if (!features.some((feature) => feature.name === uofFeatureName)) {
          const uofAction = { name: uofFeatureName, id: smiteBaseId }
          const smite = new Feature(uofAction, character, FeatureSource.Class, attackIDsToIgnore, classOptionsData)
          features.push(smite)
        }

        const minSmiteLevel = 1
        const maxSmiteLevel = 4 // Can only go to 5d8

        this.createUpcastSmiteFeatures(smiteBaseId, action, minSmiteLevel, maxSmiteLevel, features, FeatureSource.Class, character, true)
      }

      // Create 2 and 3 ki version of some monk skills
      if (['Focused Aim', 'Sharpen the Blade'].includes(action.name)) {
        for (let ki = 2; ki <= 3; ki++) {
          const focusedAim: Dictionary = { ...action, kiPoints: ki, id: fakeIDBase + feature.id + ki }
          features.push(new Feature(focusedAim, character, FeatureSource.Class, attackIDsToIgnore, classOptionsData))
        }
      }
    }

    // Grab subclass features too
    for (const characterClass of classesData) {
      for (const feature of characterClass.definition.classFeatures) {
        const newFeature = new Feature(feature, character, FeatureSource.Class, attackIDsToIgnore, classOptionsData)
        if (newFeature.isBuff) features.push(newFeature)
      }

      const subclassDefinition = characterClass.subclassDefinition
      if (subclassDefinition) {
        for (const feature of subclassDefinition.classFeatures) {
          const newFeature = new Feature(feature, character, FeatureSource.Class, attackIDsToIgnore, classOptionsData)
          if (newFeature.isBuff) {
            features.push(newFeature)

            if (newFeature.name === 'Assassinate') {
              const name = `${newFeature.name}: Surprised`

              const id = fakeIDBase + newFeature.id + 1
              const surprised = new Feature({ name, id }, character, FeatureSource.Class, attackIDsToIgnore, classOptionsData)
              features.push(surprised)
            }
          }
        }
      }
    }

    // Grab racial traits
    for (const trait of racialTraitsData) {
      const newFeature = new Feature(trait.definition, character, FeatureSource.RacialTrait, attackIDsToIgnore)
      if (newFeature.isBuff) features.push(newFeature)
    }

    // Racial Actions (2024)
    for (const racialRaction of raceActionsData) {
      const feature = new Feature(racialRaction, character, FeatureSource.RacialTrait, attackIDsToIgnore)
      if (feature.isBuff) features.push(feature)
    }

    // Next do spells (for hex, smites, hunters mark, etc)
    const spellsToRemove: Spell[] = []
    for (const spell of spells) {
      // DDB bug workaround
      if (spell.name === 'Thunderous Smite' || spell.name === 'Staggering Smite') {
        spell.higherLevelDice = Dice.Create(1, 6)
      }

      if (spell.name.includes('Smite') && spell.higherLevelDice) {
        if (spell.name === 'Divine Smite') {
          const smiteBaseId = fakeIDBase + spell.id

          const uofFeatureName = 'Divine Smite (Undead or Fiend)'
          const uofAction = { name: uofFeatureName, id: smiteBaseId + 1001 }
          if (!features.some((feature) => feature.name === uofFeatureName)) {
            const smite = new Feature(uofAction, character, FeatureSource.Class, attackIDsToIgnore, classOptionsData)
            features.push(smite)
          }
        }

        const smiteBaseId = fakeIDBase + spell.id
        const minSpellLevel = spell.level
        const maxSpellLevel = character.highestLevelSpellOrPactMagicSlot()

        const showLevelBadge = true
        this.createUpcastSmiteFeatures(smiteBaseId, spell, minSpellLevel, maxSpellLevel, features, FeatureSource.Spell, character, showLevelBadge)

        spellsToRemove.push(spell)
      } else {
        if (FeatureParserSpellEffects.supportsUpcasting(spell)) {
          const maxSpellLevel = character.highestLevelSpellOrPactMagicSlot()

          for (let level = spell.level; level <= maxSpellLevel; level++) {
            spell.upcastLevel = level
            const feature = new Feature(spell, character, FeatureSource.Spell)
            feature.levelInfo = new FeatureLevelInfo(level, spell.level, maxSpellLevel, spell.id)
            if (feature.isBuff) features.push(feature)
            if (!feature.effects.doNotPruneSpellSource) spellsToRemove.push(spell)
          }
        } else {
          const feature = new Feature(spell, character, FeatureSource.Spell)

          if (feature.isBuff) {
            features.push(feature)
            if (!feature.effects.doNotPruneSpellSource) spellsToRemove.push(spell)

            if (feature.name === 'Booming Blade') {
              const bbAction = { name: 'Booming Blade: Target Moves', id: fakeIDBase + feature.id + 1 }
              const bbMove = new Feature(bbAction, character, FeatureSource.Spell)
              features.push(bbMove)
            }
          }
        }
      }

      if (spell.name === 'Toll the Dead') {
        const name = 'Toll the Dead: Injured'
        const id = fakeIDBase + spell.id + 1
        const tollTheDead = new Feature({ name, id }, character, FeatureSource.Spell)
        features.push(tollTheDead)
      }
    }

    // If a spell is just a buff, remove it from the spell list
    spellIDsToPrune.push(...spellsToRemove.map((spell) => spell.id))

    // Grab specific Feats, like Sharpshooter and GWM
    for (const feat of feats) {
      const action = {
        name: feat.name,
        activation: Activation.None(),
        id: feat.id,
        requiresConcentration: false,
        snippet: '',
        option: feat.optionDefinition
      }

      if (action) {
        const source: FeatureSource = feat.isFightingStyle ? FeatureSource.FightingStyle : FeatureSource.Feat
        const feature = new Feature(action, character, source, attackIDsToIgnore, featOptionsData)
        if (feature.isBuff) features.push(feature)
      }
    }

    // TODO - look at other data.options.class stuff that AREN'T invocations.
    const classOptions = this.loadChosenClassOptions(classOptionsData)
    for (const classOption of classOptions) {
      if (FIGHTING_STYLES.includes(classOption.name)) continue
      const feature = new Feature(classOption, character, FeatureSource.ClassOption, attackIDsToIgnore)
      features.push(feature)
    }

    for (const inventoryItemData of inventoryData) {
      const definitionData = inventoryItemData.definition
      const itemFeature = new Feature(definitionData, character, FeatureSource.Item, attackIDsToIgnore)
      if (itemFeature.isBuff) features.push(itemFeature)
    }

    // Filter out duplicate features based on ID
    return features
      .filter((feature, index, self) => index === self.findIndex((f) => f.id === feature.id))
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  static createUpcastSmiteFeatures(
    baseId: number,
    action: Dictionary,
    minLevel: number,
    maxLevel: number,
    features: Feature[],
    source: FeatureSource,
    character: Character,
    showLevelBadge: boolean = false
  ) {
    const highestLevelSlot = character.highestLevelSpellOrPactMagicSlot()
    const maxSlot = Math.min(highestLevelSlot, maxLevel)

    for (let level = minLevel; level <= maxSlot; level++) {
      const newSmiteAction = { ...action }
      newSmiteAction.upcastLevel = level
      newSmiteAction.id = baseId + level
      const leveledSmite = new Feature(newSmiteAction, character, source)

      if (showLevelBadge) leveledSmite.levelInfo = new FeatureLevelInfo(level, minLevel, maxSlot, action.id)
      if (features.some((feature) => feature.id === leveledSmite.id)) continue
      features.push(leveledSmite)
    }
  }

  static createUnarmedFightingFeatures(fightingStyle: FightingStyle, character: Character, fakeIDBase: number): Feature[] {
    const source = FeatureSource.FightingStyle
    const baseId = fakeIDBase + fightingStyle.id
    const uuFighting = new Feature({ name: 'Unarmed Fighting (Unarmed)', id: baseId + 1 }, character, source)
    const auFighting = new Feature({ name: 'Unarmed Fighting (Armed)', id: baseId + 2 }, character, source)
    const ufGrapple = new Feature({ name: 'Unarmed Fighting: Grapple Damage', id: baseId + 3 }, character, source)
    return [uuFighting, auFighting, ufGrapple]
  }

  static loadChosenClassOptions(classOptions: Dictionary[]): Dictionary[] {
    const ENTITY_TYPE_ID = 258900837 // TODO - this may not be right, but works for invocations at least
    return classOptions.filter((classOption) => classOption.definition.entityTypeId === ENTITY_TYPE_ID).map((classOption) => classOption.definition)
  }
}
