import React, { useState, useEffect } from 'react'
import { DprTab, MainNavBar } from './MainNavBar'
import { Box, Heading, VStack, Text, HStack, Avatar, Icon, IconButton } from '@chakra-ui/react'
import { TrackPageView, PAGE_ANALYZE } from '../Common/AppAnalytics'
import { handleCharacterButtonClick } from '../Common/Handlers'
import { useNavigate } from 'react-router-dom'
import { URLEntryBar } from '../Common/UIComponents'
import { useLocalStorage } from '../Common/useLocalStorage'
import { RECENT_CHARACTERS_KEY } from '../Common/Constants'
import { ShareDataInterface } from '../Common/Interfaces'
import { FaUser } from 'react-icons/fa'
import { CloseIcon } from '@chakra-ui/icons'

// NEXT: When a character is loaded on the character tab whatever page, THEN store in local storage
// That way, no matter how it loads, if it's successful, it shows up here.
// We need some sort of local storage fake stub for this to work. So… maybe when a character is loaded,
// we store the fake stub data that we would normally make for a share link in local storage?
// We probably want a different tighter smaller stub card that just shows icon, name, and class info

export function AnalyzePage() {
  const { items, removeItem } = useLocalStorage<ShareDataInterface>(RECENT_CHARACTERS_KEY)
  const [urlFieldValue, setUrlFieldValue] = useState('')
  const navigate = useNavigate()
  function handleButtonClick() {
    handleCharacterButtonClick(navigate, urlFieldValue)
  }

  // Add this to monitor items changes and trigger re-renders
  useEffect(() => {
    // Component will re-render whenever items changes
    // You can add additional logic here if needed
  }, [items])

  TrackPageView(PAGE_ANALYZE)

  const [recentUrls, setRecentUrls] = useState<string[]>(() => {
    const storedUrls = localStorage.getItem('recentUrls')
    return storedUrls ? JSON.parse(storedUrls) : []
  })

  function updateButtonClick() {
    handleButtonClick()
    if (urlFieldValue.trim()) {
      const updatedUrls = [urlFieldValue, ...recentUrls.filter((url) => url !== urlFieldValue)].slice(0, 10)
      setRecentUrls(updatedUrls)
      localStorage.setItem('recentUrls', JSON.stringify(updatedUrls))
    }
  }

  console.log('items', items)

  const element = (
    <VStack
      spacing={2}
      px={4}
      py={2}
      display="flex"
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      textAlign={'center'}
    >
      <Heading size="sm" color="blue.200">
        Analyze Your Character
      </Heading>
      <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
        Theory craft and explore the damage of your D&D 5e characters.
      </Text>
      <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
        Enter your D&D Beyond character URL to get started.
      </Text>
      <Box px={'8'} width={'100%'}>
        <URLEntryBar urlFieldData={[urlFieldValue, setUrlFieldValue]} onButtonClick={updateButtonClick} />
      </Box>

      {items.length > 0 && (
        <Box mt={4}>
          <Heading size="sm" mb={2} color="blue.200">
            Recent Characters
          </Heading>
          <VStack spacing={2} align="stretch" width="80vw">
            {items.map((item) => (
              <Box
                key={item.cid}
                p={2}
                borderRadius="md"
                borderWidth="1px"
                borderColor="gray.700"
                bg="gray.800"
                _hover={{ bg: 'gray.700', cursor: 'pointer' }}
                onClick={() => navigate(`/c/${item.cid}`)}
              >
                <HStack justify="space-between">
                  <HStack>
                    <IconButton
                      aria-label="Remove character"
                      icon={<CloseIcon />}
                      size="sm"
                      variant="ghost"
                      onClick={(e) => {
                        e.stopPropagation()
                        removeItem?.(item)
                      }}
                    />
                    <Box mr={2}>{item.avatarUrl ? <Avatar src={item.avatarUrl} size="sm" /> : <Icon as={FaUser} boxSize={8} />}</Box>
                    <VStack align="start" spacing={0}>
                      <Text fontWeight="bold">{item.name}</Text>
                      {item.classNames && (
                        <Text fontSize="sm" color="gray.400" textAlign={'left'}>
                          {item.classNames}
                        </Text>
                      )}
                    </VStack>
                  </HStack>
                </HStack>
              </Box>
            ))}
          </VStack>
        </Box>
      )}
    </VStack>
  )

  return <MainNavBar tab={DprTab.ANALYZE}>{element}</MainNavBar>
}
