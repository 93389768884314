import React, { ElementType } from 'react'

import { HStack, Spacer, ButtonGroup, IconButton, Flex, SkeletonText, Icon, CloseButton } from '@chakra-ui/react'
import { InputGroup, Input, InputRightElement } from '@chakra-ui/react'
import { Td, Box, Text, Heading } from '@chakra-ui/react'
import { Character } from '../DDB/Character'
import { DiceCollection } from '../DDB/Dice'
import { Spell } from '../DDB/Spell'
import { SpellDescriptionBox } from './DescriptionElementFromSpell'
import { FiSearch } from 'react-icons/fi'
import { DownloadIcon, RepeatIcon } from '@chakra-ui/icons'
import { FiPrinter } from 'react-icons/fi'
import { BoldFlexSpan } from '../Common/UIComponents'
import { Dispatcher } from '../Common/Types'

export function LevelListItem({ level, value }: { level: number | string; value: React.ReactNode }) {
  return (
    <Box key={`level-${level}-${value}`}>
      <Flex>
        <BoldFlexSpan ps={1} pe={1}>
          L{level}:
        </BoldFlexSpan>{' '}
        {value}
      </Flex>
    </Box>
  )
}

// Common title component for attribute sections
export function AttributeTitle({ children }: { children: React.ReactNode }) {
  return (
    <Flex fontSize={'md'} className="font-md" fontWeight="bold" pe={1}>
      {children}
    </Flex>
  )
}

export function BoldLabel({ label, children, hidden = false }: { label?: string; children: React.ReactNode; hidden?: boolean }) {
  if (hidden) return null
  return (
    <Flex>
      {label && <BoldFlexSpan pe={1}>{label}:</BoldFlexSpan>}
      {children}
    </Flex>
  )
}

function downloadSpells(spells: Spell[]) {
  if (spells) {
    const spellJson = spells.map((spell) => spell.rpgCardJson())
    const finalString = JSON.stringify(spellJson, null, 2)
    const blob = new Blob([String(finalString)], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'spells.json'
    a.click()
    URL.revokeObjectURL(url)
  }
}

export const SpellDescriptionCell = React.memo(function SpellDescriptionCell({
  spell,
  innerPad,
  sx
}: {
  spell?: Spell
  innerPad: number
  sx: { borderWidth: string; borderStyle: string; borderColor: string }
}) {
  return (
    <Td
      whiteSpace="normal"
      wordBreak="break-word"
      verticalAlign="top"
      pe={innerPad}
      ps={2}
      sx={sx}
      py={2}
      colSpan={spell ? (spell.hasSpellCardEffect() ? 1 : 2) : 1}
      width="100%"
      minWidth="300px" // Add a minimum width to ensure the cell is wider
    >
      {spell ? <SpellDescriptionBox spell={spell} /> : <SkeletonText noOfLines={5} spacing="4" startColor="gray.200" endColor="gray.300" />}
    </Td>
  )
})

export const SpellEffectCell = React.memo(function SpellEffectCell({
  spell,
  character,
  innerPad,
  sx
}: {
  spell: Spell
  character: Character | undefined
  innerPad: number
  sx: { borderWidth: string; borderStyle: string; borderColor: string }
}) {
  const cellProps: CellProps = {
    verticalAlign: 'top' as const,
    pe: innerPad,
    ps: innerPad,
    sx,
    colSpan: 1
  }

  if (spell.hasUpcastDamageDice() || spell.hasUpcastHealingDice()) {
    return <UpcastCell spell={spell} character={character} cellProps={cellProps} />
  }

  if (spell.isHealingSpell() && !spell.hasUpcastHealingDice()) {
    return <SimpleHealingCell spell={spell} cellProps={cellProps} />
  }

  if (spell.level === 0 && spell.isDamageSpell()) {
    return <CantripDamageCell spell={spell} cellProps={cellProps} />
  }

  if (spell.level > 0 && spell.isDamageSpell() && spell.spellAttributes().effectCountLabel) {
    return <LeveledDamageCell spell={spell} character={character} cellProps={cellProps} />
  }

  return null
})

interface CellProps {
  verticalAlign: 'top'
  pe: number
  ps: number
  sx: { borderWidth: string; borderStyle: string; borderColor: string }
  colSpan: number
}

function UpcastCell({ spell, character, cellProps }: { spell: Spell; character: Character | undefined; cellProps: CellProps }) {
  const isUpcastDamage = spell.hasUpcastDamageDice()
  const title = isUpcastDamage ? 'Damage' : 'Healing'
  const mod = isUpcastDamage ? 1 : 0
  const minLevel = spell.level - mod
  const maxLevel = character!.highestLevelSpellOrPactMagicSlot() - mod

  return (
    <Td fontSize="md" className="font-md" py={2} {...cellProps}>
      <AttributeTitle>{title}</AttributeTitle>
      {spell
        .spellAttributes()
        .diceCollectionsForLevels.filter((_: DiceCollection, level: number) => level >= minLevel && level <= maxLevel)
        .map((diceCollection: DiceCollection, index: number) => (
          <LevelListItem key={index} level={spell.level + index} value={diceCollection.displayString()} />
        ))}
    </Td>
  )
}
function SimpleHealingCell({ spell, cellProps }: { spell: Spell; cellProps: CellProps }) {
  return (
    <Td py={2} {...cellProps}>
      <AttributeTitle>Healing</AttributeTitle>
      <Flex ps={1}>{spell.healingDice?.diceString()}</Flex>
    </Td>
  )
}
function CantripDamageCell({ spell, cellProps }: { spell: Spell; cellProps: CellProps }) {
  return (
    <Td py={2} {...cellProps}>
      <AttributeTitle>Damage</AttributeTitle>
      <Flex ps={1}>{spell.attackAction().diceStringForLevel(0)}</Flex>
      {spell.spellAttributes().effectCountLabel && (
        <>
          <Flex fontSize="md" className="font-md" fontWeight="bold" pe={1} pt={2}>
            {spell.effectCountLabel}
          </Flex>
          <Flex ps={1}>{spell.effectCount}</Flex>
        </>
      )}
    </Td>
  )
}
function LeveledDamageCell({ spell, character, cellProps }: { spell: Spell; character: Character | undefined; cellProps: CellProps }) {
  return (
    <Td py={2} {...cellProps}>
      <AttributeTitle>Damage</AttributeTitle>
      <Flex ps={1}>{spell.attackAction().diceStringForLevel(0)}</Flex>
      <Flex fontSize="md" className="font-md" fontWeight="bold" pe={1} pt={2}>
        {spell.effectCountLabel}
      </Flex>
      {Array.from({ length: character!.highestLevelSpellOrPactMagicSlot() }, (_, i) => i + 1).map((level) => (
        <LevelListItem key={level} level={level} value={spell.effectCountForLevel(level)} />
      ))}
    </Td>
  )
}

export const SpellInfoCell = React.memo(function SpellInfoCell({
  edgePad,
  innerPad,
  sx,
  spell
}: {
  edgePad: number
  innerPad: number
  sx: { borderWidth: string; borderStyle: string; borderColor: string }
  spell?: Spell
}) {
  const spellInfo = React.useMemo(() => spell?.spellSheetInfo(), [spell?.id])

  return (
    <Td
      key={`spell-info-${spell?.id}`}
      whiteSpace="normal"
      wordBreak="break-word"
      verticalAlign="top"
      minW={'14rem'}
      ps={edgePad}
      pe={innerPad}
      sx={sx}
      py={2}
    >
      <Flex wrap="wrap" align="center">
        <Flex fontWeight="bold" fontSize="lg" className="font-lg">
          {spell ? spell.name : <SkeletonText skeletonHeight={'1rem'} noOfLines={1} w="10rem" startColor="gray.300" endColor="gray.400" />}
        </Flex>
      </Flex>
      <Flex fontSize="md" className="font-md" fontStyle={'italic'} pb={4}>
        {spell ? (
          spell.levelString()
        ) : (
          <SkeletonText noOfLines={1} skeletonHeight={'0.8rem'} pt={1} w="60%" isLoaded={false} startColor="gray.200" endColor="gray.300" />
        )}
      </Flex>

      {spellInfo ? (
        spellInfo.map(({ label, value, hidden }) =>
          hidden ? null : (
            <Flex key={label} fontSize="sm" className="font-sm">
              <BoldLabel key={label} label={label}>
                {value}
              </BoldLabel>
            </Flex>
          )
        )
      ) : (
        <Box>
          <SkeletonText w="5rem" startColor="gray.100" endColor="gray.300" />
          <SkeletonText pt={2} w="5rem" startColor="gray.300" endColor="gray.100" />
        </Box>
      )}
    </Td>
  )
})

export function URLInputControlStrip({
  urlFieldData,
  handleLoadClick,
  handleReloadClick,
  handleXClick,
  character,
  handlePrint,
  isLoading
}: {
  urlFieldData: [string, Dispatcher<string>]
  handleLoadClick: () => void
  handleReloadClick: () => void
  handleXClick: () => void
  character: Character | undefined
  handlePrint: () => void
  isLoading: boolean
}) {
  const [urlFieldValue, setUrlFieldValue] = urlFieldData
  const internalHandleXClick = () => {
    setUrlFieldValue('')
    handleXClick()
  }

  const showDownloads = false
  return (
    <HStack pb={4} key="spell-list-input-strip">
      <Spacer />
      <InputGroup maxW="xl">
        <Input
          key="spell-list-input"
          id="spell-list-input"
          placeholder="DDB URL"
          size={'md'}
          maxW={'xl'}
          value={urlFieldValue}
          onChange={(event) => setUrlFieldValue(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') handleLoadClick()
          }}
        />
        {urlFieldValue && (
          <InputRightElement>
            <CloseButton
              aria-label="Clear input"
              size="sm"
              onClick={internalHandleXClick}
              variant="unstyled"
              _hover={{ bg: 'transparent' }}
              color="gray.500"
              _active={{ color: 'gray.700' }}
            />
          </InputRightElement>
        )}
      </InputGroup>
      <ButtonGroup variant="tertiary" spacing="1">
        <IconButton
          icon={<Icon as={FiSearch as ElementType} />}
          aria-label="Search"
          isRound
          onClick={handleLoadClick}
          boxSize={{ base: '5', md: '6' }}
        />

        {character && (
          <IconButton
            icon={<Icon as={RepeatIcon as ElementType} />}
            aria-label="Reload"
            isRound
            isLoading={isLoading}
            onClick={() => handleReloadClick()}
            boxSize={{ base: '5', md: '6' }}
          />
        )}
        {showDownloads && character && (
          <IconButton
            icon={<Icon as={DownloadIcon as ElementType} />}
            aria-label="Download"
            isRound
            onClick={() => downloadSpells(character?.spellsSortedByLevelAndName(true))}
            boxSize={{ base: '5', md: '6' }}
          />
        )}
        {character && (
          <IconButton
            icon={<Icon as={FiPrinter as ElementType} />}
            aria-label="Print"
            isRound
            onClick={() => handlePrint()}
            boxSize={{ base: '5', md: '6' }}
          />
        )}
        {/* <HStack spacing={2}>
          <Checkbox
            colorScheme={'blue'}
            isChecked={onlyPrepared}
            onChange={(e) => setOnlyPrepared(e.target.checked)}
          />
          <Text fontSize="md">Only prepared spells</Text>
        </HStack> */}
      </ButtonGroup>
      <Spacer />
    </HStack>
  )
}

export function InstructionsHeader() {
  return (
    <Box>
      <Heading as="h1" size="md" textAlign={'center'} pb={4}>
        Spell Sheets (Pre-Release Alpha)
      </Heading>
      <Text pb={4} textAlign={'center'}>
        To print out a spell sheet: enter a DDB character URL, load the spell list, and hit the printer button.
      </Text>
      {/* <Text pb={4} textAlign={'center'}>
        Even more experimental! To generate printable cards: enter a DDB character URL, load the spell list, hit the download icon to download
        spells.json, visit https://rpg-cards.vercel.app, then load the downloaded file.
      </Text> */}
    </Box>
  )
}

export function OctoBoxValue({ title, value }: { title: string; value: string | number }) {
  if (value === undefined) return null
  return (
    <Flex direction={'column'} alignItems="center">
      <OctoBox minW="130">{value}</OctoBox>
      <Box mt={1} fontSize="sm" className="font-sm">
        {title}
      </Box>
    </Flex>
  )
}

export function OctoBox({
  minW,
  maxW = '30vw',
  className = undefined,
  children
}: {
  minW?: string
  maxW?: string
  className?: string
  children: React.ReactNode
}) {
  const cornerX = 10
  const cornerY = 6
  const octagonClipPath = `polygon(
        ${cornerY}px 0%, 
        calc(100% - ${cornerY}px) 0%, 
        100% ${cornerX}px, 
        100% calc(100% - ${cornerX}px), 
        calc(100% - ${cornerY}px) 100%, 
        ${cornerY}px 100%, 
        0% calc(100% - ${cornerX}px), 
        0% ${cornerX}px
    )`

  return (
    <Box position="relative" display="inline-block" my={1} minW={minW} width="max-content" maxW={maxW} className={className} mx={2}>
      <Box
        position="absolute"
        width="calc(100% + 4px)"
        left="-2px"
        height="calc(100% + 4px)"
        bg="gray.700"
        alignItems={'center'}
        clipPath={octagonClipPath}
      />
      <Box position="relative" bg="white" color="blue.800" top={'2px'} px={4} py={1} clipPath={octagonClipPath}>
        <Box textAlign="center" fontSize="lg" className="font-lg">
          {children}
        </Box>
      </Box>
    </Box>
  )
}
