import React from 'react'
import { IconButton, HStack, Badge, Text, useNumberInput } from '@chakra-ui/react'

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Dispatcher } from '../../Common/Types'

export function NumberStepper({
  label = '',
  numberData,
  showControls,
  range,
  minW,
  ps = 0,
  larger = false,
  isDisabled = false
}: {
  label?: string
  numberData: [number, Dispatcher<number>]
  showControls: boolean
  range: [number, number]
  minW?: string
  ps?: number
  larger?: boolean
  isDisabled?: boolean
}) {
  const [min, max] = range
  const [level, onLevelChange] = numberData
  const { value, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    value: level.toString(),
    min: min,
    max: max,
    precision: 0,
    onChange: (valueAsString, valueAsNumber) => {
      if (!isDisabled) onLevelChange(valueAsNumber)
    }
  })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()

  const buttonProps = larger
    ? {
        color: 'white',
        bg: 'transparent',
        size: 'sm' as const,
        minW: '28px'
      }
    : {
        color: 'white',
        bg: 'transparent',
        size: 'xs' as const,
        minW: '20px'
      }

  // Handle events to prevent bubbling so tapping the text doesn't check the box
  const stopPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }
  return (
    <HStack justifyContent={'center'} opacity={isDisabled ? 0.6 : 1} ps={ps}>
      <Badge
        color="white"
        bgColor="brand"
        borderRadius="full"
        px={0}
        py={0}
        maxH={larger ? '24px' : '20px'}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {showControls && <IconButton {...dec} {...buttonProps} aria-label="Decrement" icon={<ChevronLeftIcon fontSize={'large'} />} />}
        <Text
          px={showControls ? 0 : 2}
          userSelect="none"
          onClick={stopPropagation}
          onKeyDown={stopPropagation}
          onMouseDown={stopPropagation}
          onMouseUp={stopPropagation}
          minW={minW}
          textAlign={minW ? 'center' : undefined}
          fontSize={larger ? 'md' : 'xs'}
          fontWeight={larger ? 'normal' : 'bold'}
        >
          {label}
          {value}
        </Text>
        {showControls && <IconButton {...inc} {...buttonProps} aria-label="Increment" icon={<ChevronRightIcon fontSize={'large'} />} />}
      </Badge>
    </HStack>
  )
}
