import { ActionParser } from './ActionParser'
import { AttackAction } from '../AttackAction'
import { Dice } from '../Dice'

import { Action } from '../CharacterJSON/Actions'

export class ActionParserUnarmedStrike {
  static createActions(parser: ActionParser, classActions: Action[], fakeIDBaseList: number[], attackActions: AttackAction[]) {
    const character = parser.character
    const unarmedStrike = 'Unarmed Strike'
    let unarmedStrikeDice = Dice.flatAmountDie(1)

    if (character.hasFeatNamed('Tavern Brawler')) {
      unarmedStrikeDice = Dice.Create(1, 4)
    }

    if (character.classLevel('Monk') > 0) {
      unarmedStrikeDice = Dice.Create(1, character.monkDieSize())
    }

    if (classActions.some((classAction) => classAction.name === 'Bardic Damage')) {
      const bardicDieSize = character.bardicInspirationDieSize()
      const dice = Dice.Create(1, bardicDieSize)
      const modMultiplier = 1
      const dexOverride = true
      const fakeId = fakeIDBaseList[0] + fakeIDBaseList[1]++
      const bardicDamageAction = parser.createUnarmedStrikeAttackAction(
        character,
        unarmedStrike,
        parser.action,
        fakeId,
        dice,
        modMultiplier,
        dexOverride
      )

      bardicDamageAction.name = 'Bardic Damage'

      attackActions.push(bardicDamageAction)
    }

    const fakeId = fakeIDBaseList[0] + fakeIDBaseList[1]++
    const unarmedStrikeAction = parser.createUnarmedStrikeAttackAction(character, unarmedStrike, parser.action, fakeId, unarmedStrikeDice)

    attackActions.push(unarmedStrikeAction)

    if (character.classLevel('Monk') > 0) {
      const fakeId = fakeIDBaseList[0] + fakeIDBaseList[1]++
      const unarmedStrikeBonusAction = parser.createUnarmedStrikeAttackAction(character, unarmedStrike, parser.bonusAction, fakeId, unarmedStrikeDice)
      attackActions.push(unarmedStrikeBonusAction)
    }
  }
}
