import { put, head } from '@vercel/blob'
import { customAlphabet } from 'nanoid'
import { CacheDirectory } from '../Common/Types'

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
const generateNanoId = customAlphabet(alphabet, 5)

export class BlobCache {
  static isKeyNumeric(key: string): boolean {
    return /^\d+$/.test(key)
  }

  static async generateNewID(directory: CacheDirectory): Promise<string> {
    // Check if this nano ID already exists
    let pathAlreadyExists = true
    let key = ''
    let path = ''

    while (pathAlreadyExists) {
      do {
        key = generateNanoId()
      } while (this.isKeyNumeric(key))

      path = `${directory}/${key}.json`
      try {
        await head(path)
      } catch (error) {
        pathAlreadyExists = false
        // Once we get an 'error', we know this path doesn't exist
      }
    }

    if (key.length === 0) {
      console.error('Failed to generate a nanoid key')
    }

    return key
  }

  static path(directory: CacheDirectory, key: string) {
    return `${directory}/${key}.json`
  }

  // pass in Json.strigify(data)
  static async put(directory: CacheDirectory, key: string, blob: string) {
    const buffer = Buffer.from(blob)
    const path = this.path(directory, key)
    await put(path, buffer, {
      contentType: 'application/json',
      access: 'public',
      addRandomSuffix: false
    })
  }

  // // call JSON.parse(data)
  static async get(directory: CacheDirectory, key: string): Promise<string | undefined> {
    try {
      const path = this.path(directory, key)
      const headResult = await head(path)
      const blobData = await fetch(headResult.url)
      return await blobData.text()
    } catch (error) {
      return undefined
    }
  }
}
