import React from 'react'
import { DprTab, MainNavBar } from './MainNavBar'
import { Box, Heading } from '@chakra-ui/react'
import BlogPost from './BlogPost'

export function PostListPage() {
  return (
    <MainNavBar tab={DprTab.POSTS}>
      <Box px={8} py={2} alignItems="center" justifyContent="center" width="100%" textAlign={'center'}>
        <Heading size="sm" color="blue.200">
          Blog Posts
        </Heading>
        <BlogPost page="BuildList" />
      </Box>
    </MainNavBar>
  )
}
