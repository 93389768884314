import { ActionParser } from './ActionParser'
import { AttackAction } from '../AttackAction'
import { Utility } from '../../Common/Utility'
import { Range } from '../CharacterJSON/Range'
import { Dice } from '../Dice'
import { Activation } from '../CharacterJSON/Activation'
import { Spell } from '../Spell'
import { Weapon } from '../Weapon'
import { Dictionary } from '../../Common/Types'

export class ActionParserSpell {
  static parse(
    parser: ActionParser,
    spell: Spell,
    spellIDsToPrune: number[],
    fakeIDBaseList: number[],
    attackActions: AttackAction[],
    weapons: Weapon[]
  ) {
    const ba = parser.bonusAction
    const character = parser.character

    const name = spell.name

    let spellAction = undefined
    let spellIdToPrune: number | undefined = spell.id

    if (name === 'Spiritual Weapon') {
      const toHit = spell.attackMod
      const displayName = spell.displayName
      const attributes = { range: spell.range, type: 'Spell Attack' }
      const abilityMod = spell.spellcastingModifier
      const maxSpellLevel = character.highestLevelSpellOrPactMagicSlot()
      let lastDieCount = 0
      for (let level = spell.level; level <= maxSpellLevel; level++) {
        const diceCount = Math.floor(level / 2)
        if (diceCount === lastDieCount) continue
        lastDieCount = diceCount
        const dice = Dice.Create(diceCount, 8, abilityMod)

        const id = spell.id + level + fakeIDBaseList[0] + fakeIDBaseList[1]++
        const action = new AttackAction(id, displayName, toHit, dice, { ...attributes }, ba)
        action.id = id
        action.attributes.id = action.id
        action.turnLevel = level
        attackActions.push(action)
      }
    } else if (name === 'Shadow Blade') {
      const maxLevel = Math.min(character.highestLevelSpellOrPactMagicSlot(), 7)
      let lastDieCount = 0
      for (let level = spell.level; level <= maxLevel; level += 1) {
        const dieCount = 2 + Math.floor((level - 1) / 2)
        if (dieCount === lastDieCount) continue
        lastDieCount = dieCount

        const action = this.createShadowBlades(parser, spell, Dice.Create(dieCount, 8), parser.action)
        action.id = action.id + fakeIDBaseList[0] + fakeIDBaseList[1]++
        action.attributes.id = action.id
        action.turnLevel = level
        attackActions.push(action)

        const opportunityAttack = ActionParserSpell.createOpportunityAttack(action, fakeIDBaseList)
        attackActions.push(opportunityAttack)
      }
    } else if (name === 'Flame Blade') {
      const maxLevel = Math.min(character.highestLevelSpellOrPactMagicSlot(), 7)
      let lastDieCount = 0
      for (let level = spell.level; level <= maxLevel; level += 1) {
        const dieCount = 2 + (level - 1)
        if (dieCount === lastDieCount) continue
        lastDieCount = dieCount

        const action = this.createFlameBlades(parser, spell, Dice.Create(dieCount, 6), parser.action)
        action.id = action.id + fakeIDBaseList[0] + fakeIDBaseList[1]++
        action.attributes.id = action.id
        action.turnLevel = level
        attackActions.push(action)

        const opportunityAttack = ActionParserSpell.createOpportunityAttack(action, fakeIDBaseList)
        attackActions.push(opportunityAttack)
      }
    } else if (name === 'Magic Stone') {
      spellAction = spell.attackAction()
      spellAction.attributes.range = Range.makeWeaponRange(60)
      spellAction.activation = Activation.Action()
    } else if (name === 'Shillelagh') {
      spellAction = spell.attackAction()
      spellAction.activation = Activation.Action()

      if (weapons.find((weapon) => weapon.name === 'Quarterstaff')) spellAction.attributes.is2024Polearm = true

      // TODO - later give this the properties and weapon masteries of the parent Club or Quarterstaff

      const opportunityAttack = ActionParserSpell.createOpportunityAttack(spellAction, fakeIDBaseList)
      attackActions.push(opportunityAttack)
    } else if (name === 'Flame Arrows') {
      // This is a buff, remove it
    } else if (name === 'Holy Weapon') {
      const name = 'Dismiss Holy Weapon'

      const range = Range.makeWeaponRange(30)

      const saveDCAbility = Utility.shortNameForAbility('constitution')
      const spellSaveDC = spell.saveDc
      const activation = Activation.BonusAction()
      const dice = Dice.Create(4, 8)
      const attributes = {
        range: range,
        type: 'Weapon',
        requiresSavingThrow: true,
        requiresAttackRoll: false,
        saveDcAbility: saveDCAbility,
        saveDcValue: spellSaveDC
      }

      spellAction = new AttackAction(spell.id, name, 0, dice, attributes, activation)
    } else if (name === 'Wrath of Nature') {
      const spellName = 'Wrath of Nature: Trees'
      const saveDCAbility = Utility.shortNameForAbility('dexterity')
      const range = Range.makeSpellAttackRange(60)
      const spellSaveDC = spell.saveDc
      const activation = Activation.Action()
      const dice = Dice.Create(4, 6)
      const attributes = {
        range: range,
        type: 'Spell Attack',
        requiresSavingThrow: true,
        requiresAttackRoll: false,
        saveDcAbility: saveDCAbility,
        saveDcValue: spellSaveDC,
        freeAction: true
      }
      spellAction = new AttackAction(spell.id, spellName, 0, dice, attributes, activation)
      attackActions.push(spellAction)

      const rocksAttributes = {
        range,
        type: 'Spell Attack',
        requiresAttackRoll: true
      }
      const rocksActivation = Activation.BonusAction()
      const rocksDice = Dice.Create(3, 8)
      const rocksName = 'Wrath of Nature: Rocks'
      spellAction = new AttackAction(spell.id + 1, rocksName, spell.attackMod, rocksDice, rocksAttributes, rocksActivation)
    } else {
      spellIdToPrune = undefined
    }

    if (spellAction) {
      attackActions.push(spellAction)
    }

    if (spellIdToPrune) {
      spellIDsToPrune.push(spellIdToPrune)
    }
  }

  static createCommonBladeAttack(parser: ActionParser, spell: Spell, die: Dice, activation: Activation, attributes: Dictionary): AttackAction {
    const character = parser.character
    const abilityMod = character.finesseAbilityMod()
    const toHit = character.finesseToHitMod()
    die.fixedValue += abilityMod
    return new AttackAction(spell.id, spell.name, toHit, die, attributes, activation)
  }

  static createShadowBlades(parser: ActionParser, spell: Spell, die: Dice, activation: Activation): AttackAction {
    const attackRange = Range.makeWeaponRange(20, 60)
    const attributes = parser.thrownfinesseWeaponAttrs(attackRange)
    return this.createCommonBladeAttack(parser, spell, die, activation, attributes)
  }

  static createFlameBlades(parser: ActionParser, spell: Spell, die: Dice, activation: Activation): AttackAction {
    const attackRange = Range.makeWeaponRange(5)
    const attributes = parser.lightFinesseWeaponAttrs(attackRange)
    return this.createCommonBladeAttack(parser, spell, die, activation, attributes)
  }

  static createOpportunityAttack(action: AttackAction, fakeIDBaseList: number[]): AttackAction {
    const opportunityAttack = action.copy()
    // Deep copy if needed, for safety.
    if (action.attributes.displayAttributes) opportunityAttack.attributes.displayAttributes = [...action.attributes.displayAttributes]

    const fakeId = fakeIDBaseList[0] + fakeIDBaseList[1]++
    opportunityAttack.name = action.name
    const opportunityAttackId = 1000 * fakeId + action.id
    opportunityAttack.configureAsOpportunityAttack(opportunityAttackId)
    return opportunityAttack
  }
}
