import { ABILITY, Class } from '../Common/Types'
import { CharacterParser } from './CharacterParser'
import { Dice } from './Dice'
import { CharacterFeat } from './Feat'
import { Campaign } from './CharacterJSON/Campaign'
import { Inventory } from './CharacterJSON/Inventory'
import { Spell } from './Spell'
import { AttackAction } from './AttackAction'
import { Feature } from './Feature'
import { Dictionary } from '../Common/Types'
import { TurnAction } from './TurnAction'
import { DiceCollection } from './Dice'
import { CheckMap, NumberMap, ShareDataInterface } from '../Common/Interfaces'
import { Utility } from '../Common/Utility'
import { Activation } from './CharacterJSON/Activation'

// This is meant to be a nice read-only interface for the UI and app logic to use.
export class Character {
  protected characterParser: CharacterParser

  constructor(characterParser: CharacterParser) {
    this.characterParser = characterParser
  }

  totalLevel(): number {
    return this.characterParser.totalLevel
  }

  proficiencyBonus(): number {
    return this.characterParser.proficiencyBonus
  }

  modifierForAbilityIndex(index: number) {
    return this.characterParser.modifierForAbilityIndex(index)
  }

  highestMentalAbilityModifier(): number {
    const mentalAbilities: ABILITY[] = ['intelligence', 'wisdom', 'charisma']
    return Math.max(...mentalAbilities.map((ability) => this.modifierForAbility(ability)))
  }

  abilityScore(ability: ABILITY): number {
    const index = Utility.indexOfAbility(ability)
    return this.abilityScoreForIndex(index)
  }

  abilityScoreForIndex(index: number): number {
    return this.characterParser.abilityScores[index]
  }

  saveDcForAbility(ability: ABILITY): number {
    const pb = this.proficiencyBonus()
    return 8 + pb + this.modifierForAbility(ability)
  }

  attackModForAbility(ability: ABILITY): number {
    const pb = this.proficiencyBonus()
    return pb + this.modifierForAbility(ability)
  }

  is2024Class(theClass: Class): boolean {
    return this.characterParser.is2024Class(theClass)
  }

  classLevel(theClass: Class): number {
    return this.characterParser.classLevel(theClass)
  }

  modifierForAbility(ability: ABILITY): number {
    return this.characterParser.modifierForAbility(ability)
  }

  scoreForAbility(ability: ABILITY): number {
    return this.characterParser.scoreForAbility(ability)
  }

  highestLevelSpellSlot(): number {
    return this.characterParser.highestLevelSpellSlot
  }

  warlockSpellLevel(): number {
    const warlockLevel = this.characterParser.classLevel('Warlock')
    return warlockLevel === 0 ? 0 : Math.min(5, Math.ceil(warlockLevel / 2))
  }

  highestLevelSpellOrPactMagicSlot() {
    return Math.max(this.highestLevelSpellSlot(), this.warlockSpellLevel())
  }

  rageBonusDamage(): number {
    const barbarianLevel = this.characterParser.classLevel('Barbarian')
    if (barbarianLevel >= 16) return 4
    if (barbarianLevel >= 9) return 3
    return 2
  }

  clericDivineSparkDieCount(): number {
    const clericLevel = this.characterParser.classLevel('Cleric')
    if (clericLevel >= 18) return 4
    if (clericLevel >= 13) return 3
    if (clericLevel >= 7) return 2
    return 1
  }

  superiorityDice(): Dice {
    const fighterLevel = this.characterParser.classLevel('Fighter')
    if (fighterLevel >= 18) return Dice.Create(1, 12)
    if (fighterLevel >= 10) return Dice.Create(1, 10)
    if (fighterLevel >= 3) return Dice.Create(1, 8)

    console.error('Trying to get superiority dice for non-fighter')
    return Dice.Create(0, 0)
  }

  baseDieSizeForLevel(level: number): number {
    if (level === 0) return 0
    if (level <= 4) return 4
    if (level <= 10) return 6
    if (level <= 16) return 8

    return 10
  }

  sporeDamageDie(): Dice {
    const druidLevel = this.characterParser.classLevel('Druid')
    if (druidLevel >= 14) return Dice.Create(1, 10)
    if (druidLevel >= 10) return Dice.Create(1, 8)
    if (druidLevel >= 6) return Dice.Create(1, 6)

    return Dice.Create(1, 4)
  }

  subclassNames(): string[] {
    return this.characterParser.classes.map((c) => (c.subclassName ? c.subclassName : '')).filter((name) => name !== '')
  }

  name(): string {
    return this.characterParser.name
  }

  id(): number {
    return this.characterParser.id
  }

  race(): string {
    return this.characterParser.race
  }

  hasFightingStyleNamed(fightingStyle: string): boolean {
    return this.characterParser.fightingStyles.map((style) => style.name).includes(fightingStyle)
  }

  hasFeatNamed(featName: string): boolean {
    return this.featNames().includes(featName)
  }

  featNamed(featName: string): CharacterFeat | undefined {
    return this.characterParser.feats.find((feat) => feat.name === featName)
  }

  soulknifeEnergyDieSize(characterClass: Class): number {
    // 2024 Soulknife. Scales the same as the old one.
    return this.psionicEnergyDieSize(characterClass)
  }

  psionicEnergyDieSize(characterClass: Class): number {
    const level = this.characterParser.classLevel(characterClass)
    return 2 + this.baseDieSizeForLevel(level)
  }

  cantripDieCount(): number {
    return Math.floor((this.characterParser.totalLevel + 1) / 6) + 1
  }

  maxKiPointsForMonkSpell() {
    const level = this.characterParser.classLevel('Monk')
    if (level >= 17) return 6
    if (level >= 13) return 5
    if (level >= 9) return 4
    if (level >= 5) return 3

    return 0
  }

  monkDieSize(): number {
    const level: number = this.characterParser.classLevel('Monk')
    const is2024Class: boolean = this.characterParser.is2024Class('Monk')
    return this.baseDieSizeForLevel(level) + (is2024Class ? 2 : 0)
  }

  bardicInspirationDieSize(level?: number): number {
    if (!level) {
      level = this.characterParser.classLevel('Bard')
    }
    return level === 20 ? 12 : Math.floor(level / 5) * 2 + 6
  }

  hasShieldEquipped(): boolean {
    return this.characterParser.hasShieldEquipped
  }

  hasWeaponsEquipped(): boolean {
    return this.characterParser.weapons.length > 0
  }

  hasOffHand(): boolean {
    return this.characterParser.hasOffHand
  }

  isShieldEquipped(inventoryData: Inventory[]): boolean {
    for (const inventoryItemData of inventoryData) {
      const { definition, equipped } = inventoryItemData
      const { type, baseArmorName } = definition
      if ((type === 'Shield' || baseArmorName === 'Shield') && equipped === true) {
        return true
      }
    }
    return false
  }

  strength(): number {
    return this.characterParser.abilityScores[0]
  }

  dexterity(): number {
    return this.characterParser.abilityScores[1]
  }

  constitution(): number {
    return this.characterParser.abilityScores[2]
  }

  intelligence(): number {
    return this.characterParser.abilityScores[3]
  }

  wisdom(): number {
    return this.characterParser.abilityScores[4]
  }

  charisma(): number {
    return this.characterParser.abilityScores[5]
  }

  isSingleClassWarlock(): boolean {
    return this.classNames().length === 1 && this.characterParser.classLevel('Warlock') > 0
  }

  classesAndSpellcastingAbilities(): Dictionary[] {
    const pb = this.proficiencyBonus()
    return this.characterParser.classes.map((characterClass) => {
      const spellcastingAbilityId = characterClass.spellCastingAbility
      if (!spellcastingAbilityId) {
        return [characterClass.className, characterClass.subclassName || '', characterClass.level, 'None', 0]
      }

      const { className, level } = characterClass
      const fullAbility = spellcastingAbilityId ? Utility.toTitleCase(Utility.nameForAbilityID(spellcastingAbilityId)!) : 'None'
      const score = this.characterParser.abilityScores[spellcastingAbilityId]
      const spellSaveDC = 8 + pb + Utility.modifierForScore(score)
      const spellAttack = pb + Utility.modifierForScore(score)
      const ability = Utility.shortNameForAbilityID(spellcastingAbilityId)
      const subclass = characterClass.subclassName ? characterClass.subclassName : ''

      return { className, level, spellSaveDC, spellAttack, subclass, ability, fullAbility }
    })
  }

  classNames(): [string, number][] {
    return this.characterParser.classes.map((characterClass) => {
      return [`${characterClass.classDisplayString()}`, characterClass.level]
    })
  }

  weaponMasteries() {
    return this.characterParser.weaponMasteries
  }

  fightingStyles() {
    return this.characterParser.fightingStyles
  }

  allSpells(): Spell[] {
    return this.characterParser.allSpells
  }

  spells(): Spell[] {
    return this.characterParser.preparedSpells
  }

  nonEffectSpells(): Spell[] {
    return this.spells().filter((spell) => !spell.isEffect)
  }

  attackRollSpells(): Spell[] {
    return this.nonEffectSpells().filter((spell) => spell.requiresAttackRoll)
  }

  damagingSpells(): Spell[] {
    return this.nonEffectSpells().filter((spell) => spell.isDamageSpell())
  }

  attackActions() {
    return this.characterParser.attackActions
  }

  damagingSpellActions(): AttackAction[] {
    return this.damagingSpells().map((spell) => spell.attackAction())
  }

  warcasterReactionSpellAttackActions(): AttackAction[] {
    if (!this.hasFeatNamed('War Caster')) return []

    return this.damagingSpells()
      .filter((spell) => spell.requiresAttackRoll)
      .map((spell) => spell.attackAction())
      .map((action) => {
        action.activation = Activation.Reaction()
        action.id = action.id + 100000
        action.attributes.id = action.id
        action.attributes.displayAttributes.push('Opportunity Attack')
        return action
      })
  }

  attackBonusActions(): AttackAction[] {
    return this.characterParser.extraAttackActions.filter((action) => action.activation.usesBonusAction())
  }

  attackReactions(): AttackAction[] {
    return this.characterParser.extraAttackActions.filter((action) => action.activation.usesReaction())
  }

  avatarUrl(): string {
    return this.characterParser.avatarUrl
  }

  abilityScores(): number[] {
    return this.characterParser.abilityScores
  }

  features(): Feature[] {
    return this.characterParser.features
  }

  attackCount(): number {
    return this.characterParser.attackCount
  }

  campaign(): Campaign | undefined {
    return this.characterParser.campaign
  }

  classAnalyticsNames(): Dictionary[] {
    return this.characterParser.classes.map((characterClass) => {
      return {
        className: characterClass.className,
        subclassName: characterClass.subclassName ?? '',
        level: characterClass.level
      }
    })
  }

  ///////
  // For Display
  //////

  totalDamageStringForTurns(turnActions: TurnAction[]): string {
    if (!turnActions) {
      return ''
    }

    return this.totalDamageForTurns(turnActions).displayString()
  }

  totalDamageForTurns(turnActions: TurnAction[]): DiceCollection {
    const allDiceCollection = new DiceCollection()
    for (const turnAction of turnActions) {
      const dice = turnAction.autoCrit
        ? turnAction.critDiceCollectionForLevel(turnAction.attackAction?.turnLevel || 0)
        : turnAction.allDamageDiceCollection(turnAction.attackAction?.turnLevel || 0)
      allDiceCollection.addDiceCollection(dice)
    }

    return allDiceCollection
  }

  totalCritDiceStringForTurns(turnActions: TurnAction[]): string {
    if (!turnActions) {
      return ''
    }

    return this.totalCritDiceForTurns(turnActions).displayString()
  }

  totalCritDiceForTurns(turnActions: TurnAction[]): DiceCollection {
    const allDiceCollection = new DiceCollection()
    for (const turnAction of turnActions) {
      const dice = turnAction.critDiceCollectionForLevel(turnAction.attackAction?.turnLevel || 0)
      allDiceCollection.addDiceCollection(dice)
    }
    return allDiceCollection
  }

  classNamesForDisplay(stripLevelForSingleClass: boolean = false): string {
    const names: [string, number][] = this.classNames()
    if (stripLevelForSingleClass && names.length === 1) {
      return names[0][0]
    }
    return names
      .map((className: [string, number]) => `${className[0]} ${className[1]}`)
      .join(' / ')
      .replace('  ', ' ')
  }

  featNames(): string[] {
    return this.characterParser.feats.map((feat) =>
      feat.name.includes('Weapon Mastery') ? `Weapon Mastery – ${feat.friendlySubtypeName}` : feat.name
    )
  }

  featNamesForDisplay(): string {
    if (!this.featNames()) {
      return ''
    }

    const sortedFeatNames = this.featNames().sort((a, b) => a.localeCompare(b))
    return sortedFeatNames.join(', ')
  }

  testDescriptorForDisplay(): string {
    return this.classNamesForDisplay() + ' – ' + this.featNamesForDisplay()
  }

  defaultEnabledFeatureMap(): CheckMap {
    const racialTraits: CheckMap = {}

    for (const feature of this.features()) {
      if (feature.defaultEnabled) {
        racialTraits[feature.id] = true
      }
    }

    return racialTraits
  }

  dprcalcURL() {
    const slug: string | undefined = this.shareData()?.slug
    return 'http://dprcalc.com/c/' + (slug ? slug : this.id())
  }

  ddbURL(): string {
    return 'https://www.dndbeyond.com/characters/' + this.id()
  }

  baseShareDateForRecents(): ShareDataInterface {
    return {
      cid: this.id(),
      race: this.race(),
      name: this.name(),
      classNames: this.classNamesForDisplay(),
      checkedFeatures: [],
      actionIdList: [],
      advantageOverrides: new NumberMap(),
      targetAC: 10,
      elvenAccuracy: false,
      avatarUrl: this.avatarUrl(),
      dpr: '0'
    }
  }

  shareData(): ShareDataInterface | undefined {
    return this.characterParser.shareData
  }

  characterJsonForDevelopment(): Dictionary {
    if (Utility.isDevelopment) {
      return this.characterParser.characterJsonForDevelopment!.prunedData()
    }
    return {}
  }

  loadedFromCache(): boolean {
    return this.characterParser.loadedFromCache
  }

  finesseAbilityMod() {
    return Math.max(this.modifierForAbility('strength'), this.modifierForAbility('dexterity'))
  }

  finesseToHitMod() {
    return this.finesseAbilityMod() + this.proficiencyBonus()
  }

  spellsSortedByLevelAndName(loadAllSpells: boolean = false): Spell[] {
    const spells = loadAllSpells ? this.allSpells() : this.spells()
    return spells.sort((a, b) => {
      return a.level === b.level ? a.name.localeCompare(b.name) : a.level - b.level
    })
  }

  warlockSpellSlotCount() {
    const warlockLevel = this.characterParser.classLevel('Warlock')
    if (warlockLevel === 0) return 0
    if (warlockLevel === 1) return 1
    if (warlockLevel <= 10) return 2
    if (warlockLevel <= 16) return 3
    return 4
  }

  warlockSpellSlots(): number[] {
    const level = this.warlockSpellLevel()
    const slots = new Array(level).fill(0)
    slots[level - 1] = this.warlockSpellSlotCount()
    return slots
  }

  spellSlots(): number[] {
    return this.characterParser.spellSlots()
  }
}
