// React
import React from 'react'
import { useState } from 'react'

// Chakra-UI React Components
import { Table, Tbody, Tr, Td, TableContainer, VStack } from '@chakra-ui/react'
import { Flex, Card, SimpleGrid, Checkbox } from '@chakra-ui/react'

import { Box } from '@chakra-ui/react'

// DPR
import { sumDamageArrays } from '../Data/ChartData'
import { CreatureType } from '../DDB/CharacterJSON/Creature' // TOFDO Later abstract this out
import { AccordionView, TableHeader } from '../Common/UIComponents'
import { CharacterHeader } from './CharacterHeader'
import { Dispatcher } from '../Common/Types'
import { Character } from '../DDB/Character'
import { ActionLevelMap, CheckMap, NumberMap } from '../Common/Interfaces'
import { TurnEngine } from '../Data/TurnEngine'
import { Utility } from '../Common/Utility'
import { DamageACChart } from './Charts'
import { DamagePercentilesChart } from './Charts'
import { calculateDamagesDataForAC } from '../Data/ChartData'
import { ActivationType } from '../DDB/CharacterJSON/Activation'
import { FeatureSource } from '../DDB/FeatureSource'
import { ShareDataInterface } from '../Common/Interfaces'
import { WeaponProperty } from '../DDB/WeaponAttributes'

// Controls
import { FeatureBox } from './Controls/FeatureBox'
import { FeatureTableSection } from './Controls/FeatureTable'
import { AttackTable } from './Controls/AttackTable'
import { TurnTable } from './Controls/TurnTable'
import { SpellTable } from './Controls/SpellTable'
import { Weapon } from '../DDB/Weapon'
import { ACLookup } from './ACLookup'
import { useEffect } from 'react'
import { SkeletonText } from '@chakra-ui/react'
import { CharacterLoader } from '../Loader/CharacterLoader'

export const TABLE_HEADER_COLOR: string = 'gray.400'

function clamp(value: number, range: number[]): number {
  const min: number = range[0]
  const max: number = range[range.length - 1]
  return Math.min(Math.max(value, min), max)
}

export function MiniCharacterSheet({ id }: { id: string }) {
  const [character, setCharacter] = useState<Character | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    setIsLoading(true)
    setError(null)

    CharacterLoader.load(id)
      .then((char) => {
        setCharacter(char)
        setIsLoading(false)
      })
      .catch((err) => {
        setError(err)
        setIsLoading(false)
      })
  }, [id])

  if (isLoading) {
    return (
      // TODO check this out with slow loading, it's just random for now.
      <Box padding="2" boxShadow="md" borderRadius="md" height={300} bgColor="gray.800">
        <Flex gap={2} width="100%" height={284}>
          <Box flex="1" borderRadius="md" bgColor="gray.900" p={2}>
            <SkeletonText mt="2" noOfLines={6} skeletonHeight={4} spacing="3" />
          </Box>
          <Box flex="1" bgColor="gray.900" borderRadius={'xl'} p={2}>
            <SkeletonText mt="2" noOfLines={3} skeletonHeight={4} spacing="2" />
          </Box>
          <Box flex="3" bgColor="gray.900" borderRadius={'xl'} p={2}>
            <SkeletonText mt="2" noOfLines={8} skeletonHeight={4} spacing="2" />
          </Box>
        </Flex>
      </Box>
    )
  }

  if (error || !character) {
    return (
      <Box padding="4" boxShadow="md" borderRadius="md">
        Failed to load character
      </Box>
    )
  }

  if (!character.loadedFromCache()) {
    console.error('Mini character card not loaded from cache:', character.id())
  }
  return <CharacterSheet character={character} reloadState={[false, () => {}]} isPrimary={true} isMini={true} />
}

// Character Sheet
export function CharacterSheet({
  character,
  reloadState,
  isPrimary,
  isMini = false
}: {
  character: Character
  reloadState: [boolean, Dispatcher<boolean>]
  isPrimary: boolean
  isMini?: boolean
}) {
  const [reload, setReload] = reloadState

  const loadedShareData = character.shareData()
  const initialFeatures: CheckMap = isPrimary && loadedShareData ? loadedShareData.checkedFeatures : character.defaultEnabledFeatureMap()

  const initialActionIDs = isPrimary && loadedShareData ? loadedShareData.actionIdList : []
  const initialAdventageOverrides = isPrimary && loadedShareData ? NumberMap.Create(loadedShareData.advantageOverrides) : new NumberMap()
  const [damageRiders, setDamageRiders] = useState<boolean>(false)
  const [checkedFeatures, setCheckedFeatures] = useState<CheckMap>(initialFeatures)
  const [actionIdList, setActionIdList] = useState<ActionLevelMap[]>(initialActionIDs)
  const [advantageOverrides, setaAvantageOverrides] = useState<NumberMap>(initialAdventageOverrides)

  // "What if" weapons
  const [actionWeapons, setActionWeapons] = useState<Weapon[]>([])
  const [bonusActionWeapons, setBonusActionWeapons] = useState<Weapon[]>([])

  // AC
  const acs = Utility.range(5, 25)
  const initialAC = ACLookup.averageACForLevel(character.totalLevel())
  let providedAC = isPrimary && loadedShareData ? loadedShareData.targetAC : initialAC
  providedAC = clamp(providedAC, acs)
  const [targetAC, setTargetAC] = useState(providedAC)

  // TODO make this common with AddWeaponButton/AttackRow code!
  // We want the weapons so we can PERSIST them, but we use the Actions…
  //   const simAttackActions = actionWeapons.map((weapon) => AttackAction.CreateFromWeapon(weapon, character))
  //   const baSimAttackActions = bonusActionWeapons.map((weapon) => AttackAction.CreateFromWeapon(weapon, character, true))

  const turnEngine = new TurnEngine(character, checkedFeatures, actionIdList, advantageOverrides, acs, targetAC, damageRiders)

  //         , [
  //     ...simAttackActions,
  //     ...baSimAttackActions
  //   ])

  const attacks = character.attackActions()
  const bonusAttacks = character.attackBonusActions()
  const reactionAttacks = character.attackReactions()

  const spells = character.damagingSpellActions().sort((a, b) => a.name.localeCompare(b.name))
  const reactionAttackSpells = character.warcasterReactionSpellAttackActions()
  let allFeatures = character.features()

  if (isMini) {
    // Filter out features that don't match any of the initialFeatures
    const enabledFeatureIds = Object.entries(initialFeatures)
      .filter(([, isEnabled]) => isEnabled)
      .map(([id]) => Number(id))

    // Keep the level steppers in.
    // However… TODO: find the groups and if they are *ALL* disabled, remove it. Like… a multi-smite build.
    allFeatures = allFeatures.filter((feature) => feature.levelInfo || enabledFeatureIds.includes(feature.id))
  }

  const fixedSpellLevel = character.isSingleClassWarlock() ? character.warlockSpellLevel() : 0

  const wsAttacks = attacks.filter((attack) => attack.attributes.type === 'Companion' && attack.attributes.creatureType === CreatureType.WildShape)
  const wildShapeIds = wsAttacks.map((attack) => attack.id)
  const nonWSAttacks = attacks.filter((attack) => !wildShapeIds.includes(attack.id))

  if (attacks.length !== wsAttacks.length + nonWSAttacks.length) {
    console.error('Attack count mismatch:', attacks.length, wsAttacks.length, nonWSAttacks.length)
  }

  const racialFeatures = allFeatures.filter((feature) => feature.featureSource === FeatureSource.RacialTrait)
  const classBuffFeatures = allFeatures.filter((feature) => feature.featureSource === FeatureSource.Class && feature.isBuff)

  const weaponMasteryFeatures = allFeatures.filter((feature) => feature.featureSource === FeatureSource.WeaponMastery && feature.isBuff)

  const spellFeatures = allFeatures.filter((feature) => feature.featureSource === FeatureSource.Spell)
  const fightingStyleFeatures = allFeatures.filter((feature) => feature.featureSource === FeatureSource.FightingStyle && feature.isBuff)

  const effectFeatures = allFeatures.filter((feature) => feature.featureSource === FeatureSource.Effect)
  const ammoFeatures = allFeatures.filter((feature) => feature.featureSource === FeatureSource.Ammunition)
  const featFeatures = allFeatures.filter((feature) => feature.featureSource === FeatureSource.Feat)
  const itemFeatures = allFeatures.filter((feature) => feature.featureSource === FeatureSource.Item)
  const classOptionFeatures = allFeatures.filter((feature) => feature.featureSource === FeatureSource.ClassOption && feature.isBuff)
  const nonBuffFeatures = allFeatures.filter((feature) => !feature.isBuff)

  //   Just a sanity check…
  const featureArrays = [
    weaponMasteryFeatures,
    classBuffFeatures,
    ammoFeatures,
    racialFeatures,
    fightingStyleFeatures,
    spellFeatures,
    effectFeatures,
    featFeatures,
    classOptionFeatures,
    itemFeatures,
    nonBuffFeatures
  ]

  const totalFeatureCount = featureArrays.reduce((total, features) => total + features.length, 0)
  if (totalFeatureCount !== allFeatures.length) {
    const featureNames = featureArrays.flat().map((feature) => feature.name)
    const misisngFeatures = allFeatures.filter((feature) => !featureNames.includes(feature.name))
    console.error(
      'Feature count mismatch. Missing: ',
      misisngFeatures.map((feature) => feature.name)
    )
  }
  //End sanity check

  const featureSections = [
    { title: 'Weapon Masteries ★', features: weaponMasteryFeatures },
    { title: 'Class Features', features: classBuffFeatures },
    { title: 'Class Options', features: classOptionFeatures },
    { title: 'Feat Effects', features: featFeatures },
    { title: 'Item Effects', features: itemFeatures },
    { title: 'Ammunition Effects', features: ammoFeatures },
    { title: 'Spell Effects', features: spellFeatures },
    { title: 'Racial Traits', features: racialFeatures },
    { title: 'Fighting Styles', features: fightingStyleFeatures },
    { title: 'External Effects', features: isMini ? [] : effectFeatures }
  ]

  const padding = 2
  const showRange = true

  const allTurns = turnEngine.allTurnActionsAndDamageRiders
  const elvenAccuracy = turnEngine.elvenAccuracy
  const damageData = calculateDamagesDataForAC(allTurns, acs, targetAC)

  const accordionViewProps = { width: '100%', height: '250px' }
  const isSmall = false //useBreakpointValue({ base: 'sm', lg: 'md' }) == 'sm' // TODO this is too slow

  const statsAccordion = (
    <AccordionView title={'Additional Statistics'} {...accordionViewProps}>
      <DamagePercentilesChart damageData={damageData} chartAC={targetAC} requiresSave={turnEngine.anyActionRequiresSave} />
    </AccordionView>
  )

  const shareData: ShareDataInterface = {
    cid: character.id(),
    race: character.race(),
    name: character.name(),
    classNames: character.classNamesForDisplay(),
    checkedFeatures,
    actionIdList,
    advantageOverrides,
    targetAC,
    elvenAccuracy,
    avatarUrl: character.avatarUrl(),
    dpr: sumDamageArrays(allTurns, [targetAC])[0].toFixed(2)
  }

  const characterHeader = (
    <CharacterHeader
      character={character}
      turnEngine={turnEngine}
      acs={acs}
      shareData={shareData}
      acState={[targetAC, setTargetAC]}
      reloadState={[reload, setReload]}
      isMini={isMini}
    />
  )

  const damageACChart = (
    <DamageACChart
      numberMaps={turnEngine.averageDamageMaps}
      acState={[targetAC, setTargetAC]}
      requiresSave={turnEngine.anyActionRequiresSave}
      isMini={isMini}
    />
  )

  const damageRiderButton = (
    <Checkbox isChecked={damageRiders} colorScheme="blue" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDamageRiders(e.target.checked)}>
      Damage Riders
    </Checkbox>
  )

  // Attack table data
  const wildShapeTableTitle = 'Wildshape Attacks'
  const bonusActionTableTitle = 'Bonus Action Attacks'
  const spellsTableTitle = 'Spells'
  const reactionTableTitle = 'Reaction Attacks'
  const warcasterTableTitle = 'Warcaster Attacks'

  const hasOffhand = character.hasOffHand()
  const hasBonusActions = bonusAttacks.length > 0
  const offhandString = 'Bonus action attacks require offhand weapons marked as “Dual Wield” in DDB.'
  const aTooltip = !hasOffhand && !hasBonusActions ? offhandString : null
  const baTooltip = !hasOffhand && hasBonusActions ? offhandString : null

  const action = ActivationType.ACTION
  const ba = ActivationType.BONUS_ACTION

  const attackTableTitle = character.attackCount() > 1 ? `Attacks (${character.attackCount()} per turn)` : 'Attacks'

  const attackMap = [
    {
      title: attackTableTitle,
      attackList: nonWSAttacks,
      activationType: action,
      tooltip: aTooltip,
      weapons: actionWeapons,
      setWeapons: setActionWeapons
    },
    { title: wildShapeTableTitle, attackList: wsAttacks, activationType: action, tooltip: null },
    {
      title: bonusActionTableTitle,
      attackList: bonusAttacks,
      activationType: ba,
      tooltip: baTooltip,
      aTooltip,
      weapons: bonusActionWeapons,
      setWeapons: setBonusActionWeapons,
      filterProperties: ['Heavy', 'Two-Handed'] as WeaponProperty[]
    }
  ]

  const reactionMap = [
    {
      title: reactionTableTitle,
      attackList: reactionAttacks,
      activationType: ActivationType.REACTION,
      tooltip: null,
      weapons: actionWeapons,
      setWeapons: setActionWeapons
    }
  ]

  // Attack table data
  const attackTableSections = attackMap.map(({ title, attackList, activationType, tooltip, weapons, setWeapons, filterProperties }) => (
    <AttackTable
      character={character}
      key={title}
      title={title}
      attacks={attackList}
      activationType={activationType}
      showRange={showRange}
      tooltip={tooltip}
      selectedWeaponState={[weapons || [], setWeapons || (() => {})]}
      actionIdState={[actionIdList, setActionIdList]}
      filterProperties={filterProperties}
    />
  ))

  const reactionTableSections = reactionMap.map(({ title, attackList, activationType, tooltip, weapons, setWeapons }) => (
    <AttackTable
      character={character}
      key={title}
      title={title}
      attacks={attackList}
      activationType={activationType}
      showRange={showRange}
      tooltip={tooltip}
      selectedWeaponState={[weapons || [], setWeapons || (() => {})]}
      actionIdState={[actionIdList, setActionIdList]}
    />
  ))

  const spellTableSection = (
    <SpellTable
      title={spellsTableTitle}
      spells={spells}
      fixedSpellLevel={fixedSpellLevel}
      actionIdState={[actionIdList, setActionIdList]}
      highestLevelSlot={character.highestLevelSpellOrPactMagicSlot()}
      showRange={showRange}
    />
  )

  const warcasterTableSection = reactionAttackSpells.length ? (
    <SpellTable
      title={warcasterTableTitle}
      spells={reactionAttackSpells}
      fixedSpellLevel={fixedSpellLevel}
      actionIdState={[actionIdList, setActionIdList]}
      highestLevelSlot={character.highestLevelSpellOrPactMagicSlot()}
      showRange={showRange}
    />
  ) : undefined

  const turnTable = (
    <TurnTable
      turnEngine={turnEngine}
      actionIdState={[actionIdList, setActionIdList]}
      advantageState={[advantageOverrides, setaAvantageOverrides]}
      targetAC={targetAC}
      isMini={isMini}
    />
  )

  const featuresTable = (
    <Table variant="unstyled" size="sm" sx={{ td: { py: isMini && totalFeatureCount > 6 ? 1 : 2 } }} bgColor={isMini ? 'transparent' : 'gray.900'}>
      {isMini ? <TableHeader pt={2}>Features</TableHeader> : <TableHeader pt={4}>⚠️ Experimental Features</TableHeader>}
      {!isMini && (
        <Tbody>
          <Tr verticalAlign={'top'}>
            <Td py={1}>{damageRiderButton}</Td>
            <Td py={1}>
              <Box p={1} fontSize="sm">
                Once-per-turn effects are calculated for all attacks
              </Box>
            </Td>
          </Tr>
        </Tbody>
      )}
      {featureSections.map((section) => (
        <FeatureTableSection
          key={section.title}
          title={section.title}
          features={section.features}
          checkedFeatureState={[checkedFeatures, setCheckedFeatures]}
          isMini={isMini}
        />
      ))}
    </Table>
  )

  const otherFeatureProps = { width: '100%' }
  const otherFeaturesTable = (
    <AccordionView title={'Other Features'} {...otherFeatureProps}>
      <Table variant="unstyled" size="sm">
        <Tbody>
          {nonBuffFeatures.map((feature) => (
            <Tr key={feature.id}>
              <Td colSpan={2}>
                <FeatureBox feature={feature} ps={2} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </AccordionView>
  )

  if (isMini) {
    return (
      <Card direction={'column'} overflow="hidden" variant="unstyled" borderRadius={'lg'} p={2}>
        <Flex direction={{ base: 'column', md: 'row' }} gap={padding} width="100%">
          <Box flex={{ base: '1', md: '1' }} borderRadius="md" bgColor="gray.900" mb={{ base: 2, md: 0 }}>
            <VStack p={0} spacing={0}>
              {characterHeader}
              <Box px={2}>{damageACChart}</Box>
            </VStack>
          </Box>
          <Box flex={{ base: '1', md: '1' }} bgColor="gray.900" borderRadius={'xl'} mb={{ base: 2, md: 0 }}>
            {featuresTable}
          </Box>
          <Box flex={{ base: '1', md: '3' }} bgColor="gray.900" borderRadius={'xl'}>
            {turnTable}
          </Box>
        </Flex>
      </Card>
    )
  }

  return (
    <SimpleGrid columns={{ sm: 1, lg: 2 }} spacing={padding}>
      <Box>{characterHeader}</Box>
      <Box width="100%" maxH={250} bgColor={'bg.surface'}>
        {damageACChart}
      </Box>
      <Box>
        {isSmall && statsAccordion}
        <TableContainer>
          <Table variant="unstyled" size="sm">
            {attackTableSections}
            {spellTableSection}
            {reactionTableSections}
            {warcasterTableSection}
          </Table>
        </TableContainer>
      </Box>
      <Box p={0}>
        {!isSmall && statsAccordion}
        {turnTable}
        {featuresTable}
        {otherFeaturesTable}
      </Box>
    </SimpleGrid>
  )
}
