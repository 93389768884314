import { Json, SpellcastingId } from '../../Common/Types'
import { Activation } from './Activation'
import { Range } from './Range'
import { Dice } from '../Dice'

export class HigherLevelDefinition {
  level: number
  typeId: number
  dice: Dice | null
  value: number | null
  details: string

  constructor(json: Json) {
    this.level = json.level
    this.typeId = json.typeId
    this.dice = json.dice ? new Dice(json.dice) : null
    this.value = json.value
    this.details = json.details
  }
}

export class AtHigherLevels {
  higherLevelDefinitions: HigherLevelDefinition[]

  constructor(json: Json) {
    this.higherLevelDefinitions = json.higherLevelDefinitions.map((definition: Json) => new HigherLevelDefinition(definition))
  }
}

export class SpellModifier {
  fixedValue?: number
  id: string
  entityId?: number
  entityTypeId?: number
  type: string
  subType: string
  dice: Dice | null
  die: Dice | null
  statId: number | null
  requiresAttunement: boolean
  duration: number | null
  friendlyTypeName: string
  friendlySubtypeName: string
  isGranted: boolean
  value: number | null
  modifierTypeId: number
  modifierSubTypeId: number
  componentId: number
  componentTypeId: number
  count: number
  usePrimaryStat: boolean
  atHigherLevels: AtHigherLevels

  constructor(json: Json) {
    this.fixedValue = json.fixedValue
    this.id = json.id
    this.entityId = json.entityId
    this.entityTypeId = json.entityTypeId
    this.type = json.type
    this.subType = json.subType
    this.dice = json.dice ? new Dice(json.dice) : null
    this.die = json.die
    this.statId = json.statId
    this.requiresAttunement = json.requiresAttunement
    this.duration = json.duration
    this.friendlyTypeName = json.friendlyTypeName
    this.friendlySubtypeName = json.friendlySubtypeName
    this.isGranted = json.isGranted
    this.value = json.value
    this.modifierTypeId = json.modifierTypeId
    this.modifierSubTypeId = json.modifierSubTypeId
    this.componentId = json.componentId
    this.componentTypeId = json.componentTypeId
    this.count = json.count

    this.usePrimaryStat = json.usePrimaryStat

    this.atHigherLevels = new AtHigherLevels(json.atHigherLevels)
  }
}

export class Duration {
  durationInterval: number
  durationUnit: string | null
  durationType: string

  constructor(json: Json) {
    this.durationInterval = json.durationInterval
    this.durationUnit = json.durationUnit
    this.durationType = json.durationType
  }

  private unitString(): string {
    const string = `${this.durationUnit}${this.durationInterval !== 1 ? 's' : ''}`
    return string.toLocaleLowerCase()
  }

  concentrationTimeString(): string | undefined {
    if (this.durationType === 'Concentration') {
      return `Up to ${this.durationInterval} ${this.unitString()}`
    }
    return undefined
  }

  durationTypeString(): string {
    if (this.durationType === 'Instantaneous') return 'Instantaneous'
    if (this.durationType === 'Concentration') {
      return `${this.durationType}, up to ${this.durationInterval} ${this.unitString()}`
    }

    if (this.durationUnit) {
      return `${this.durationInterval} ${this.unitString()}`
    }

    if (this.durationInterval === 0) {
      return 'Instantaneous'
    }

    return `${this.durationInterval}`
  }
}

export class SpellDefinition {
  id: number
  name: string
  level: number
  school: string
  duration: Duration | null
  activation: Activation
  range: Range
  concentration: boolean
  ritual: boolean
  saveDcAbilityId?: number
  attackType?: number
  canCastAtHigherLevel: boolean
  isHomebrew: boolean
  requiresSavingThrow: boolean
  requiresAttackRoll: boolean
  atHigherLevels: AtHigherLevels // TODO
  modifiers: SpellModifier[]
  scaleType: string
  isLegacy: boolean
  description?: string
  componentsDescription?: string
  components?: number[]

  constructor(json: Json, loadDescriptions: boolean = false) {
    this.id = json.id
    this.name = json.name
    this.level = json.level
    this.school = json.school
    this.duration = json.duration ? new Duration(json.duration) : null
    this.activation = new Activation(json.activation)
    this.range = json.range
    this.concentration = json.concentration
    this.ritual = json.ritual
    this.saveDcAbilityId = json.saveDcAbilityId
    this.attackType = json.attackType
    this.canCastAtHigherLevel = json.canCastAtHigherLevel
    this.isHomebrew = json.isHomebrew
    this.requiresSavingThrow = json.requiresSavingThrow
    this.requiresAttackRoll = json.requiresAttackRoll
    this.atHigherLevels = json.atHigherLevels
    this.modifiers = json.modifiers.map((modifier: Json) => new SpellModifier(modifier))
    this.scaleType = json.scaleType
    this.isLegacy = json.isLegacy

    if (loadDescriptions) {
      this.description = json.description
      this.componentsDescription = json.componentsDescription
      this.components = json.components
    }
  }
}

export class SpellList {
  //characterClassId: number // Later this can help us do per-class spell save DCs
  entityTypeId: number
  spells: Spell[]
  spellcastingId: SpellcastingId | undefined

  constructor(json: Json, spellcastingId: SpellcastingId | undefined, loadDescriptions: boolean = false) {
    this.entityTypeId = json.entityTypeId
    this.spellcastingId = spellcastingId
    this.spells = json.spells.map((spell: Json) => new Spell(spell, spellcastingId, loadDescriptions))
  }
}

export class Spell {
  id: number
  entityTypeId: number
  definition: SpellDefinition | null
  definitionId: number
  prepared: boolean
  countsAsKnownSpell: boolean
  usesSpellSlot: boolean
  alwaysPrepared: boolean
  spellCastingAbilityId: SpellcastingId | undefined
  range: Range | null

  activation: Activation | null

  constructor(json: Json, spellcastingId: SpellcastingId | undefined, loadDescriptions: boolean = false) {
    this.id = json.id
    this.entityTypeId = json.entityTypeId
    this.definition = json.definition ? new SpellDefinition(json.definition, loadDescriptions) : null
    this.definitionId = json.definitionId
    this.prepared = json.prepared
    this.countsAsKnownSpell = json.countsAsKnownSpell
    this.usesSpellSlot = json.usesSpellSlot
    this.alwaysPrepared = json.alwaysPrepared

    // Don't override the spell casting ID if the spell says there isn't one!
    if (json.spellCastingAbilityId !== false || spellcastingId !== undefined) {
      this.spellCastingAbilityId = json.spellCastingAbilityId || spellcastingId
    } else {
      this.spellCastingAbilityId = undefined
    }

    if (!this.definition && json.definitionId !== 0) console.log(`Looking at ${this.id}, no definition`, json)

    this.range = json.range ? new Range(json.range) : null
    this.activation = json.activation ? new Activation(json.activation) : null
  }
}

export class SpellGroup {
  class: Spell[]
  feat: Spell[]
  item: Spell[]
  race: Spell[]
  background: Spell[]

  constructor(json: Json, spellcastingId: SpellcastingId | undefined, loadDescriptions: boolean = false) {
    this.class = json.class.map((spell: Json) => new Spell(spell, spellcastingId, loadDescriptions))
    this.feat = json.feat.map((spell: Json) => new Spell(spell, spellcastingId, loadDescriptions))
    this.item = json.item.map((spell: Json) => new Spell(spell, spellcastingId, loadDescriptions))
    this.race = json.race.map((spell: Json) => new Spell(spell, spellcastingId, loadDescriptions))
    if (json.background) this.background = json.background.map((spell: Json) => new Spell(spell, spellcastingId, loadDescriptions))
    else {
      this.background = []
    }
  }
}

export class SpellRules {
  levelSpellSlots: number[][]
  multiClassSpellSlotDivisor?: number

  constructor(json: Json) {
    this.levelSpellSlots = json.levelSpellSlots
    this.multiClassSpellSlotDivisor = json.multiClassSpellSlotDivisor
  }
}
