import React, { ElementType, useMemo, memo, useEffect } from 'react'
import { Avatar, AvatarProps, Box, Icon, Spacer, VStack, IconButton, Button, ButtonGroup, useDisclosure, Flex, HStack } from '@chakra-ui/react'
import { Drawer, DrawerBody, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { DPRCalcLogo } from './CustomIcons'

import { DISCORD_URL, PATREON_URL, RECENT_CHARACTERS_KEY } from '../Common/Constants'
import { IconBrandDiscordFilled, IconBrandPatreonFilled } from '@tabler/icons-react'
import { FiMenu } from 'react-icons/fi'
import { FaCalculator, FaFileContract, FaQuestion, FaUser } from 'react-icons/fa'
import { useLocalStorage } from '../Common/useLocalStorage'
import { ShareDataInterface } from '../Common/Interfaces'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { IconType } from 'react-icons'

export enum DprTab {
  HOME,
  ANALYZE,
  FAQ,
  CHARACTER,
  SPELLS,
  CALCULATOR,
  POSTS,
  DISCORD,
  PATREON
}

const lineProps = {
  content: '""',
  position: 'absolute',
  bottom: '-4px',
  left: -1,
  right: -1,
  height: '2px',
  transition: 'background-color 0.5s ease'
}

const UserIcon = <Icon as={FaUser as ElementType} boxSize={5} width={6} />
const MemoAvatar = memo(function MemoAvatar(props: AvatarProps) {
  return <Avatar loading="eager" transition="opacity 0.3s ease" boxSize={6} size="xs" {...props} />
})
function CachedAvatar({ url }: { url?: string }) {
  // This will only be recreated if the URL changes
  return useMemo(() => {
    if (!url) return UserIcon
    return <MemoAvatar src={url} />
  }, [url])
}

export function MainNavBar({ tab, children }: { tab: DprTab; children: React.ReactNode }) {
  const { items } = useLocalStorage<ShareDataInterface>(RECENT_CHARACTERS_KEY)
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  // Attempt to prefetch images for the avatars
  useEffect(() => {
    items.forEach((item) => {
      if (item.avatarUrl) {
        const img = new Image()
        img.src = item.avatarUrl
      }
    })
  }, [items])

  function localNavigate(url: string) {
    navigate(url)
    onClose()
  }

  function NavItems({ inDrawer = false }: { inDrawer?: boolean }) {
    const baseButtonProps = {
      textColor: 'gray.100',
      fontSize: inDrawer ? 'xl' : 'md',
      justifyContent: inDrawer ? 'flex-start' : 'center',
      width: inDrawer ? '100%' : 'auto'
    }

    function buttonPropsForTab(propTab: DprTab) {
      return {
        isActive: propTab === tab,
        ...baseButtonProps,
        _hover: {
          _after: {
            bg: propTab === tab ? 'blue.300' : 'blue.600'
          }
        },
        _after: {
          bg: propTab === tab ? 'blue.300' : 'transparent',
          ...lineProps
        }
      }
    }
    function AvatarButton({ icon, tab, path, children }: { icon?: IconType; tab: DprTab; path: string; children: React.ReactNode }) {
      return (
        <Button
          leftIcon={inDrawer && icon ? <Icon as={icon as ElementType} /> : undefined}
          onClick={() => localNavigate(path)}
          {...buttonPropsForTab(tab)}
        >
          {children}
        </Button>
      )
    }

    return (
      <Flex direction={inDrawer ? 'column' : 'row'} gap={inDrawer ? 2 : 6} alignItems={inDrawer ? 'flex-start' : 'center'} width={'100%'}>
        <AvatarButton tab={DprTab.HOME} path="/">
          <DPRCalcLogo spacing={inDrawer ? 2 : 1} />
        </AvatarButton>
        <AvatarButton icon={FaCalculator} tab={DprTab.ANALYZE} path="/analyze">
          Analyze
        </AvatarButton>
        {items.length > 0 &&
          (items.length === 1 ? (
            <Button
              leftIcon={<CachedAvatar url={items[0].avatarUrl} />}
              onClick={() => localNavigate(`/c/${items[0].cid}`)}
              {...buttonPropsForTab(DprTab.CHARACTER)}
            >
              <Box ml={inDrawer ? -1 : 0}>{items[0].name}</Box>
            </Button>
          ) : (
            <Menu offset={inDrawer ? undefined : [-13, 8]} placement="bottom-start">
              <MenuButton
                ml={inDrawer ? -1 : 0}
                as={Button}
                leftIcon={<CachedAvatar url={items[0].avatarUrl} />}
                {...buttonPropsForTab(DprTab.CHARACTER)}
              >
                <Flex>
                  <Box>{items[0].name}</Box>
                  <ChevronDownIcon ml={0.5} mt={0.5} />
                </Flex>
              </MenuButton>
              <MenuList>
                {items.map((item) => (
                  <Box
                    key={item.cid}
                    role="menuitem"
                    px={3}
                    py={2}
                    _hover={{ bg: 'blue.600' }}
                    onClick={() => localNavigate(`/c/${item.cid}`)}
                    cursor="pointer"
                  >
                    <HStack>
                      <Box display="flex" alignItems="center">
                        <Box mr={2} display="flex" alignItems="center">
                          <CachedAvatar url={item.avatarUrl} />
                        </Box>
                        <Box fontSize={'md'}>{item.name}</Box>
                      </Box>
                      {/* <IconButton
                        aria-label="Remove"
                        icon={<CloseIcon />}
                        size="xs"
                        variant="ghost"
                        onClick={(e) => {
                          e.stopPropagation() // Prevent triggering MenuItem click
                          removeItem?.(item)
                        }}
                      /> */}
                    </HStack>
                  </Box>
                ))}
              </MenuList>
            </Menu>
          ))}
        <AvatarButton icon={FaFileContract} tab={DprTab.POSTS} path="/posts">
          Posts
        </AvatarButton>
        <AvatarButton icon={FaQuestion} tab={DprTab.FAQ} path="/faq">
          FAQ
        </AvatarButton>

        <Spacer />
        <Button leftIcon={<IconBrandDiscordFilled />} onClick={() => externalNavigate(DISCORD_URL)} {...buttonPropsForTab(DprTab.DISCORD)}>
          Discord
        </Button>
        <Button leftIcon={<IconBrandPatreonFilled />} onClick={() => externalNavigate(PATREON_URL)} {...buttonPropsForTab(DprTab.PATREON)}>
          Patreon
        </Button>
      </Flex>
    )
  }

  return (
    <VStack width="100%">
      <Box borderBottomWidth="1px" bg="bg.surface" position="relative" width="100vw" py={2} px={4} minH={14} display="flex" alignItems="center">
        <IconButton display={{ base: 'flex', md: 'none' }} aria-label="Open menu" icon={<FiMenu />} onClick={onOpen} variant="ghost" />
        <Box display={{ base: 'flex', md: 'none' }} flexGrow={1} alignItems="center">
          <DPRCalcLogo spacing={2} />
        </Box>
        <ButtonGroup size="lg" variant="text" colorScheme="gray" spacing="6" display={{ base: 'none', md: 'flex' }} width="100%">
          <NavItems />
        </ButtonGroup>
      </Box>

      <Box width="100%">{children}</Box>

      {/* Mobile Drawer */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay h="100%" maxH="100%" w="100%" maxW="100%" />
        <DrawerContent h="100%" maxH="100%" pos="fixed" overflowY="auto">
          <DrawerHeader alignItems={'center'} display="flex" justifyContent="space-between" padding={4}>
            <DrawerCloseButton />
          </DrawerHeader>
          <DrawerBody>
            <VStack align="stretch">
              <NavItems inDrawer={true} />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </VStack>
  )
}

function externalNavigate(url: string) {
  window.open(url, '_blank')
}
